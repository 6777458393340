export const guest_urls = {
    'home': '/',
    'about_us': '/about',
    'sellers': '/sellers',
    'buyers': '/buyers',
    'single_event': '/event/:eventId',
    'single_event_dyn': (eventId) => `/event/${eventId}`,
    'google_auth': '/auth/google',
    'facebook_auth': '/auth/facebook',
    'apple_auth': '/auth/apple',
    'cookie_policy': '/cookie-policy',
    'terms_and_condition': '/terms-and-condition',
    'privacy_policy': '/privacy-policy',
    'search_event_list': '/search-event-list',
    'contact_us': '/contact-us',
    'refund_policy': '/refund-policy'
}