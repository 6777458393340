import React from 'react'
import google from '../../../assets/images/google.svg';
import facebook from '../../../assets/images/facebook.svg';
import apple from '../../../assets/images/apple-logo.png';
import { get_delete_request, get_request } from '../../../helpers/request';
import { useDispatch } from 'react-redux';
import { app_api } from '../../../utils/endpoints/app_api';
import { core_backend_url } from '../../../config';

export default function SocialAuthComponent() {

    const dispatch = useDispatch()

    const authenticate_user = () => {
        window.location.href = `${app_api.google_auth}`
    }
    const authenticateWithFacebook = () => {
        window.location.href = `${app_api.facebook_auth}`;
    };

    const authenticateWithApple = async () => {
        // Your Apple Client ID and Redirect URI
        const appleId = process.env.REACT_APP_APPLE_CLIENT_ID; // Your Apple Client ID
        const redirectUri = process.env.REACT_APP_APPLE_REDIRECT_URI;
        // Updated URL with response_mode set to form_post
        const appleSignInUrl = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${appleId}&redirect_uri=${redirectUri}&scope=email%20name&response_mode=form_post`;
        // Redirect the user to Apple Sign In
        window.location.href = appleSignInUrl;
    };

    return (
        <>
            <div className="alternate-reg">
                <span>Or instant log in with</span>
            </div>

            <div className="social-icon-sec-wrapr" role="group" aria-label="Vertical button group">
                <button type="button" onClick={authenticate_user} className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                    <img className='' src={google} width={25} height={25} />
                    {/* Continue with Google */}
                </button>
                <button type="button" onClick={authenticateWithFacebook} className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                    <img className='' src={facebook} width={25} height={25} />
                    {/* Continue with Facebook */}
                </button>
                <button type="button" onClick={authenticateWithApple} className="btn btn-outline-gray py-2 btn-outer-socialIcon">
                    <img className='' src={apple} width={25} height={25} />
                    {/* Continue with Facebook */}
                </button>

                {/* <button type="button" onClick={authenticate_user} className="btn btn-outline-gray py-2">
                <img className='me-3' src={google}/>
                Continue with Google
            </button>
            <button type="button" onClick={authenticateWithFacebook} className="btn btn-outline-gray py-2">
                <img className='me-3' src={facebook}/>
                Continue with Facebook
            </button> */}
                {/* <button type="button" class="btn btn-outline-primary">Button</button>
            <button type="button" class="btn btn-outline-primary">Button</button> */}
            </div>
        </>
    )
}
