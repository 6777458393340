import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  clearAll,
  logoutUser,
  onGetUser,
  onOnboardUser,
  onUserLogin,
  setErrorMessage,
  setLoading,
  setPageLoading,
} from '../../../config/redux/slice/authSlice'
import {
  get_delete_request,
  post_put_patch_request,
} from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import { system_user_roles } from '../../../constants/english'
import loading_gif from '../../../assets/images/logo-loading-gif.gif'
import alternateReg from '../../../assets/images/alternateReg.svg'
import {
  clearAll as clearAllApp,
  logoutAppUser,
} from '../../../config/redux/slice/appSlice'
import {
  clearAll as clearAllAdmin,
  logoutAdminUser,
} from '../../../config/redux/slice/adminSlice'
import {
  clearAll as clearAllCustomer,
  logoutCustomerUser,
} from '../../../config/redux/slice/customerSlice'
import SocialAuthComponent from '../../components/Form/SocialAuthComponent'
import ErrorPage from '../../layout/ErrorPage'
import registration_background from '../../../assets/images/background_images/registration_background.jpeg'
import LoginPasswordComponent from '../../components/Form/LoginPasswordComponent'

export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [status, setStatus] = useState('')
  const { loading, errorMsg, pageLoading, userInfoExists, userData } =
    useSelector((state) => state.authData)

  const { lastVisitedPage } = useSelector((state) => state.customer)
  const { adminErrorMsg } = useSelector((state) => state.admin)
  const { appErrorMsg } = useSelector((state) => state.app)

  useEffect(() => {
    dispatch(clearAll({}))
    dispatch(clearAllApp({}))
    dispatch(clearAllAdmin({}))
    dispatch(clearAllCustomer({}))
    dispatch(setPageLoading({ loading: false }))
  }, [, adminErrorMsg, appErrorMsg])

  useEffect(() => {
    if (userInfoExists) {
      const timer = setTimeout(() => {
        setPageLoading({ loading: false })
        if (userData.user_role.toLowerCase() == 'user') {
          navigate(app_urls.dashboard)
        } else if (
          system_user_roles.includes(userData.user_role.toLowerCase())
        ) {
          navigate(app_urls.admin_dashboard)
        }
      }, 10000)
    }
  }, [userInfoExists])

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    const res = await post_put_patch_request(
      'post',
      '',
      `${app_api.login}`,
      data,
      dispatch,
      setLoading,
      onUserLogin,
      setErrorMessage
    )
    if (status === 403) {
      dispatch(onOnboardUser({ email: data.email }))
      navigate(app_urls.verification_link_sent)

    }
    if (res !== undefined && res.status < 400) {
      dispatch(onOnboardUser({ email: data.email }))
      get_auth_user(res.data.data.jwtToken)
    }
  }

  const closeAlert = () => {
    dispatch(setErrorMessage({ message: null }))
  }

  const get_auth_user = async (token) => {
    dispatch(setPageLoading({ loading: true }))
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_user}`,
      {},
      dispatch,
      '',
      '',
      setErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      const timer = setTimeout(() => {
        dispatch(setPageLoading({ loading: false }))
        dispatch(onGetUser(res.data.data))
        if (res.data.data.role.toLowerCase() == 'user') {
          if (lastVisitedPage) {
            navigate(lastVisitedPage)
          } else {
            navigate(app_urls.dashboard)
          }
        } else if (
          system_user_roles.includes(res.data.data.role.toLowerCase())
        ) {
          navigate(app_urls.admin_dashboard)
        }
      }, 3000)
      // if((res.data.data.role).toLowerCase() == 'user'){
      //   navigate(app_urls.profile)
      // }else if(system_user_roles.includes((res.data.data.role).toLowerCase())){
      //   navigate(app_urls.admin_dashboard)
      // }
    } else if (res !== undefined) {
      setStatus(res.status)
    }
  }

  // const logout = () => {
  //     dispatch(logoutUser())
  //     dispatch(logoutCustomerUser())
  //     dispatch(logoutAppUser())
  //     dispatch(logoutAdminUser())
  // }

  return (
    <>
      {pageLoading ? (
        <>
          {errorMsg ? (
            <ErrorPage
              status={status}
              message={errorMsg}
              type={'email-verification'}
            />
          ) : (
            <div
              style={{ height: '100vh' }}
              className="w-100 bg-white d-flex justify-content-center align-items-center"
            >
              <img src={loading_gif} />
            </div>
          )}
        </>
      ) : (
        <AuthWrapper
          background_image={registration_background}
          onHandleSubmit={handleSubmit(onSubmit)}
        >
          {errorMsg && (
            <AuthAlert
              onClick={closeAlert}
              alertStyles={{ width: '95%', margin: '0 auto' }}
              status="error"
            >
              {errorMsg}
            </AuthAlert>
          )}
          <div className="form-group col-md-12 mb-4">
            <label htmlFor="email">Email</label>
            <input
            autoComplete='off'
              type="email"
              className="form-control input-lg"
              id="email"
              aria-describedby="emailHelp"
              {...register('email', {
                required: {
                  value: true,
                  message: '*this field is required',
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '*invalid email pattern',
                },
              })}
            />
            {errors?.email?.message && (
              <div className="text-danger small mt-1">
                {errors?.email?.message}
              </div>
            )}
          </div>
          <LoginPasswordComponent
            fieldName={'password'}
            error_msg={errors.password?.message}
            label={'Password'}
            isReq={true}
            minlength={6}
            maxlength={6}
            register={register}
            patternRules={''}
          />
          <div className="col-md-12">
            <div className="d-flex justify-content-end mb-3">
              {/* <div className="custom-control custom-checkbox mr-3 mb-3">
              <input type="checkbox" className="custom-control-input" id="customCheck2"/>
              <label className="custom-control-label" htmlFor="customCheck2">Remember me</label>
            </div> */}

              <Link className="text-color" to={app_urls.forgot_password}>
                {' '}
                Forgot password?{' '}
              </Link>
            </div>

            <div>
              {loading ? (
                <Spinner class_styles={'mb-3'} />
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary btn-lg form-control input-lg text-uppercase mb-4"
                >
                  Log in
                </button>
              )}
            </div>

            <p>
              Don't have an account yet?
              <Link className="text-blue ms-1" to={app_urls.register}>
                Register Here
              </Link>
              {/* <a className="text-blue" href="sign-up.html">Register Here</a> */}
            </p>
          </div>

          <SocialAuthComponent />
        </AuthWrapper>
      )}
    </>
  )
}
