import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { clearAll, onUpdate, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'


export default function HistoryTrackPopup({ historyPopup, setHistoryPopup, orderId }) {
    const [staticStatusData, setStaticStatusData] = useState('');
    const dispatch = useDispatch()
    const { token } = useSelector((state) => state.authData)
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        setHistoryPopup(false);
    };


    useEffect(() => {
        getChangeAmountList();
    }, [])

    const getChangeAmountList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getStatusHistoryList(orderId)}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let resData = res?.data?.data || [];
            setStaticStatusData(resData);
        }
        setLoading(false);
    }

    return (
        <Modal show={historyPopup} onHide={handleClose} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title> Track History</Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>Submitted On</th>
                                        <th scope="col" className='no-wrap'>User Details</th>
                                        <th scope="col" className='no-wrap'>Order Status</th>
                                        <th scope="col" className='no-wrap'>Comment</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {staticStatusData && staticStatusData.length > 0 ? (
                                        staticStatusData.map((item) => (
                                            <tr key={item}>
                                                <td>{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</td>

                                                <td>{item.userName} <br />{item.userRole}</td>
                                                <td>{item.orderStatusMaster.name}</td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No details available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
