import React from 'react'
import { useForm } from 'react-hook-form'
import ModalLayout from '../layout/ModalLayout'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  onGetUser,
  setErrorMessage,
  setLoading,
  showPhoneNumberVerificationModalFunc,
} from '../../config/redux/slice/authSlice'
import { app_api } from '../../utils/endpoints/app_api'
import { post_put_patch_request } from '../../helpers/request'
import Spinner from './Spinner'
import AuthAlert from './AuthAlert'

export default function VerifyPhoneNumber() {
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    // watch,
    // getValues,
    // setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })

  const { loading, errorMsg } = useSelector((state) => state.authData)

  const { token, userData } = useSelector((state) => state.authData)

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    let verifyData = {
      code: data.code,
      phoneNo: userData.phone
    }

    const res = await post_put_patch_request(
      'post',
      token,
      `${app_api.verify_phone_number_otp}`,
      verifyData,
      dispatch,
      setLoading,
      '',
      setErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      dispatch(onGetUser({ ...userData, phoneVerifiedAt: true }))
      dispatch(
        showPhoneNumberVerificationModalFunc({
          modal: true,
          type: 'success',
          message: res?.data?.message
            ? res?.data?.message
            : 'Phone number successfully verified',
        })
      )
    }
  }

  // const closeModal = () => {
  //   dispatch(showPhoneNumberVerificationModalFunc({modal: false, type: null}))
  // }

  return (
    <ModalLayout heading={'Phone Number Verification'} keepHeading={false}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="modal-body px-4 px-md-7">
          <h5 className="text-dark fw-bold">Phone Number Verification</h5>
          {errorMsg && (
            <AuthAlert
              alertStyles={{ width: '95%', margin: '0 auto' }}
              status="error"
            >
              {errorMsg}
            </AuthAlert>
          )}
          <h5
            className="text-dark"
            style={{ fontSize: '0.86rem', lineHeight: '1.5rem' }}
          >
            Enter the 6 digit OTP that was sent to your phone number (
            {userData.phone})
          </h5>
          <div className="mt-2">
            <div style={{ rowGap: '1.2rem' }} className="row mb-2">
              <div className="form-group">
                <label htmlFor="token">One Time Password (OTP)</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="token"
                  {...register('code', {
                    required: {
                      value: true,
                      message: '*this field is required',
                    },
                  })}
                />
                {errors?.code?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.code?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer pt-0 border-top-0 d-flex align-items-center">
          <div>
            {loading ? (
              <Spinner isForm={true} />
            ) : (
              <button type="submit" className="btn btn-primary me-3">
                Submit Form
              </button>
            )}
          </div>
          <button
            // onClick={reset}
            onClick={() => {
              dispatch(showPhoneNumberVerificationModalFunc({ modal: false, type: null })); // Close the modal
              reset(); // Reset the form
            }}
            disabled={loading ? true : false}
            type="button"
            className="btn btn-info"
          >
            Cancel
          </button>
        </div>
      </form>
    </ModalLayout>
  )
}
