import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { clearAll, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import Spinner from '../../../components/Spinner'
import { onUpdate, setAdminErrorMessageUser, setAdminLoadingUser } from '../../../../config/redux/slice/adminSlice'


export default function OtherStatusConfirm({ showOtherPopup, setShowOtherPopup, statusData, orderId,getPendingList }) {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.authData);
    const [loading, setLoading] = useState(false);
    const {
        adminLoading,
      } = useSelector((state) => state.admin)
    const handleClose = () => {
        setShowOtherPopup(false);
    };

    const handleSubmit = async () => {
        if (!statusData || !orderId) {
            alert("Missing required data.");
            return;
        }

        setLoading(true);

        try {
            const updateData = {
                statusId: statusData.id,
                comment: null, // You can include a comment field here if required
            };

            const res = await post_put_request_promoCode(
                "post",
                token,
                `${app_api.orderStatus(orderId)}`,
                updateData,
                dispatch,
                setAdminLoadingUser,
                '',
                setAdminErrorMessageUser,
                true
            );

            if (res && res.status < 400) {
                dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
            }
            else {
                dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))

            }
            setShowOtherPopup(false); // Close the popup after submission
            getPendingList(); // Refresh the list after the update
        } catch (error) {
            console.error("Error in saving status:", error);
            alert("An error occurred while updating the status.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={showOtherPopup} onHide={handleClose} className="modal-popup-commission">
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to change the status to "{statusData?.name}" for this order?
            </Modal.Body>
            <Modal.Footer>
            {adminLoading ?
                    <Spinner isForm={true} /> :
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="ml-2"
                >
                    {loading ? "Saving..." : "Save"}
                </Button>
}
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

