import Pagination from "react-js-pagination";
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';
import { strText } from '../../../../constants/english'
import { formatNumber } from '../../../../helpers/helpers'
import moment from 'moment'
import AddComment from "./addComment";
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_request_promoCode } from '../../../../helpers/request'
import { clearAll, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import Spinner from '../../../components/Spinner'
import { onUpdate, setAdminErrorMessageUser, setAdminLoadingUser } from '../../../../config/redux/slice/adminSlice'


export default function Refund() {

    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [formData, setFormData] = useState(new FormData());

    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [all_orders, setAll_orders] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState({});
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [comment, setComment] = useState("");
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [boldRow, setBoldRow] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const [actionType, setActionType] = useState('');
    const [selectedActions, setSelectedActions] = useState({});
    const [selectedEvents, setSelectedEvents] = useState([]);
    const [selectedSellers, setSelectedSellers] = useState({});
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectedOrdersPerSeller, setSelectedOrdersPerSeller] = useState({});
    const [dropDownValue, setDropDownValue] = useState([]);
    const { userData, token } = useSelector((state) => state.authData)
    const [orderStatusId, setOrderStatusId] = useState(null);

    const dispatch = useDispatch()

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const {
        adminLoading,
        adminErrorMsg,
        adminSuccessMsg
      } = useSelector((state) => state.admin)

    useEffect(() => {
        getPendingList();
    }, [])

    const {
        customerLoading,
    } = useSelector((state) => state.customer)

    const getPendingList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getRefundRequestList}/refundApproved?page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }

    const handleSearchChange = async (event) => {

        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10;

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getRefundRequestList}/refundApproved?search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }

        setLoading(false);
        setActivePage(1);
    }

    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10;

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getRefundRequestList}/refundApproved?page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }

        setLoading(false);
        setActivePage(pageNumber);
    };

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();

        setNestedExpandedRows((prevState) => {
            const currentMainIndexState = prevState[mainIndex] || {};
            const isExpanded = currentMainIndexState[nestedIndex] || false;

            return {
                ...prevState,
                [mainIndex]: {
                    ...currentMainIndexState,
                    [nestedIndex]: !isExpanded, // Toggle the specific nested row
                },
            };
        });
    };

    const handleSelectAll = () => {
        if (all_orders.length === selectedOrders.length) {
            // Deselect all orders and sellers
            setSelectedOrders([]);
            setSelectedSellers({});
            setSelectedOrdersPerSeller({}); // Deselect all orders per seller
        } else {
            // Select all orders and sellers
            setSelectedOrders(all_orders.map(order => order.id)); // Select all event IDs

            const allSellers = {};
            const allOrdersPerSeller = {};

            all_orders.forEach(order => {
                // Get sellers for each event using detailedPayouts.data
                allSellers[order.id] = order.detailedPayouts.data.map(seller => seller.user.id); // Get seller IDs

                // Map orders for each seller within each event
                allOrdersPerSeller[order.id] = {};
                order.detailedPayouts.data.forEach(seller => {
                    allOrdersPerSeller[order.id][seller.user.id] = seller.orders.map(order => order.orderId); // Get order IDs for each seller
                });
            });

            // Set selected sellers and orders per seller
            setSelectedSellers(allSellers);
            setSelectedOrdersPerSeller(allOrdersPerSeller); // Select all orders per seller
        }
    };


    const handleSelectSeller = (eventId, sellerId) => {
        setSelectedSellers(prevSellers => {
            const updatedSellers = { ...prevSellers };
            const sellersForEvent = updatedSellers[eventId] || [];

            // Toggle seller selection
            const sellerSelected = sellersForEvent.includes(sellerId);
            if (sellerSelected) {
                // Deselect seller and remove their orders
                updatedSellers[eventId] = sellersForEvent.filter(id => id !== sellerId);

                // Remove orders for this seller
                setSelectedOrdersPerSeller(prevOrders => {
                    const updatedOrders = { ...prevOrders };
                    if (updatedOrders[eventId]) {
                        delete updatedOrders[eventId][sellerId];
                        // If no orders left for this seller, remove event
                        if (Object.keys(updatedOrders[eventId]).length === 0) {
                            delete updatedOrders[eventId];
                        }
                    }
                    return updatedOrders;
                });
            } else {
                // Select seller and auto-select their orders
                updatedSellers[eventId] = [...sellersForEvent, sellerId];

                // Find the seller's orders in detailedPayouts
                const sellerData = all_orders
                    .find(order => order.id === eventId)
                    ?.detailedPayouts.data.find(seller => seller.user.id === sellerId);

                const orders = sellerData ? sellerData.orders.map(order => order.orderId) : [];

                // Update selected orders for this seller
                setSelectedOrdersPerSeller(prevOrders => {
                    const updatedOrders = { ...prevOrders };
                    if (!updatedOrders[eventId]) updatedOrders[eventId] = {};
                    updatedOrders[eventId][sellerId] = orders; // Auto-select all orders for this seller
                    return updatedOrders;
                });
            }

            // Check if all sellers for the event are selected
            const allSellers = all_orders.find(order => order.id === eventId)?.detailedPayouts.data.map(s => s.user.id) || [];
            const allSellersSelected = allSellers.every(id => updatedSellers[eventId]?.includes(id));

            // If all sellers are selected, select the event
            if (allSellersSelected) {
                setSelectedOrders(prev => [...new Set([...prev, eventId])]);
            } else {
                // If not all sellers are selected, unselect the event
                setSelectedOrders(prev => prev.filter(id => id !== eventId));
            }

            return updatedSellers;
        });
    };


    const handleSelectOrder = (eventId, sellerId, orderId) => {
        setSelectedOrdersPerSeller((prevOrders) => {
            const updatedOrders = JSON.parse(JSON.stringify(prevOrders));

            // Initialize nested structures if they don't exist
            if (!updatedOrders[eventId]) {
                updatedOrders[eventId] = {};
            }
            if (!updatedOrders[eventId][sellerId]) {
                updatedOrders[eventId][sellerId] = [];
            }

            const sellerOrders = updatedOrders[eventId][sellerId];
            const isOrderSelected = sellerOrders.includes(orderId);

            if (isOrderSelected) {
                // Deselect the order
                updatedOrders[eventId][sellerId] = sellerOrders.filter((id) => id !== orderId);
            } else {
                // Select the order
                updatedOrders[eventId][sellerId] = [...sellerOrders, orderId];
            }

            // Find the event in all_orders
            const event = all_orders.find(order => order.id === eventId);

            if (event) {
                // Find the seller in the event's detailedPayouts
                const seller = event.detailedPayouts.data.find(seller => seller.user.id === sellerId);
                const totalOrdersForSeller = seller?.orders.length || 0;

                // Check if all orders for this seller are selected
                const allOrdersSelectedForSeller = updatedOrders[eventId][sellerId].length === totalOrdersForSeller;

                // If all orders for the seller are selected, mark the seller as selected
                setSelectedSellers((prevSellers) => {
                    const updatedSellers = { ...prevSellers };

                    if (!updatedSellers[eventId]) updatedSellers[eventId] = [];

                    if (allOrdersSelectedForSeller) {
                        // Select the seller if all their orders are selected
                        if (!updatedSellers[eventId].includes(sellerId)) {
                            updatedSellers[eventId].push(sellerId);
                        }
                    } else {
                        // Deselect the seller if not all their orders are selected
                        updatedSellers[eventId] = updatedSellers[eventId].filter(id => id !== sellerId);
                    }

                    // Check if all sellers for the event are selected
                    const allSellers = event.detailedPayouts.data.map(s => s.user.id);
                    const allSellersSelected = allSellers.every(sellerId => updatedSellers[eventId]?.includes(sellerId));

                    // If all sellers are selected, select the event
                    if (allSellersSelected) {
                        setSelectedOrders((prev) => [...new Set([...prev, eventId])]);
                    } else {
                        // Unselect the event if not all sellers are selected
                        setSelectedOrders((prev) => prev.filter(id => id !== eventId));
                    }

                    return updatedSellers;
                });
            }

            return updatedOrders;
        });
    };


    const handleSelectEvent = (eventId) => {
        if (selectedOrders.includes(eventId)) {
            // Deselect event
            setSelectedOrders(prev => prev.filter(id => id !== eventId));
            setSelectedSellers(prev => {
                const newSellers = { ...prev };
                delete newSellers[eventId]; // Remove sellers for this event
                return newSellers;
            });
            setSelectedOrdersPerSeller(prev => {
                const newOrders = { ...prev };
                delete newOrders[eventId]; // Deselect all orders for this event
                return newOrders;
            });
        } else {
            // Check if there are any sellers or orders to select
            const event = all_orders.find(order => order.id === eventId);
            if (event && event.detailedPayouts.data.length > 0) {
                // Select event
                setSelectedOrders(prev => [...prev, eventId]);

                // Select sellers associated with the event
                setSelectedSellers(prev => {
                    const sellers = event.detailedPayouts.data.map(seller => seller.user.id); // Get seller IDs
                    return {
                        ...prev,
                        [eventId]: sellers // Set selected sellers for the event
                    };
                });

                // Select orders for each seller
                setSelectedOrdersPerSeller(prev => {
                    const sellerOrders = {};
                    event.detailedPayouts.data.forEach(seller => {
                        sellerOrders[seller.user.id] = seller.orders.map(order => order.orderId); // Get order IDs for the seller
                    });
                    return { ...prev, [eventId]: sellerOrders }; // Set selected orders for the event
                });
            }
        }
    };

    const initiateRefund = async () => {

        const payoutData = Object.keys(selectedOrdersPerSeller).map((eventId) => {

            const event = all_orders.find(order => order.id === eventId);
            if (!event) {
                return null;  // Skip this event if not found
            }

            const ordersPerSeller = selectedOrdersPerSeller[eventId];

            let totalEventAmount = 0;

            const sellerDetails = Object.keys(ordersPerSeller).map((sellerId) => {

                const seller = event.detailedPayouts?.data.find(seller => seller.user.id === sellerId);
                if (!seller) {
                    return {
                        userId: sellerId,
                        error: "Seller not found",
                        orders: [],
                        totalAmount: 0,
                    };
                }

                const orders = ordersPerSeller[sellerId].map((orderId) => {

                    const orderDetails = seller.orders?.find(order => order.orderId === orderId);
                    if (!orderDetails) {
                        return null; // Return null for missing orders
                    }

                    if (orderDetails.payoutStatus === 'PAID') {
                        return null; // Skip PAID orders
                    }

                    return {
                        orderId: orderDetails?.orderId || `Order ID: ${orderId} not found`,
                        paymentIntentId: orderDetails?.paymentIntentId ?? "",
                        amount: orderDetails?.totalAmount || 0,
                    };
                }).filter(order => order !== null); // Filter out null values (PAID orders)

                const totalAmount = orders.reduce((sum, order) => sum + order.amount, 0);
                totalEventAmount += totalAmount;

                return {
                    userId: sellerId,
                    orders,
                    totalAmount, // Include the total amount in the sellerDetails
                };
            }).filter(seller => seller.orders.length > 0); // Filter out sellers with no orders

            // Skip the event if there are no valid sellerDetails or totalAmount
            if (sellerDetails.length === 0 || totalEventAmount === 0) {
                return null; // Don't include this event in payoutData
            }

            return {
                eventId,
                sellerDetails,
                totalAmount: totalEventAmount, // Include total amount for the event
            };
        }).filter(event => event !== null); // Filter out null events (empty orders)

        console.log("payoutData",payoutData)


        // Only proceed with sending payoutData if it has valid events
        if (payoutData.length > 0) {
            const res = await post_put_request_promoCode(
                "post",
                token,
                `${app_api.initiateRefund}`,
                JSON.stringify(payoutData),
                dispatch,
                setAdminLoadingUser,
                '',
                setAdminErrorMessageUser,
                true
            );

            if (res !== undefined && res.status < 400) {
                // Handle successful response
                dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
                setFormData(new FormData())
                getPendingList();
                console.log('res11', res);
                console.log('res21', res.data);
            }
            else{
                dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
                setFormData(new FormData())
            }
        } else {
           console.log('No valid events to send');
        }
    };
    const getSellerPayoutStatus = (orders) => {
        let status = 'NOT INITIATED';
        const statuses = orders.map(order => order.payoutStatus);

        if (statuses.every(status => status === 'PAID')) {
            return 'PAID';
        } else if (statuses.every(status => status === 'FAILED')) {
            return 'FAILED';
        } else if (statuses.some(status => status === 'PAID') && statuses.some(status => status === 'FAILED') ||
            statuses.some(status => status === 'PAID') && statuses.some(status => status === 'NOT INITIATED') ||
            statuses.some(status => status === 'FAILED') && statuses.some(status => status === 'NOT INITIATED')) {
            return 'MIXED';
        } else if (statuses.every(status => status === 'NOT INITIATED')) {
            return 'NOT INITIATED';
        }

        return status;
    };
    const getEventPayoutStatus = (eventOrders) => {
        let status = 'NOT INITIATED';

        const statuses = eventOrders.map(order => getSellerPayoutStatus(order.orders));

        if (statuses.every(status => status === 'PAID')) {
            return 'PAID';
        } else if (statuses.every(status => status === 'FAILED')) {
            return 'FAILED';
        } else if (statuses.some(status => status === 'PAID') && statuses.some(status => status === 'FAILED') ||
            statuses.some(status => status === 'PAID') && statuses.some(status => status === 'NOT INITIATED') ||
            statuses.some(status => status === 'FAILED') && statuses.some(status => status === 'NOT INITIATED')) {
            return 'MIXED';
        } else if (statuses.every(status => status === 'MIXED')) {
            return 'MIXED';
        }

        else if (statuses.every(status => status === 'NOT INITIATED')) {
            return 'NOT INITIATED';
        }

        return status;
    };
    const areAllEventsPaid = () => {
        return all_orders.every(order => getEventPayoutStatus(order?.detailedPayouts?.data || []) === 'PAID');
    };
    return (

        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input select-check cursor-pointer"
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={all_orders.length > 0 && selectedOrders.length === all_orders.length}
                        disabled={areAllEventsPaid()}
                    />
                    <label className="form-check-label" htmlFor="selectAll">Select All</label>
                </div>
                {adminLoading ?
                    <Spinner isForm={true} /> :
                    <button
                        className="btn btn-primary ml-3 cursor-pointer"
                        style={{ marginLeft: "20px" }}
                        onClick={() => {
                            initiateRefund();
                        }}
                        disabled={areAllEventsPaid()}>
                        Initiate Refund
                    </button>
                }
            </div>


            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'></th>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Event Date</th>
                            <th scope="col" className='no-wrap'>Refund Due</th>
                            <th scope="col" className='no-wrap'>Refund Status</th>
                            <th scope="col" className='no-wrap'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : all_orders.length > 0 ? (
                            all_orders.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="cursor-pointer"
                                                checked={selectedOrders.includes(item?.id)}
                                                onChange={() => handleSelectEvent(item?.id)}
                                                disabled={getEventPayoutStatus(item?.detailedPayouts?.data || []) === 'PAID'}
                                            />
                                        </td>
                                        <td>{item?.name}</td>
                                        <td>{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                        <td>{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</td>
                                        <td>{getEventPayoutStatus(item?.detailedPayouts?.data || [])}</td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>

                                        </td>
                                    </tr>
                                    {(expandedRows[index] && item?.detailedPayouts?.data?.length > 0 && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className='no-wrap'></th>
                                                            <th className='no-wrap'>Seller Name</th>
                                                            <th className='no-wrap'>Seller Email</th>
                                                            <th className='no-wrap'>Seller Phone</th>
                                                            <th className='no-wrap'>Refund Due</th>
                                                            <th className='no-wrap'>Refund Status</th>
                                                            <th className='no-wrap'>Action</th>
                                                        </tr>
                                                    </thead>

                                                    {item?.detailedPayouts.data.map((items, sellerIndex) => {
                                                        const sellerPayoutStatus = getSellerPayoutStatus(items.orders); // Get the seller payout status

                                                        return (
                                                            <tbody key={sellerIndex}>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="cursor-pointer"

                                                                            checked={selectedSellers[item.id]?.includes(items.user.id) || false}
                                                                            onChange={() => handleSelectSeller(item.id, items.user.id)}
                                                                            disabled={getSellerPayoutStatus(items.orders) === 'PAID'} />
                                                                    </td>
                                                                    <td>{items.user.name}</td>
                                                                    <td>{items.user.email}</td>
                                                                    <td>{items.user.phone}</td>
                                                                    <td>{items?.dueAmount ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}` : 'N/A'}</td>
                                                                    <td>{sellerPayoutStatus}</td> {/* Displaying sellerPayoutStatus here */}
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <div className="">
                                                                                <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, sellerIndex, event)}>
                                                                                    {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                                                        <i className="fa fa-angle-up fa-sizeset"></i>
                                                                                    ) : (
                                                                                        <i className="fa fa-angle-down fa-sizeset"></i>
                                                                                    )}
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>

                                                                {nestedExpandedRows[index] && (
                                                                    <tr>
                                                                        <td colSpan="7">
                                                                            <table className="table table-bordered tbl-subinner-width">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className='no-wrap'></th>
                                                                                        <th className='no-wrap'>Order Id/Sale Date</th>
                                                                                        <th className='no-wrap'>Number of Ticket</th>
                                                                                        <th className='no-wrap'>Ticket Price</th>
                                                                                        <th className='no-wrap'>Total Amount</th>
                                                                                        <th className='no-wrap'>PromoCode</th>
                                                                                        <th className='no-wrap'>Final Amount</th>
                                                                                        <th className='no-wrap'>Refund Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {items.orders.map((orderItem, itemIndex) => {
                                                                                        const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;
                                                                                        return (
                                                                                            <tr key={itemIndex}>
                                                                                                <td>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={selectedOrdersPerSeller[item.id]?.[items.user?.id]?.includes(orderItem.orderId) || false}
                                                                                                        onChange={() => handleSelectOrder(item.id, items.user?.id, orderItem.orderId)}
                                                                                                        disabled={orderItem?.payoutStatus === 'PAID'}
                                                                                                    />
                                                                                                </td>
                                                                                                <td className={boldRow === dropdownKey ? 'fontWeight' : ""}>
                                                                                                    {orderItem?.orderRef}<br />
                                                                                                    {orderItem?.deliveryDate ? (format(new Date(orderItem?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}
                                                                                                </td>
                                                                                                <td>{orderItem?.orderItems.length || 0}</td>
                                                                                                <td>{orderItem?.orderItems[0].price ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.price ? `${strText.currency_symbol}${formatNumber(orderItem?.price)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.promoCodeDiscount ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.totalAmount ? `${strText.currency_symbol}${formatNumber(orderItem?.totalAmount)}` : 'N/A'}</td>
                                                                                                <td>{orderItem?.payoutStatus}</td>
                                                                                            </tr>
                                                                                        );
                                                                                    })}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        );
                                                    })}


                                                </table>
                                            </td>
                                        </tr>
                                    ))}


                                </>

                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>

                </table>
            </div>

            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : all_orders.length > 0 ? (
                    all_orders.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head"></h3>
                                        <div className="mob-tbl-value">
                                            <input
                                                type="checkbox"
                                                checked={selectedOrders.includes(item?.id)}
                                                onChange={() => handleSelectEvent(item?.id)}
                                                disabled={getEventPayoutStatus(item?.detailedPayouts?.data || []) === 'PAID'}

                                            />
                                        </div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.name}>{item?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Amount Due</h3>
                                        <div className="mob-tbl-value">{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Payout Status</h3>
                                        <div className="mob-tbl-value">{getEventPayoutStatus(item?.detailedPayouts?.data || [])}</div>
                                    </div>


                                    <div className="mob-table-sec-expand">
                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                    </div>

                                </div>
                                {expandedRows[index] && item?.detailedPayouts?.data?.length > 0 && (
                                    <>
                                        {item?.detailedPayouts.data.map((items, sellerIndex) => {
                                            const sellerPayoutStatus = getSellerPayoutStatus(items.orders); // Declare variable outside JSX

                                            return (
                                                <div className="mob-tbl-inner-sec" key={sellerIndex}>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head"></h3>
                                                        <div className={`mob-tbl-value`}>
                                                            <input
                                                                type="checkbox"
                                                                checked={selectedSellers[item.id]?.includes(items.user.id) || false}
                                                                onChange={() => handleSelectSeller(item.id, items.user.id)}
                                                                disabled={getSellerPayoutStatus(items.orders) === 'PAID'}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Name</h3>
                                                        <div className={`mob-tbl-value`}>{items.user.name}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Email</h3>
                                                        <div className="mob-tbl-value">{items.user.email}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Seller Phone</h3>
                                                        <div className="mob-tbl-value">{items.user.phone}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Amount Due</h3>
                                                        <div className="mob-tbl-value">
                                                            {items?.dueAmount
                                                                ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}`
                                                                : 'N/A'}
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Payout Status</h3>
                                                        <div className="mob-tbl-value">{sellerPayoutStatus}</div>
                                                    </div>

                                                    <div className="mob-table-sec-action">
                                                        <a
                                                            title="View Items"
                                                            className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd"
                                                            onClick={(event) => toggleNestedRow(index, sellerIndex, event)}
                                                        >
                                                            {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                            ) : (
                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                            )}
                                                        </a>
                                                    </div>

                                                    {nestedExpandedRows[index] && (
                                                        <>
                                                            {items.orders.map((orderItem, itemIndex) => {
                                                                const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;
                                                                return (
                                                                    <div className="mob-tb-subinner" key={itemIndex}>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head"></h3>
                                                                            <div className="mob-tbl-value">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    checked={
                                                                                        selectedOrdersPerSeller[item.id]?.[items.user?.id]?.includes(orderItem.orderId) || false
                                                                                    }
                                                                                    onChange={() =>
                                                                                        handleSelectOrder(item.id, items.user?.id, orderItem.orderId)
                                                                                    }
                                                                                    disabled={orderItem?.payoutStatus === 'PAID'}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Order Id/Sale Date</h3>
                                                                            <div
                                                                                className={`mob-tbl-value ${boldRow === dropdownKey ? 'fontWeight' : ''
                                                                                    }`}
                                                                            >
                                                                                {orderItem?.orderRef}
                                                                                <br />
                                                                                {orderItem?.deliveryDate
                                                                                    ? format(new Date(orderItem?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase()
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Number of Tickets</h3>
                                                                            <div className="mob-tbl-value">{orderItem?.numberOfTickets || 0}</div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Ticket Price</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.ticketPrice
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.ticketPrice)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Total Amount</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.orderItems[0].price
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">PromoCode</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.promoCodeDiscount
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Final Discount</h3>
                                                                            <div className="mob-tbl-value">
                                                                                {orderItem?.totalAmount
                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.totalAmount)}`
                                                                                    : 'N/A'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="mob-table-sec">
                                                                            <h3 className="mob-tbl-head">Payout Status</h3>
                                                                            <div className="mob-tbl-value">{orderItem?.payoutStatus}</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </>
                                )}

                            </div>


                        </>

                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}

            </div>

            {showComplaintPopup && (
                <AddComment
                    setShowComplaintPopup={setShowComplaintPopup}
                    showComplaintPopup={showComplaintPopup}
                    selectedItem={selectedOrder}
                    actionType={actionType} // Pass the selected action type
                    setOrderStatusId={orderStatusId}
                    getPendingList={getPendingList}
                />
            )}
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}