import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
const UserDetails = ({ show, handleClose, user }) => {
    const [totalDiscount, setTotalDiscount] = useState(0);

    useEffect(() => {
        if (user && user.length > 0) {
          const sumDiscount = user.reduce((acc, currentItem) => {
            return acc + (currentItem.discount || 0);
          }, 0);
    
          setTotalDiscount(sumDiscount);
          console.log('Total Discount:', sumDiscount);
        }
      }, [user]);


    return (
        <Modal show={show} onHide={handleClose} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>Buyer Name</th>
                                        <th scope="col" className='no-wrap'>Event Name</th>
                                        <th scope="col" className='no-wrap'>Discount Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {user && user?.length > 0 ? (
                                        user.map((item) => (
                                            <tr>
                                                <td>
                                                    <div className="text-ellipse-single">{item.buyerName}</div>
                                                </td>
                                                <td className="text-ellipse-single">{item.eventName}</td>
                                                <td>
                                                <span> {item.discount ? `${strText.currency_symbol}${formatNumber(item.discount)}` : 'N/A'}</span>
                                             </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No user details available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div className="pt-1 user-details"><b>Total Discount:
                   <span> {totalDiscount ? `${strText.currency_symbol}${formatNumber(totalDiscount)}` : 'N/A'}</span>
                    </b></div>

                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserDetails;
