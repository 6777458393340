import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  onAddListing,
  setCustomerErrorMessage,
  setCustomerLoading,
  setDetails,
  setFormData,
  setLastVisitedPage,
} from '../../../../config/redux/slice/customerSlice'
import {
  get_delete_request,
  post_put_patch_request,
} from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import Spinner from '../../../components/Spinner'
import ListingPageLayout from './ListingPageLayout'
import { useNavigate } from 'react-router-dom'
import { app_urls } from '../../../../utils/urls/app_urls'
import moment from 'moment'
import SuccessModal from './SuccessModal'
import { showPhoneNumberVerificationModalFunc } from '../../../../config/redux/slice/authSlice'
import { strText } from '../../../../constants/english'

export default function SellTicketPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    clearErrors,
    resetField,
    getValues,
    setError,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [seats, setSeats] = useState([])
  const [subSections, setSubSections] = useState([])
  const [sectionsData, setSections] = useState([])
  const [info_list, set_info_list] = useState([])
  const [showError, setShowError] = useState(false)
  const [errorType, setErrorType] = useState('')
  const [path, setPath] = useState(window.location.pathname)
  const [priceRange, setPriceRange] = useState({})
  const [clickedAddSeat, setClickedAddSeat] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const checkboxLabels = [
    'Restricted view', 'Child ticket (ages 16 and under)*', 'Child ticket (ages 18 and under)*', 
    'Junior ticket (ages 21 and under)', 'Senior ticket (61 and older)',
    'Away Supporters Only', 'Home Supporters Only', 'Includes unlimited food and drinks',
     'Includes limited complimentary food and drinks', 'Food and drink voucher included',
    'Free Halftime drinks', 'Complimentary matchday programme', 'Padded Seats', 'Standing Only'
  ];

  const handleYesClick = () => {
    setShowCheckboxes(true);
    setActiveButton('yes');
  };

  const handleNoClick = () => {
    setShowCheckboxes(false);
    setSelectedCheckboxes([]); // Clear selected checkboxes when "NO" is clicked
    setActiveButton('no');

  };
 

  const handleCheckboxChange = (label) => {
    setSelectedCheckboxes((prevSelected) =>
      prevSelected.includes(label)
        ? prevSelected.filter((item) => item !== label)
        : [...prevSelected, label]
    );
  };
  const {
    customerLoading,
    listingManagement: { listingFormData },
    eventManagement: { eventDetails },
  } = useSelector((state) => state.customer)

  const {
    token,
    userData,
  } = useSelector((state) => state.authData)

  const numberOfElements = 30

  //const elements = Array.from({ length: numberOfElements }, (_, i) => i + 1);

  // Initialize an array to store the elements
  const elements = []

  // Use a for loop to generate the elements
  for (let i = 1; i <= numberOfElements; i++) {
    elements.push(i)
  }

  const rowValue = watch('row')
  const seatNoValue = watch('seatNumber')
  const ticketNoValue = watch('numberOfTickets')
  const subSectionIdValue = watch('subSectionId');
  
  useEffect(() => {

    // getAllSections()
    checkIfUserHasConnectedAccount()
    if (Object.values(listingFormData).length != 0) {
      if (listingFormData?.seats) {
        setSeats(listingFormData.seats)
      }
      if (listingFormData?.sectionId) {
        setValue('sectionId', listingFormData.sectionId)
        if (listingFormData?.subSectionId) {
          setValue('subSectionId', listingFormData.subSectionId)
          getAllSUbSections(listingFormData?.sectionId)
        }
      }
      dispatch(setFormData({ data: {}, type: 'listing' }))
    } setValue('subSectionId', '')
  }, [])

  
  // const get_auth_user  = async () => {
  //     // dispatch(setPageLoading({loading: true}))
  //     dispatch(clearAll({}))
  //     const res = await get_delete_request(
  //       'get',
  //        token,
  //         `${app_api.get_user}`,
  //         {},
  //         dispatch,
  //         "",
  //         "",
  //         setCustomerErrorMessage
  //     )
  //     if(res!==undefined && res.status == 401){
  //         dispatch(setLastVisitedPage(path))
  //     }
  // }

  useEffect(() => {
    dispatch(clearAll())
    if (eventDetails?.Venue?.id) {
      getAllSections()
    }
    if (eventDetails?.id) {
      getListingPriceRange()
    }
    set_info_list([
      {
        id: 1,
        title: 'Event',
        description: eventDetails?.name,
      },
      {
        id: 2,
        title: 'Description',
        description: eventDetails?.description,
      },
      {
        id: 3,
        title: 'Venue',
        description: eventDetails?.Venue?.name,
      },
      {
        id: 4,
        title: 'Address',
        description: eventDetails?.Venue?.address,
      },
      {
        id: 5,
        title: 'Date',
        description: moment(eventDetails?.date).format('YYYY-MM-DD'),
      },
      {
        id: 6,
        title: 'Time',
        description: moment(eventDetails?.date).format('HH:mm'),
      },
    ])
    // Clean up the class on unmount (optional)
  }, [eventDetails])


  const onSubmit = async (data) => {

    dispatch(clearAll({}));
    let same_rows;
    let avoid_leaving_single;

    // Check if the block is N/A
    // const isBlockNA = (data.subSectionId === '' || data.subSectionId === 'N/A');
    const isBlockNA = false;


    // Check if seats are required based on the block
    // const seatsRequired = !isBlockNA;

    // if (seatsRequired) {
    //   if (data.sellingOption === 'FULL') {
    //     same_rows = seats.every((item) => item.row === seats[0]?.row);
    //   }

    //   if (data.sellingOption === 'AVOID_LEAVING_SINGLE') {
    //     const countMap = {};
    //     let arr = seats.map((option) => option.row);
    //     arr.forEach((item) => {
    //       countMap[item] = (countMap[item] || 0) + 1;
    //     });
    //     avoid_leaving_single = Object.values(countMap).every(
    //       (count) => count !== 1
    //     );
    //   }

    //   if (
    //     ((seats.length < Number(ticketNoValue) || seats.length > Number(ticketNoValue)) && data.subSectionId !== '')
    //   ) {
    //     setErrorType('quantity');
    //     setShowError(true);
    //     return;
    //   } else if (data.sellingOption === 'FULL' && same_rows === false) {
    //     setErrorType('full');
    //     setShowError(true);
    //     return;
    //   } else if (
    //     data.sellingOption === 'AVOID_LEAVING_SINGLE' &&
    //     avoid_leaving_single === false
    //   ) {
    //     setErrorType('avoid_leaving_single');
    //     setShowError(true);
    //     return;
    //   }
    // }

    // if (data.numberOfTickets <= 1) {
    //   data.sellingOption = 'ANY';
    // }
    // // let finalSeats = seats;
    // if (isBlockNA) {
    //   finalSeats = seats.concat(
    //     Array.from(
    //       { length: data.numberOfTickets - seats.length },
    //       () => ({ row: '-', seatNumber: '-' })
    //     )
    //   );
    // } else if (seatsRequired) {
    //   if (seats.length === 0) {
    //     finalSeats = Array.from(
    //       { length: data.numberOfTickets },
    //       () => ({ row: '-', seatNumber: '-' })
    //     );
    //   } else {
    //     finalSeats = seats.concat(
    //       Array.from(
    //         { length: data.numberOfTickets - seats.length },
    //         () => ({ row: '-', seatNumber: '-' })
    //       )
    //     );
    //   }
    // }
    if (data.numberOfTickets <= 1) {
      data.sellingOption = 'ANY';
    }
    const selectedCheckboxesString = selectedCheckboxes.join('|');

    data = {
      ...data,
      restrictionsAndFeatures: selectedCheckboxesString,
      seats: [],
      numberOfTickets: Number(data.numberOfTickets),
      pricePerTicket: Number(data.pricePerTicket),
      facevaluePerTicket: Number(data.facevaluePerTicket),
      reservedPricePerTicket: Number(data.reservedPricePerTicket),
      eventId: eventDetails?.id,
      subSectionId: data.subSectionId || data.subSectionId !== '' ? data.subSectionId : undefined,
    };
    console.log("dataaaaaaaaaa", data)
    const res = await post_put_patch_request(
      'post',
      token,
      `${app_api.add_listing}`,
      data,
      dispatch,
      setCustomerLoading,
      onAddListing,
      setCustomerErrorMessage
    );

    if (res !== undefined && res.status < 400) {
      dispatch(setFormData({ data: {}, type: 'listing' }));
      dispatch(setDetails({ details: res?.data?.data, type: 'listing' }));
      checkAlert(res?.data.data);
      setSeats([]);
      reset();
      if (data.ticketsType === 'ELECTRONIC') {
        navigate(app_urls.upload_ticket_dyn(eventDetails?.id));
      } else {
        navigate(app_urls.user_listings);
      }
    } else if (res !== undefined && res.status === 401) {
      dispatch(setLastVisitedPage(path));
      dispatch(setFormData({ data, type: 'listing' }));
    } else {
      dispatch(setFormData({ data: {}, type: 'listing' }));
      dispatch(setDetails({ details: {}, type: 'listing' }));
    }
  };

  const checkAlert = async (data) => {
    dispatch(clearAll({}))
    const body = {
      eventId: data.eventId,
      price: data.pricePerTicket,
      numberOfTickets: data.numberOfTickets,
      sectionId: data.sectionId,
      subSectionId: data.subSectionId,
      sellingOption: data.sellingOption,
      ticketType: data.ticketsType
    }
    const res = await post_put_patch_request(
      'post',
      token,
      `${app_api.set_ticket_alert}`,
      body,
      dispatch,
      setCustomerLoading,
      '',
      setCustomerErrorMessage
    );
   
  }


  const getAllSections = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_sections(eventDetails?.Venue?.id)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res != undefined && res.status < 400) {
      setSections(res.data.data)
    }
  }

  const getAllSUbSections = async (selectedSection) => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_subsections(selectedSection)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res != undefined && res.status < 400) {
      if (res.data.data.length == 0) {
        setValue('subSectionId', '')
      }
      setSubSections(res.data.data)
    }
  }

  const checkIfUserHasConnectedAccount = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.check_if_user_has_account}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res != undefined && res.status < 400) {
      if (res.data.data == false) {
        navigate(app_urls.connectedAcc)
      } else {
        if (userData.phoneVerifiedAt == null) {
          dispatch(
            showPhoneNumberVerificationModalFunc({
              modal: true,
              type: 'welcome',
            })
          )
        }
      }
    }
  }

  const getListingPriceRange = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_listing_price_range(eventDetails?.id)}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res != undefined && res.status < 400) {
      setPriceRange(res?.data?.data)
    }
  }

  return (
    <ListingPageLayout heading={'Your Event'} info_list={info_list}>
      <h5 className="mb-3 fw-bold">Sell Tickets</h5>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div>
          <h6 className="mb-3 fw-bold">HOW MANY TICKETS DO YOU HAVE</h6>
          <div className="form-group mb-3">
            <label htmlFor="numberOfTickets" className="form-label">
              Number of Tickets
            </label>
            <select
              defaultValue={listingFormData?.numberOfTickets ?? ''}
              className="form-select"
              id="numberOfTickets"
              {...register('numberOfTickets', {
                required: {
                  value: true,
                  message: '*this field is required',
                },
              })}
            >
              <option disabled value="">
                Quantity
              </option>
              {elements.map((element) => {
                return (
                  <option key={element} value={element}>
                    {element} {element == 1 ? 'Ticket' : 'Tickets'}
                  </option>
                )
              })}
            </select>
            {errors?.numberOfTickets?.message && (
              <div className="text-danger p mt-1">
                {errors?.numberOfTickets?.message}
              </div>
            )}
          </div>

          <div className="row">
            {priceRange?.minPrice && (
              <span>
                <i className="mdi mdi-information-outline"></i> Tickets for this
                event are listed between {strText.currency_symbol}
                {priceRange?.minPrice} - {strText.currency_symbol}
                {priceRange?.maxPrice} per ticket
              </span>
            )}
            <div className="form-group col-md-6">
              <label htmlFor="pricePerTicket" className="form-label">
                Price Per Ticket ({strText.currency_symbol})
              </label>
              <input
                defaultValue={listingFormData?.pricePerTicket}
                type="text"
                className="form-control"
                autoComplete='off'
                id="pricePerTicket"
                placeholder=""
                maxLength={10}
                {...register('pricePerTicket', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
              />
              {errors?.pricePerTicket?.message && (
                <div className="text-danger p mt-1">
                  {errors?.pricePerTicket?.message}
                </div>
              )}
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="reservedPricePerTicket" className="form-label">
                Reserved Price Per Ticket ({strText.currency_symbol})
              </label>
              <input
                defaultValue={listingFormData?.reservedPricePerTicket}
                type="text"
                className="form-control"
                id="reservedPricePerTicket"
                autoComplete='off'
                placeholder=""
                maxLength={10}
                {...register('reservedPricePerTicket', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                  validate: (value) =>
                    Number(value) <= Number(getValues('pricePerTicket')) ||
                    'Amount greater than price per ticket',
                })}
              />
              {errors?.reservedPricePerTicket?.message && (
                <div className="text-danger p mt-1">
                  {errors?.reservedPricePerTicket?.message}
                </div>
              )}
            </div>

            <div className="form-group col-md-6">
              <label htmlFor="facevaluePerTicket" className="form-label">
                Face Value Per Ticket ({strText.currency_symbol})
              </label>
              <input
                defaultValue={listingFormData?.facevaluePerTicket}
                type="text"
                className="form-control"
                autoComplete='off'
                id="facevaluePerTicket"
                placeholder=""
                maxLength={10}
                {...register('facevaluePerTicket', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
              />
              {errors?.facevaluePerTicket?.message && (
                <div className="text-danger p mt-1">
                  {errors?.facevaluePerTicket?.message}
                </div>
              )}
            </div>
          </div>
        </div>
        {ticketNoValue > 1 && (
          <>
            <hr className="my-4" />

            <div>
              <h6 className="mb-3 fw-bold text-uppercase">
                How would you like to sell?
              </h6>

              <div className="my-3">
                <div className="form-check">
                  <input
                    id="sellingOption"
                    name="paymentMethod"
                    type="radio"
                    defaultChecked={
                      listingFormData?.sellingOption == 'ANY' ? true : false
                    }
                    value={'ANY'}
                    className="form-check-input"
                    {...register('sellingOption', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                  />
                  <label className="form-check-label" htmlFor="sellingOption">
                    Any
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="sellingOption"
                    name="paymentMethod"
                    type="radio"
                    defaultChecked={
                      listingFormData?.sellingOption == 'FULL' ? true : false
                    }
                    value={'FULL'}
                    className="form-check-input"
                    {...register('sellingOption', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                  />
                  <label className="form-check-label" htmlFor="sellingOption">
                    Full
                  </label>
                </div>
                <div className="form-check">
                  <input
                    id="sellingOption"
                    name="paymentMethod"
                    type="radio"
                    defaultChecked={
                      listingFormData?.sellingOption == 'AVOID_LEAVING_SINGLE'
                        ? true
                        : false
                    }
                    value={'AVOID_LEAVING_SINGLE'}
                    className="form-check-input"
                    {...register('sellingOption', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                  />
                  <label className="form-check-label" htmlFor="sellingOption">
                    Avoid leaving single
                  </label>
                </div>
                {errors?.sellingOption?.message && (
                  <div className="text-danger p mt-1">
                    {errors?.sellingOption?.message}
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        <hr className="my-4" />

        <div>
          <h6 className="fw-bold mb-3">WHAT TYPE OF TICKET ARE YOU LISTING</h6>

          <div className="my-3">
            <div className="form-check">
              <input
                id="ticketsType"
                name="paymentMethod"
                type="radio"
                defaultChecked={listingFormData?.ticketsType}
                className="form-check-input"
                value="PHYSICAL"
                {...register('ticketsType', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
              />
              <label className="form-check-label" htmlFor="ticketsType">
                Physical
              </label>
            </div>
            <div className="form-check">
              <input
                id="ticketsType2"
                name="paymentMethod"
                type="radio"
                defaultChecked={listingFormData?.ticketsType}
                className="form-check-input"
                value="ELECTRONIC"
                {...register('ticketsType', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
              />
              <label className="form-check-label" htmlFor="ticketsType2">
                Electronic
              </label>
            </div>
            <div className="form-check">
              <input
                id="ticketsType3"
                name="paymentMethod"
                type="radio"
                defaultChecked={listingFormData?.ticketsType}
                className="form-check-input"
                value="MOBILE"
                {...register('ticketsType', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
              />
              <label className="form-check-label" htmlFor="ticketsType3">
                Mobile
              </label>
            </div>
            {errors?.ticketsType?.message && (
              <div className="text-danger p mt-1">
                {errors?.ticketsType?.message}
              </div>
            )}
            {/* <div className="form-check">
                <input id="paypal" name="paymentMethod" type="radio" className="form-check-input" />
                <label className="form-check-label" htmlFor="paypal">PayPal</label>
                </div> */}
          </div>
        </div>


        <div>
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="fw-bold me-3 mb-0">Are there any restrictions/features on your tickets ?</h6>
            {console.log("pinkkk",activeButton == 'yes')}
            <div className="d-flex">
              <button
                className={`btn btn-lg me-2 btn-no-ticket ${activeButton == 'yes' ? 'btn-active' : 'btn-default'}`}
                type="button"
                onClick={handleYesClick}
              >
                YES
              </button>
              <button
                className={`btn btn-lg btn-no-ticket ${activeButton == 'no' ? 'btn-active' : 'btn-default'}`}
                type="button"
                onClick={handleNoClick}
              >
                NO
              </button>
            </div>
          </div>
          {showCheckboxes && (
            <div className="mt-3 p-3 border tickets-listsec">
              <div className="grid-container">
                {checkboxLabels.map((label, index) => (
                  <div className="form-check" key={index}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={`checkbox-${index}`}
                      onChange={() => handleCheckboxChange(label)}
                      checked={selectedCheckboxes.includes(label)}
                    />
                    <label className="form-check-label mb-0" htmlFor={`checkbox-${index}`}>
                      {label}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {ticketNoValue && (
          <>
            <hr className="my-4" />

            <div>
              <h6 className="mb-3 fw-bold">WHERE ARE YOUR SEATS</h6>
              {/* {console.log("sectionsData",sectionsData.length)} */}
              {sectionsData.length !== 0 && (
                <div className="form-group mb-3">
                  <label htmlFor="sectionId" className="form-label">
                    Section
                  </label>
                  <select
                    className="form-select"
                    id="sectionId"
                    {...register('sectionId', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                    onChange={(e) => {
                      getAllSUbSections(e.target.value)
                    }}
                  >
                    <option value="">Select Section</option>
                    {sectionsData.map((section) => {
                      return (
                        <option key={section.id} value={section.id}>
                          {section.name}
                        </option>
                      )
                    })}
                  </select>
                  {errors?.sectionId?.message && (
                    <div className="text-danger p mt-1">
                      {errors?.sectionId?.message}
                    </div>
                  )}
                </div>
              )}
              {subSections.length !== 0 && (
                <div className="form-group mb-4">
                  <label htmlFor="subSectionId" className="form-label">
                    Block
                  </label>
                  <select
                    className="form-select"
                    id="subSectionId"
                    {...register('subSectionId')}
                  >
                    {/* <option value="">Select Subsection</option> */}
                    <option value="">N/A</option>
                    {subSections.map((subSection) => {
                      return (
                        <option key={subSection.id} value={subSection.id}>
                          {subSection.name}
                        </option>
                      )
                    })}
                  </select>
                  {errors?.subSectionId?.message && (
                    <div className="text-danger p mt-1">
                      {errors?.subSectionId?.message}
                    </div>
                  )}
                </div>
              )}
<div className="form-group col-md-8">
                        <label htmlFor="row" className="form-label">
                          Row (optional)
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="row"
                          readOnly={clickedAddSeat}
                          placeholder=""
                          maxLength={10}
                         {...register('row', {
                            required: {
                              value: false
                             
                            },
                          })}
                        />
                        {errors?.row?.message && (
                          <div className="text-danger p mt-1">
                            {errors?.row?.message}
                          </div>
                        )}
                      </div>
              {/* <div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <div
                    style={{ columnGap: '1rem' }}
                    className="d-flex flex-wrap align-items-center"
                  >
                    <h6 className="mb-0 fw-bold">Tickets</h6>
                    <div
                      style={{ columnGap: '0.6rem' }}
                      className="d-flex flex-wrap align-items-center"
                    >
                      {Number(ticketNoValue) !== seats.length && (
                        <button
                          type="button"
                          onClick={() => {
                            setErrorType('');
                            setShowError(false);
                            if (subSectionIdValue !== '') {
                              if (!rowValue || rowValue === '') {
                                setError('row', {
                                  type: 'manual',
                                  message: 'Row is required',
                                });
                              } else if (!seatNoValue || seatNoValue === '') {
                                setError('seatNumber', {
                                  type: 'manual',
                                  message: 'Seat number is required',
                                });
                              } else {
                                setSeats((prev) => [
                                  ...prev,
                                  {
                                    id: prev.length + 1,
                                    row: rowValue,
                                    seatNumber: seatNoValue,
                                  },
                                ]);
                                clearErrors('row');
                                clearErrors('seatNumber');
                                // resetField('row');
                                 resetField('seatNumber');
                                 setClickedAddSeat(true);
                              }
                            } else {
                              const newRowValue = rowValue || '-';
                              const newSeatNoValue = seatNoValue || '-';

                              setSeats((prev) => [
                                ...prev,
                                {
                                  id: prev.length + 1,
                                  row: newRowValue,
                                  seatNumber: newSeatNoValue,
                                },
                              ]);
                              clearErrors('row');
                              resetField('seatNumber');
                              clearErrors('seatNumber');
                              setClickedAddSeat(true); 
                            }
                          }}
                          style={{ backgroundColor: 'rgba(0,0,0,0.1)' }}
                          className="btn"
                        >
                          Add Seat
                        </button>
                      )}

                      {showError && errorType === 'quantity' && (
                        <span className="text-danger p mt-1">
                          {Number(ticketNoValue) > seats.length
                            ? 'Please click button to add seat'
                            : `Please remove ${seats.length - Number(ticketNoValue)
                            } 
                                    seat${seats.length - Number(ticketNoValue) > 1
                              ? 's'
                              : ''
                            }`}
                        </span>
                      )}
                      {showError && errorType === 'full' && (
                        <span className="text-danger p mt-1">
                          For full selling option, the rows need to be the same
                        </span>
                      )}
                      {showError && errorType === 'avoid_leaving_single' && (
                        <span className="text-danger p mt-1">
                          For avoid leaving single selling option, there should
                          not be any unique rows
                        </span>
                      )}
                    </div>
                    <p className='text-danger'><i className="mdi mdi-information-outline"></i> Seats listed must be next to each other
                    </p>
                  </div>
                  {Number(ticketNoValue) > seats.length ? (
                    <p>
                      {seats.length} added, remaining{' '}
                      {Number(ticketNoValue) - seats.length}
                    </p>
                  ) : (
                    <p>
                      {seats.length} added, extra{' '}
                      {seats.length - Number(ticketNoValue)}
                    </p>
                  )}
                </div>
                {Number(ticketNoValue) !== seats.length && (
                  <div className="mb-2">
                    <div className="row">
                      <div className="form-group col-md-8">
                        <label htmlFor="row" className="form-label">
                          Row
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="row"
                          readOnly={clickedAddSeat}
                          placeholder=""
                          maxLength={10}
                        
                          {...register('row', {
                            required: {
                              value: watch('subSectionId') != '', 
                              message: '*this field is required',
                            },
                          })}
                        />
                        {errors?.row?.message && (
                          <div className="text-danger p mt-1">
                            {errors?.row?.message}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="seatNumber" className="form-label">
                          Seat No.
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="seatNumber"
                          placeholder=""
                          maxLength={10}
                         
                          {...register('seatNumber', {
                            required: {
                              value: watch('subSectionId') != '', 
                              message: '*this field is required',
                            },
                          })}
                        />
                        {errors?.seatNumber?.message && (
                          <div className="text-danger p mt-1">
                            {errors?.seatNumber?.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {seats.map((seat, ind) => {
                  return (
                    <div
                      key={seat.id}
                      className="border px-1 pb-1 mb-2 rounded"
                    >
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-sm"
                          onClick={() => {
                            let x = seats.filter(
                              (option) => option.id !== seat.id
                            )
                            setSeats(x)
                            if (Number(ticketNoValue) === seats.length) {
                              setErrorType('')
                              setShowError(false)
                              setClickedAddSeat(true);
                            }else{
                              setClickedAddSeat(false);
                            }
                          }}
                        >
                          <i
                            style={{ fontSize: '1rem' }}
                            className="mdi mdi-close"
                          ></i>
                        </button>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-8">
                          <label htmlFor="cc-name" className="form-label">
                            Row
                          </label>
                          <input
                            disabled
                            value={seat.row}
                            type="text"
                            className="form-control"
                            id="cc-name"
                            placeholder=""
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="cc-name" className="form-label">
                            Seat No.
                          </label>
                          <input
                            disabled
                            value={seat.seatNumber}
                            type="text"
                            className="form-control"
                            id="cc-name"
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div> */}
            </div>
          </>
        )}

        <hr className="my-4" />
        <div className="link-text-paynow my-2">By clicking on “Continue” you agree to our <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer" className="link-paynow">Terms and Conditions</a> and the <a href="/privacy-policy" target="_blank"  className="link-paynow" rel="noopener noreferrer">Privacy Policy</a>.</div>

        <>
          {customerLoading ? (
            <Spinner isForm={true} />
          ) : (
            <button className="w-100 btn btn-primary btn-lg" type="submit">
              Continue
            </button>
          )}
        </>
      </form>
    </ListingPageLayout>
  )
}
