import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap';
import { format } from 'date-fns';
import { clearAll, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import { onUpdate, setAdminErrorMessageUser, setAdminLoadingUser } from '../../../../config/redux/slice/adminSlice'
import Spinner from '../../../components/Spinner'

export default function CurrentstatusDescription({ showPopup, setShowPopup, orderId, changedPrice, setOrderStatusId }) {
    const [description, setDescription] = useState('');
    const [staticStatusData, setStaticStatusData] = useState('');
    const [reportedBy, setReportedBy] = useState('buyer'); // Default to 'Reported by buyer'
    const dispatch = useDispatch()
    const { token } = useSelector((state) => state.authData)
    const [submitting, setSubmitting] = useState(false);
    const [commentInput, setCommentInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [inputError, setInputError] = useState(false); // Add state for input validation
    const {
        adminLoading,
       
      } = useSelector((state) => state.admin)
    const handleClose = () => {
        setShowPopup(false);
    };
    const handleSubmit = async () => {
        if (submitting) return; // Prevent further submission if already submitting

        if (!commentInput.trim()) {
            setInputError(true); // Set error state if the input is empty
            return;
        }


        try {
            let orderData = {};

            if (orderId && changedPrice) {

                orderData = {
                    finalPriceBeforeAdminChange: changedPrice,
                    statusId: setOrderStatusId,
                    comment: commentInput,
                };

                const res = await post_put_request_promoCode(
                    "patch",
                    token,
                    `${app_api.orderPriceChange(orderId)}`,
                    orderData,
                    dispatch,
                setAdminLoadingUser,
                '',
                setAdminErrorMessageUser,
                true
                );

                if (res !== undefined && res.status < 400) {
                    dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
                }
                else {
                    dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
                }
            }
            handleClose()
        } catch (error) {
            console.error("Error in saving status:", error);
        }
    };

    useEffect(() => {
        getChangeAmountList();
    }, [])

    const getChangeAmountList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getAmountHistoryList(setOrderStatusId, orderId)}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let resData = res?.data?.data || [];
            setStaticStatusData(resData);
        }
        setLoading(false);
    }

    return (
        <Modal show={showPopup} onHide={handleClose} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title>Price Changed History</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {orderId && changedPrice && (

                    <div className="d-flex mb-3">
                        <input
                            type="text"
                            value={commentInput}
                            onChange={(e) => setCommentInput(e.target.value)}
                            placeholder="Add your comment"
                            className={`form-control ${inputError ? 'border-danger' : ''}`} // Apply red border if error

                        />
                 {adminLoading ?
                    <Spinner isForm={true} /> :
                        <Button variant="primary" onClick={handleSubmit} className="ml-2">
                            Save
                        </Button>
                  }

                    </div>
                )}
                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>Submitted On</th>
                                        <th scope="col" className='no-wrap'>User Details</th>
                                        <th scope="col" className='no-wrap'>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {staticStatusData && staticStatusData.length > 0 ? (
                                        staticStatusData.map((item) => (
                                            <tr key={item}>
                                                <td>{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</td>
                                                <td>{item.userName} <br/> {item.userRole}</td>
                                                <td>{item.comment}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="4">No details available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
