import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {  
  onGetUser
} from '../../../../config/redux/slice/authSlice'
import { clearAll, onUpdate, setCustomerErrorMessage, setCustomerLoading, setLastVisitedPage, setDeliveryDetails, setAddressDetails } from '../../../../config/redux/slice/customerSlice'
import { onAddToCart, onGetCart, onRemoveFromCart, setCartValues, setData } from '../../../../config/redux/slice/appSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { formatNumber, setCartNo, transformTicketsList1 } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import Spinner from '../../../components/Spinner'
import { app_urls } from '../../../../utils/urls/app_urls'
import { useDebounce } from '../../../../hooks/useDebounce'
import { country_list } from '../../../../helpers/countries'
import RestrictionsPage from './RestrictionsPage'
import { useForm } from 'react-hook-form'
import CountryComponent from '../../../components/Form/CountryComponent'
import { app_api } from '../../../../utils/endpoints/app_api'


export default function ConfirmationPage({ onClick }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openSeat, setOpenSeat] = useState(false)
  const [listingId, setListingId] = useState('')
  const [sum, setSum] = useState(0)
  const [cartAmountData, setCartAmountData] = useState({})
  const [isRemovingFromCart, setIsRemovingFromCart] = useState({})
  const [address, setAddress] = useState(''); //XD_J
  const [postCode, setPostCode] = useState(''); //XD_J
  const [phoneNumber, setPhoneNumber] = useState(''); //XD_J
  const [firstLineAddress, setFirstLineAddress] = useState('');  //XD_J
  const [countryId, setCountryId] = useState('');  //XD_J
  const [city, setCity] = useState('');  //XD_J
  const [postalCode, setPostalCode] = useState('');  //XD_J
  const [town, setTown] = useState('');  //XD_J
  const [errors, setErrors] = useState({}); //XD_J

  const [country_code, set_country_code] = useState('Select Country')
  const [filtered_country_list, set_filtered_country_list] = useState(country_list)
  const [search_value, set_search_value] = useState('')
  const search_value_debounced = useDebounce(search_value, 200)
  const [promoCode, setPromoCode] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [discount, setDiscount] = useState('0.00');
  const [finalAmount, setFinalAmount] = useState('0.00');
  const [localPostalCodeError, setLocalPostalCodeError] = useState('');
  const [localTownError, setLocalTownError] = useState('');

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    // clearErrors,
    trigger,
    formState: { error },
    reset,
  } = useForm({ mode: 'onTouched' })


  useEffect(() => {
    if (search_value_debounced) {
      set_filtered_country_list(country_list.filter((option) => (option.name.toLowerCase()).includes((search_value_debounced.toLowerCase()))))
    } else {
      set_filtered_country_list(country_list)
    }
  }, [search_value_debounced])

  const {
    customerLoading,
    paymentManagement: { clientPayment }
  } = useSelector((state) => state.customer)

  const {
    token,
    userData
  } = useSelector((state) => state.authData)

  const {
    cartManagement: { cartData, cartNumber }
  } = useSelector((state) => state.app)
  
  const handleTextChange = (fieldName, value) => {
    console.log(fieldName, value);
    if (fieldName === 'firstLineAddress') {
      if (value.length > 50) {
        setLocalAddr1Error('*Maximum length is 50 characters');
      } else {
        setLocalAddr1Error('');
        setFirstLineAddress(value);
        clearErrors('firstLineAddress');
      }
    } else if (fieldName === 'city') {
      if (value.length > 20) {
        setLocalTownError('*Maximum length is 20');
      } else {
        setLocalTownError('');
        setCity(value);
        clearErrors('city');
      }
    } 
    
    else if (fieldName === 'postalCode') {
      if (value.length > 10) {
        setLocalPostalCodeError('*Maximum length is 10');
      } else {
        setLocalPostalCodeError('');
        setPostalCode(value);
        clearErrors('postalCode');
      }
    } else if (fieldName === 'town') {
      if (value.length > 20) {
        setLocalTownError('*Maximum length is 20');
      } else {
        setLocalTownError('');
        setTown(value);
        clearErrors('town');
      }
    } else if (fieldName === 'countryId') {
      setCountryId(value);
      if (value.trim()) {
        clearErrors('countryId');
      } else {
        setErrors(prevErrors => ({ ...prevErrors, countryId: 'This field is required' }));
      }
    } else if (fieldName === 'address') {
      setAddress(value);
      if (value.trim()) {
        clearErrors('address');
      } else {
        setErrors(prevErrors => ({ ...prevErrors, address: 'Address is required' }));
      }
    } else if (fieldName === 'postCode') {
      setPostCode(value);
      if (value.trim()) {
        clearErrors('postCode');
      } else {
        setErrors(prevErrors => ({ ...prevErrors, postCode: 'Post Code is required' }));
      }
    } else if (fieldName === 'phoneNumber') {
      setPhoneNumber(value);
      if (value.trim()) {
        clearErrors('phoneNumber');
      } else {
        setErrors(prevErrors => ({ ...prevErrors, phoneNumber: 'Phone Number is required' }));
      }
    }
  };
  const clearErrors = (fieldName) => {
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      delete newErrors[fieldName];
      return newErrors;
    });
  };
  useEffect(() => {
    getCartTotal()
  }, [cartData])

  useEffect(() => {
    let x = 0
    cartData.forEach((listing) => {
      listing.Tickets.forEach((opt) => {
        x = Number(opt.price) + x
      })
    })
    setSum(x)
  }, [cartData])

  const getCartTotal = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_cart_total}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res !== undefined && res.status < 400) {
      setCartAmountData(res?.data?.data);
      setFinalAmount(res?.data?.data?.totalPrice);
      console.log('aaa', finalAmount);
    }

  }

  const setCartData = async (data) => {
    dispatch(onGetCart(data))
    dispatch(setData({ data: data, type: 'cart' }))
    let cartNo = setCartNo(data)
    dispatch(setCartValues({ propertyKey: 'cartNumber', value: cartNo }))
  }
  //XD_J

  const handleApplyClick = async () => {
    console.log('cart Data:', cartData);
    let eventId = cartData[0]?.event.id;
    let promoCodeValue = promoCode;
    console.log('promoCodeValue', promoCodeValue);
    const res = await post_put_patch_request(
      "post",
      token,
      app_api.apply_promoCode,
      { promoCode: promoCodeValue, eventId },
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      console.log('cart', cartAmountData);
      if (res !== undefined && res.status < 400) {
        console.log('res', res);
        dispatch(onUpdate({ message: res?.data.message, type: 'sales' }))

        const discountPercentage = res?.data?.data?.percentage; // Extract the discount percentage from the response
        const promoCodeId = res?.data?.data?.id;
        if (discountPercentage && cartAmountData?.totalPrice) {
          const totalPrice = parseFloat(cartAmountData?.totalPrice);
          const discountAmount = (cartAmountData.totalPrice * discountPercentage) / 100;
          const reducedPrice = cartAmountData.totalPrice - discountAmount;
          setTotalPrice(totalPrice);
          setDiscount(discountAmount);
          setFinalAmount(reducedPrice);
          let data = await post_put_patch_request(
            "post",
            token,
            app_api.save_promoCode,
            {
              promoCode,            // Promo code to be applied
              discount: discountAmount,   // Calculated discount amount
              reducedPrice,
              totalPrice,
              promoCodeId
            },
            dispatch,
            setCustomerLoading,
            "",
            setCustomerErrorMessage
          )
        }
      }

    };
  }

  const select_country_code = (code) => {
    //setValueFunc(`${fieldName2}`,code)
    set_country_code(code)

    //clearErrorsFunc('code')
    set_search_value('')
  }
  
  const validateAddress = () => {
    console.log('inside validate address');
    const errors = {};
    if (!firstLineAddress.trim()) {
      errors.firstLineAddress = 'This field is required';
    }
    console.log(errors.firstLineAddress);
    if (!postalCode.trim()) {
      errors.postalCode = 'This field is required';
    }
    if (!countryId.trim()) {
      errors.countryId = 'This field is required';
    }
    if (!city.trim()) {
      errors.city = 'This field is required';
    }
    return errors; // Return the errors object
  };
  
  const validateForm = () => {
    const errors = {};
    if (!address.trim()) {
      errors.address = 'Address is required';
    }
    if (!postCode.trim()) {
      errors.postCode = 'Post Code is required';
    }
    if (!phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required';
    }
    if (!town.trim()) {
      errors.town = 'Town is required';
    }
    return errors; // Return the errors object
  };
  const removeFromCart = async (ticketDetails, sellTogether, index) => {
    dispatch(clearAll({}))
    setIsRemovingFromCart({
      [index]: true
    })
    let objId = ticketDetails.custom_id
    // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
    let ticketIds = []
    ticketDetails.Tickets.forEach((ticketOption) => {
      ticketIds = [...ticketIds, ticketOption.id]
    })
    // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
    let data = { ticketIds }
    // dispatch(setFormData({data, type: 'listing'}))
    const res = await post_put_patch_request(
      "patch",
      token,
      `${app_api.remove_from_cart}`,
      data,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
    )
    // if(res !== undefined && res.status >= 400){
    //   dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber + 1}))
    //     dispatch(onAddToCart({ticketDetails, sellTogether}))
    // } 
    if (res !== undefined && res.status < 400) {
      setCartData(res?.data?.data)
      setIsRemovingFromCart({
        [index]: false
      })
      // dispatch(onRemoveFromCart({id:objId, sellTogether, listing: ticketDetails, ticketIds}))
      // dispatch(setCartValues({propertyKey: 'cartNumber', value: cartNumber - 1}))
    } else {
      setIsRemovingFromCart({
        [index]: false
      })
    }

  }

  const [errorAddress, setAddressError] = useState('');
  const [errorFirstLineAddress, setFirstLineAddressError] = useState('');
  const [errorCountryId, setCountryIdError] = useState('');
  const [errorCity, setCityError] = useState('');
  const [errorPostalCode, setPostalError] = useState('');
  const [postCodeError, setPostCodeError] = useState('');
  const [townError, setTownError] = useState('');
  const [localPhoneError, setLocalPhoneError] = useState('');
  const [localAddr1Error, setLocalAddr1Error] = useState('');


  const handleAddressChange = (e) => {
    const { value } = e.target;
    if (value.length > 50) {
      setAddressError('*Maximum length is 50 characters');
    } else {
      setAddressError('');
      setAddress(value);
    }
  };
  const handleTownChange = (e) => {
    const { value } = e.target;
    if (value.length > 30) {
      setTownError('*Maximum length is 30 characters');
    } else {
      setTownError('');
      setTown(value);
    }
  };

 const onClickContinue = async () => {
    dispatch(clearAll({}));

    try {
      // Trigger validation for the permanent address fields
      const isValid = await trigger([
        "firstLineAddress",
        "city",
        "countryId",
        "postalCode",
      ]);

      console.log("Permanent address validation:", isValid);

      if (!isValid) {
        return;
      }

      // Gather valid address details
      let addressDetails = {
        firstLineAddress: getValues("firstLineAddress"),
        town: getValues("city"),
        countryId: getValues("countryId"),
        postCode: getValues("postalCode"),
      };

      console.log("Valid address:", addressDetails);

      dispatch(clearAll({}));

      const res = await post_put_patch_request(
        "patch",
        token,
        `${app_api.update_address}`,
        addressDetails,
        dispatch,
        setCustomerLoading,
        "",
        ""
      );
      if (res !== undefined && res.status < 400) {
        // Handle success logic
        // {userData.firstLineAddress === null &&
        //   userData.postCode === null &&
        //   userData.country === null &&
        //   userData.town === null && (
        // dispatch(onGetUser(res.data.data))
        //  dispatch(onGetUser({ firstLineAddress: addressDetails.firstLineAddress }))
      //  onClick();
        return true;
      }
    } catch (error) {
      console.error("Error submitting permanent address:", error);
    }
  };
 
  const formatPhoneNumber = (phoneNumber) => {
    // Remove leading zeros
    const cleanedNumber_ = phoneNumber.replace(/^0+/, '');
    // Remove any non-digit characters from the phone number
    const cleanedNumber = cleanedNumber_.replace(/\D/g, '');
    // Format the number with spaces between groups of digits
    const formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
    return `${country_code} ${formattedNumber}`;
    //return formattedNumber;
  };


  return (
    <>
      <h5 className="mb-3 fw-bold">Order Confirmation</h5>
      {userData.firstLineAddress === null &&
        userData.postCode === null &&
        userData.country === null &&
        userData.town === null && (
          <div>
            <h6>Permanent Address Details</h6>

            <div className="col-md-12 mt-3 mb-3 d-flex flex-column align-items-center justify-content-center">
              <div className="row col-md-12">
                <div className="form-group col-md-6 mb-2 mt-3">
                  <label htmlFor="firstLineAddress">
                    First Line Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="firstLineAddress"
                    autoComplete="off"
                    {...register('firstLineAddress', { required: 'This field is required' })}
                    onChange={(e) => handleTextChange('firstLineAddress', e.target.value)}
                  />

                  {errors.firstLineAddress && <div className="text-danger">{errors.firstLineAddress}</div>}

                </div>



                {/* City */}
                <div className="form-group col-md-6 mb-2 mt-3">
                  <label htmlFor="town">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="city"
                    autoComplete="off"
                    {...register('city', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                    onChange={(e) => handleTextChange('city', e.target.value)}
                  />
                  {errors?.city && (
                    <div className="text-danger small mt-1">{errors?.city}</div>
                  )}
                </div>
              </div>

              <div className="row col-md-12">

                {/* Country */}
                <div className="form-group col-md-6 mb-2">
                  <label htmlFor="city">
                    Country <span className="text-danger">*</span>
                  </label>

                  <CountryComponent
                    register={register}
                    isReq={true}
                    fieldName={'countryId'}
                    fieldName2={'country'}
                    text="Select Country"
                    setValue={setValue}
                    setErrorFunc={setError}
                    getValueFunc={getValues}
                    errors={errors?.countryId}
                    getEndpoint={app_api.get_countries}
                    searchEndpoint={app_api.search_countries}
                    value={countryId}
                    autoComplete="off"
                    onChange={(value) => {
                      handleTextChange('countryId', value);
                    }} />
                  {errors.countryId && <div className="text-danger">{errors.countryId}</div>}

                </div>

                {/* Postal Code */}
                <div className="form-group col-md-6 mb-2">
                  <label htmlFor="postalCode">
                    Postal Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="postalCode"
                    autoComplete="off"
                    {...register('postalCode', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                    onChange={(e) => handleTextChange('postalCode', e.target.value)}
                  />
                  {localPostalCodeError && (
                    <div className="text-danger small mt-1">{localPostalCodeError}</div>
                  )}
                  {errors.postalCode && <div className="text-danger">{errors.postalCode}</div>}

                </div>
              </div>
            </div>
          </div>
        )}
      <div className='confirmation-card'>
        {(cartData.length > 0 && cartData[0].ticketsType === "PHYSICAL") &&
          <>
            <h6 className='fw-bold'>Delivery Details</h6>
            <div className="row w-100">
              <div className="col-md-6 mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <input type="text" className="form-control" id="address" value={address}
                   onChange={(e) => handleTextChange('address', e.target.value)}                 
                   />
                {errorAddress && <div className="text-danger small mt-1">{errorAddress}</div>}
                {errors.address && <div className="text-danger">{errors.address}</div>}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="postCode" className="form-label">Post Code</label>
                <input type="text" className="form-control" id="postCode" value={postCode}  onChange={(e) => handleTextChange('postCode', e.target.value)}                 
 />
                {postCodeError && <div className="text-danger small mt-1">{postCodeError}</div>}
                {errors.postCode && <div className="text-danger">{errors.postCode}</div>}
              </div>
            </div>
            <div className="row w-100">

              <div className="col-md-6 mb-3">
                <label htmlFor="town" className="form-label">Town</label>
                <input type="text" className="form-control" id="town" value={town}  onChange={(e) => handleTextChange('town', e.target.value)}                 
 />
                {townError && <div className="text-danger small mt-1">{townError}</div>}
                {errors.town && <div className="text-danger">{errors.town}</div>}
              </div>
              
              <div className="col-md-6 mb-3">
                <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                <input type="text" className="form-control" id="phoneNumber" value={phoneNumber} onChange={(e) => handleTextChange('phoneNumber', e.target.value)} />
                {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
              </div>

            </div>
          </>}
        {/* XD_J */}
        <div className='mb-4'>
          <h6 className='fw-bold'>
            <span className="text-dark me-2">Cart</span>
            <span className="badge bg-warning rounded-pill">{cartNumber}</span>
          </h6>
          <>
            {cartData.length != 0 ?
              <>
                {cartData.map((listingOption, ind) => {
                  return <React.Fragment key={ind}>
                    {transformTicketsList1(listingOption?.Tickets, listingOption).map((option, index) => {
                      return <React.Fragment key={option.id}>
                        <>
                          <div className='mb-4'>
                            <span className={`badge text-white me-2 mb-1 ${option?.ticketIds.length > 1 ? 'bg-success' : 'bg-danger'}`}>
                              {option?.ticketIds.length > 1 ? 'Group' : 'Single'}
                            </span>
                            <div className='pb-4 px-4 pt-4 border position-relative'>
                              <p className="ticket-text mb-2"><span className="fw-bold">Event: </span>
                                {listingOption?.event?.name}</p>
                              <p className="ticket-text mb-2"><span className="fw-bold">Section: </span>
                                {listingOption?.section?.name}</p>

                              {option?.ticketIds.length > 1 ?
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-2">
                                    <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                      {listingOption?.subSection?.name}</p>
                                    <p className="ticket-text mb-2">
                                      <span className="fw-bold">Ticket Type: </span>
                                      {listingOption?.ticketsType === 'ELECTRONIC' && listingOption?.Tickets.some(ticket => ticket.file !== null)
                                        ? 'ELECTRONIC (Instant)'
                                        : listingOption?.ticketsType}
                                    </p>
                                    <p className="ticket-text mb-2"><span className="fw-bold">No. of Tickets </span>
                                      {option?.numberOfTickets}</p>
                                </div>
                                  <div className="d-flex flex-column align-items-center gap-2">
                                    <div className="d-flex flex-column gap-2">
                                      {listingOption?.pricePerTicket == option.price ?
                                        <>
                                          <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                            {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                                          <p className="ticket-text mb-2"><span className="fw-bold">Total Price </span>
                                            {strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</p>
                                        </>
                                        :
                                        <>
                                          <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                                            <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option?.price)}</span>
                                            <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>
                                          <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Total Price </span>
                                            <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(option?.price))}</span>
                                            <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(Number(option?.numberOfTickets) * Number(listingOption?.pricePerTicket))}</span>
                                          </p>
                                        </>
                                      }
                                    </div>
                                    <button
                                      style={{ minWidth: '70px' }}
                                      onClick={() => {
                                        removeFromCart(
                                          { ...listingOption, custom_id: option.id, Tickets: option.detailsList },
                                          'neither', index)
                                      }}
                                      className={`btn ticket-btn bg-danger text-white`}
                                      disabled={customerLoading}
                                    >
                                      {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{ width: '1rem', height: '1rem' }} /> :
                                        'Remove'}
                                    </button>
                                  </div>
                                </div>
                                :
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex flex-column gap-2">
                                    <p className="ticket-text mb-2"><span className="fw-bold">Block </span>
                                      {listingOption?.subSection?.name}</p>
                                    <p className="ticket-text mb-2">
                                      <span className="fw-bold">Ticket Type: </span>
                                      {listingOption?.ticketsType === 'ELECTRONIC' && listingOption?.Tickets.some(ticket => ticket.file !== null)
                                        ? 'ELECTRONIC (Instant)'
                                        : listingOption?.ticketsType}
                                    </p>

                                  </div>
                                  <div className="d-flex flex-column align-items-center gap-2">
                                    {listingOption?.pricePerTicket == option.price ?
                                      <p className="ticket-text mb-2"><span className="fw-bold">Price </span>
                                        {strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</p>
                                      :
                                      <p className="ticket-text mb-2 position-relative"><span className="fw-bold">Price </span>
                                        <span className='bottom-50 mb-1 position-absolute'>{strText.currency_symbol}{formatNumber(option.price)}</span>
                                        <span className='text-decoration-line-through'>{strText.currency_symbol}{formatNumber(listingOption?.pricePerTicket)}</span></p>
                                    }
                                    <button
                                      style={{ minWidth: '70px' }}
                                      onClick={() => {
                                        removeFromCart(
                                          { ...listingOption, custom_id: option.id, Tickets: option.detailsList },
                                          'neither', index)
                                      }}
                                      className={`btn ticket-btn bg-danger text-white`}
                                      disabled={customerLoading}
                                    >
                                      {isRemovingFromCart?.[index] ? <Spinner color='text-white' spinner_styles={{ width: '1rem', height: '1rem' }} /> :
                                        'Remove'}
                                    </button>
                                  </div>
                                </div>
                              }
                               </div>

                          </div>
                          {listingOption?.restrictionsAndFeatures && listingOption.restrictionsAndFeatures.trim() !== '' && (
                            <div className='pb-4 px-4 pt-4 border position-relative'>
                              <div>
                                <h6 className="fw-bold me-3 mb-0" style={{ color: "#000", fontSize: "16px" }}>
                                  Features & Restrictions
                                </h6>
                                <div className="mt-3 p-3 border tickets-listsec">
                                  <div className="grid-container">
                                    {listingOption.restrictionsAndFeatures
                                      .split('|')
                                      .map((feature, index) => (
                                        <div className="form-check" key={index}>

                                          <i className="fa fa-arrow-right" style={{ marginRight: "5px", fontSize: "12px" }}></i>
                                          <label className="mb-0" htmlFor={`checkbox-${index}`}>
                                            {feature.trim()}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      </React.Fragment>
                    })}
                  </React.Fragment>
                })}
              </>
              :
              <p className='text-muted'>No Items In Cart</p>
            }
          </>
        </div>
        <div
          style={{ columnGap: '0.6rem' }}
          className="border py-2 px-2 d-flex flex-column"
        >
          <div className="d-flex align-items-center">
            <span className="fw-bold">Use Promo Code</span>
            <div>
              <input
                type="text"
                placeholder="Enter promo code"
                style={{ marginRight: '10px' }}
                value={promoCode}
                className='promocode-input'
                onChange={(e) => setPromoCode(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleApplyClick}>Apply</button>
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-bold">Total Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(cartAmountData?.totalPrice)}
              </span>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <span className="fw-bold">Discount Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(discount)}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="fw-bold">Final Price</span>
              <span className="fw-bold">
                {strText.currency_symbol}
                {formatNumber(finalAmount)}
              </span>
            </div>
          </div>

        </div>


        <hr className="my-4" />
        <>
          {/* {
            cartData.length > 0 &&

              <button
                onClick={async () => {
                  
                  const containsPhysicalTicket = cartData.some(item => item.ticketsType === "PHYSICAL");

                  const addressErrors = validateAddress();
                  const formErrors = validateForm();


                  let allErrors = {};
                  if(containsPhysicalTicket){
                    allErrors = {...formErrors }; // Combine both errors
                  }
                  if(!userData.firstLineAddress){
                    allErrors = {...allErrors,...addressErrors }; // Combine both errors
                  }
                  

                  setErrors(allErrors); // Set all errors
                  const isValid = Object.keys(allErrors).length === 0; // Check if there are no errors
                 
                  if(containsPhysicalTicket){
                    if (!userData.firstLineAddress) { 
                      if (isValid) {
                        dispatch(
                          setDeliveryDetails({
                            address,
                            postCode,
                            phoneNumber,
                            town,
                          })
                        );
                        dispatch(
                          setAddressDetails({
                            firstLineAddress,
                            postalCode,
                            countryId,
                            city,
                          })
                        );
                        await onClickContinue();
                      }
                    }
                    else{
                      if (isValid) {
                        dispatch(
                          setDeliveryDetails({
                            address,
                            postCode,
                            phoneNumber,
                            town,
                          })
                        );
                        onClick();
                      }
                    }                    
                  }
                  else if (!userData.firstLineAddress) {     
                    if (isValid) {               
                      dispatch(
                        setAddressDetails({
                          firstLineAddress,
                          postalCode,
                          countryId,
                          city,
                        })
                      );
                      await onClickContinue();
                    }
                  } 
                  else {
                    dispatch(
                      setDeliveryDetails({
                        address,
                        postCode,
                        phoneNumber,
                        town,
                      })
                    );
                    onClick();
                  }
                 }}

               
                className="w-100 btn btn-primary btn-lg"
                type="button"
              >
                Continue to Payment
              </button>
   

          } */}

{
  cartData.length > 0 && (
    <button
      onClick={async () => {
        // Check if cart contains physical tickets
        const containsPhysicalTicket = cartData.some(item => item.ticketsType === "PHYSICAL");

        // Get form errors only if it's a physical ticket, otherwise skip form validation
        const formErrors = containsPhysicalTicket ? validateForm() : {};
        const addressErrors = validateAddress();

        // Combine errors if firstLineAddress is missing
        let allErrors = { ...formErrors };
        if (!userData.firstLineAddress) {
          allErrors = { ...allErrors, ...addressErrors };
        }

        setErrors(allErrors); // Set errors to state
        const isValid = Object.keys(allErrors).length === 0; // Check if no errors

        // Log validation result for debugging
        console.log("Is form valid?", isValid);

        // If firstLineAddress is missing for any ticket type, call onClickContinue first
        if (!userData.firstLineAddress) {
          console.log("FirstLineAddress is missing, triggering onClickContinue...");

          if (isValid) {
            const success = await onClickContinue();
            if (success) {
              console.log("onClickContinue succeeded, now calling onClick...");
              onClick();
            } else {
              console.log("onClickContinue failed, not proceeding to onClick.");
            }
          } else {
            console.log("Form or address is not valid, not calling onClickContinue.");
          }
        } 
        // If firstLineAddress is present, call onClick directly
        else {
          console.log("FirstLineAddress is present, calling onClick directly...");
          if (isValid) {
            onClick();
          } else {
            console.log("Form is not valid, not calling onClick.");
          }
        }
      }}
      className="w-100 btn btn-primary btn-lg"
      type="button"
    >
      Continue to Payment
    </button>
  )
}





        </>

      </div>

    </>
  )
}
