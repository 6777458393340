import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import GeneralWrapper from '../layout/GeneralWrapper';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import search_icon from '../../assets/images/search_icon.svg';
import { app_urls } from '../../utils/urls/app_urls';
import { useNavigate } from 'react-router-dom';
import EventsImg from "../../assets/images/schedule.png"

export default function SearchEventList() {
    const location = useLocation(); // Get the current location object
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTopEvents, setFilteredTopEvents] = useState([]);
    const navigate = useNavigate();

    const {
        eventManagement: { searchResult },
    } = useSelector((state) => state.app);

    //const { token, userData } = useSelector((state) => state.authData);

    const queryParams = new URLSearchParams(location.search);
    const landingPage = queryParams.get('l');

    const eventData = useSelector((state) => state.app.eventManagement.eventData);
    const topTrendingEvents = eventData;

    useEffect(() => {
        const query = queryParams.get('q') || '';
        setSearchQuery(query);
    }, [location.search]);


    useEffect(() => {
        // Filter events based on the current search query state
        const filteredEvents = topTrendingEvents.filter((event) =>
            event.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredTopEvents(filteredEvents);
    }, [searchQuery, topTrendingEvents]); // Run this effect when the searchQuery or events change


    const searchEvent = () => {
        const landingPageValue = landingPage || 'buy';
        if (searchQuery.trim()) {
            navigate(`${app_urls.search_event_list}?q=${encodeURIComponent(searchQuery)}&l=${landingPageValue}`);
        }
    };

    const handleSearchInputChange = (e) => {
        setSearchQuery(e.target.value); // Update the searchQuery state on input change
    };

    const handleTopEventsClick = (event) => {

        // setIsOpen(false);
        const { id } = event;
        if (landingPage === 'sell') {
            navigate(app_urls.sell_ticket_dyn(id));
        } else {
            window.location.href = app_urls.single_event_dyn(id);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            searchEvent();
        }
    };

    return (
        <GeneralWrapper>
            <div className="px-3 px-lg-100 px-xl-200 mt-3 mb-5">
                <div className="WordSection1">
                    <p className="MsoNormal eventsearch-breadcrumb" style={{ marginLeft: "-.25pt" }}>
                        Home / Search Results
                    </p>
                    {/* <hr /> */}
                    <div className="bg-inputSearchEvent">
                        <div className="input-group form-inp input-serachbtn">
                            <input
                                required
                                type="text"
                                autoComplete="off"
                                className="form-control form-inp border-radius"
                                placeholder="Search for your event"
                                aria-label="Example text with button addon"
                                aria-describedby="button-addon1"
                                value={searchQuery} // bind input value to searchQuery state
                                onChange={handleSearchInputChange} // handle input change
                                onKeyDown={handleKeyPress} // handle key press event
                            />
                            <button
                                className="btn  form-inp search-input-header serchbtn-sec"
                                type="button"
                                id="button-addon1"
                                onClick={searchEvent} // onClick event calls searchEvent function
                                style={{marginLeft:"10px"}}
                            >
                                <img className="searchIcon-header" src={search_icon} alt="search" />
                            </button>
                        </div>
                    </div>
                    <p className="MsoNormal seacrh-resulteventList">
                        Search Results for: <strong>{searchQuery}</strong>
                    </p>

<div className="">
                    <h4 className='eventList-headtext'><img src={EventsImg} alt="Events" className='img-fluid eventlistImg'/>Events</h4>

                   <div className="border-bottom-events"></div>

                    <div
                        className={`form-inp ${filteredTopEvents.length > 0 ? 'bg-white' : ''}`}
                        style={{ width: "100%", padding:"7px 0" }}
                    >
                        {filteredTopEvents.length > 0 ? (
                            filteredTopEvents.slice(0, 100).map((event) => (
                                <div
                                    key={event.id}
                                    onClick={() => {
                                        handleTopEventsClick(event)
                                    }}
                                    className="d-flex align-items-center bg-white searchbar-event searchbar-event-hover border-btmEventList py-2 px-1 form-inp"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img
                                        width={80}
                                        height={80}
                                        crossOrigin="anonymous"
                                        src={event.poster}
                                        alt="poster"
                                    />
                                    <p style={{ fontSize: '0.9rem', marginLeft: "15px" }} className="card-text">
                                        <span className="text-accent fw-bold">
                                            {event.name}
                                        </span>
                                        <br />
                                        <span className="fw-bold">
                                            {moment(event.date).format('ddd Do MMM YYYY - HH:mm')}
                                        </span>
                                        <br />
                                        <span>{event.Venue.name}</span>
                                    </p>
                                </div>
                            ))
                        ) : (
                            <div className="text-center bg-white py-2">
                                <span>No events found</span>
                            </div>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        </GeneralWrapper>
    );
}
