import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
// import { clearResetBot, setAdminErrorMessageUser, setAdminLoadingUser, setDetails, showAddUserFormFunc, showUserModalFunc } from '../../../../config/redux/slice/adminSlice'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import moment from 'moment'
import CreateUser from './CreateUser'
import Pagination from "react-js-pagination";
import ViewTicket from '../../customer/ticket_management/ViewTicket';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request } from '../../../../helpers/request'
import ToggleStatusListing from '../../admin/listing_management/ToggleStatusListing';
import BankDetails from '../../admin/user_management/BankDetails'
import SellerDeatils from '../../admin/listing_management/SellerDetails'
import {
  setDetails,
  showAddUserFormFunc,
  showUserModalFunc,
} from '../../../../config/redux/slice/adminSlice';
import { buildAddress } from '../../../../helpers/helpers'
import DeleteUser from './DeleteUser';
import SuspendUser from './SuspendUser';
import { color } from 'd3';
// import DeleteListing from './DeleteListing';

// import { format } from 'date-fns';
export default function UserTableCopy({ userTypes }) {
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authData)
  const [listingAllDetails, setListingDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [nestedExpandedRows, setNestedExpandedRows] = useState({});
  const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [recordsTotal, setRecordsTotal] = useState("");
  const [ticketData, setTicketData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDbPopup, setShowDbPopup] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null);

  const [selectedUserType, setSelectedUserType] = useState('');

  useEffect(() => {
    getListingDetails()
  }, [])

  // Function to handle user type change
  const handleUserTypeChange = (event, item) => {
    const newUserType = event.target.value;
    setSelectedUserType(newUserType);
    setItem({ ...item, userType: newUserType }, 'changeusertype');
  };

  const filterUserTypeChange = async (event, item) => {
    const newUserType = event.target.value;
    console.log('newUser', newUserType);
    setSelectedUserType(newUserType);

    setLoading(true);
    setListingDetails([]);

    dispatch(clearAll({}));

    const pageSize = 10;

    if (newUserType === '') {
      // Call getListingDetails() if newUserType is an empty string
      await getListingDetails();
    } else {

    const res = await get_delete_request(
      'get',
      token,
      `${app_api.search_user}?isSeller=false&page=1&pageSize=10&draw=2&userType=${newUserType}`,

      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      true
    );
    if (res !== undefined && res.status < 400) {
      setListingDetails(res.data.data)
      setRecordsTotal(res.data.recordsFiltered)
    }
    setLoading(false);
    setActivePage(1);
  }
  }
 

  const getListingDetails = async (query = '') => {
    setLoading(true);
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=false&page=1&pageSize=10&draw=1&q=&_=1725022853948`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      true
    )
    if (res !== undefined && res.status < 400) {
      let pending_list = res?.data?.data || [];
      let totalCount = res?.data?.recordsTotal
      setListingDetails(pending_list);
      setRecordsTotal(totalCount);
      setSearchTerm('')
    } else {
      setListingDetails([]);
    }
    setLoading(false);
  }

  // const {
  //   userManagement: { userDetails, showListingModal: { show, type } }
  // } = useSelector((state) => state.admin)

  const {
    userManagement: {
      showAddUserForm,
      userDetails,
      showUserModal: {show, type}
    }
    } = useSelector((state) => state.admin)

  const toggleRow = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    setExpandedRows(prevExpandedRows => ({
      ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
      [index]: !prevExpandedRows[index]
    }));
    setNestedExpandedRows({});
    setNestedExpandedRowsList({});
  };

  const setItem = (data, type) => {
    if (type == 'delete' || type == 'suspend' || type == 'changeusertype') {
      dispatch(setDetails({ details: data, type: 'user' }))
      dispatch(showUserModalFunc({ modal: true, type }))
    }

  }
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleSearchChange = async (event) => {
    console.log('searchTerm', searchTerm);

    setLoading(true);
    setListingDetails([]);

    dispatch(clearAll({}));

    const pageSize = 10;

    const res = await get_delete_request(
      'get',
      token,
      `${app_api.search_user}?isSeller=false&page=1&pageSize=10&draw=2&q=${searchTerm}`,

      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      true
    );
    if (res !== undefined && res.status < 400) {
      setListingDetails(res.data.data)
      setRecordsTotal(res.data.recordsFiltered)
    }
    setLoading(false);
    setActivePage(1);
  }
  const handlePageChange = async (pageNumber) => {
    setLoading(true);
    dispatch(clearAll({}));

    const pageSize = 10;
    setActivePage(pageNumber);

    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=false&page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1725022853948`,

      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      true
    );
    if (res !== undefined && res.status < 400) {
      setListingDetails(res.data.data);
      setRecordsTotal(res.data.recordsFiltered);

    }
    setLoading(false);
  }
  const handleDeliveryaddressClick = (item) => {
    setShowDbPopup(true)
    setSelectedItem(item);
  }
 
  return (

    <>
    {showAddUserForm &&
          <CreateUser userTypes={userTypes} getListingDetails={getListingDetails}/>
        } 
      <div className="payable-tblouter order-tbl">
        <button onClick={() => dispatch(showAddUserFormFunc({ modal: true }))} className='btn btn-sm btn-outline-primary'>
          <i style={{ fontSize: '1rem' }} className="mdi mdi-plus-circle me-1"></i>
          Add User
        </button>
        <div className="form-group search-input-top align-items-center">
          <lable style={{color:"#000"}}>UserType:</lable>
        <select
            className="btn btn-sm select-user-type"
            value={selectedUserType}
            onChange={filterUserTypeChange}
          >
            <option value="">All</option> {/* Option to show all */}
            {userTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
          <input autoComplete='off' type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
          <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>

        </div>
        <div className="table-responsive web-show-tbl">
          <table className="table table-striped mt-3 tbl-pending">
            <thead>
              <tr>
                <th scope="col" className='no-wrap'>Buyer Details</th>
                <th scope="col" className='no-wrap'>Favourite Teams</th>
                <th scope="col" className='no-wrap'>Favourite Sports</th>
                <th scope="col" className='no-wrap'>Set User Type</th>
                <th scope="col" className='no-wrap'>Joined On</th>
                <th scope="col" className='no-wrap'>Address</th>
                <th scope="col" className='no-wrap'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                </tr>
              )
                : listingAllDetails.length > 0 ? (
                  listingAllDetails?.map((item, index) => (
                    <>
                      <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                        <td className="txt-ellipsis-user"><div><b>{item?.name}</b></div>
                        <div>
                        <div className="txt-ellipsis-user" title={item.email}>{item?.email}</div>
                          {item.phone}
                        </div>
                        </td>
                       <td className='address-field'>{item.favouriteClub}</td>
                        <td className='address-field'>{item.favouriteSports}</td>
                        <td>
                        <div>
                       <select
                            data-id={item.id}
                            className="btn btn-sm select-setuserType btn-icon change-user-type"
                            onChange={(e) => handleUserTypeChange(e, item)}
                          >
                            {userTypes.map((type) => (
                              <option key={type} value={type} selected={type === item.userType}>
                                {type}
                              </option>
                            ))}
                          </select>
                          </div>
                        </td>
                        <td className="joined-Date">{moment(item?.createdAt).format('ddd DD MMM YYYY').toUpperCase()}</td>
                        <td className="address-field">{buildAddress({
                          houseName: item?.houseName,
                          firstLineAddress: item?.firstLineAddress,
                          secondLineAddress: item?.secondLineAddress,
                          town: item?.town,
                          postCode: item?.postCode,
                          country: item?.country?.name,
                        })}</td>
                        <td><a onClick={() => setItem(item, 'suspend')} title='Supend User'
                          class={`btn btn-sm text-white btn-icon suspend-user btn-m-right ${item.isBlocked === true ? 'bg-dark' : 'bg-warning'}`}>
                          <i class='fa fa-times'></i>
                        </a>
                          <a title="Delete Listing"
                            onClick={() => setItem(item, 'delete')}
                            data-id={item?.id}
                            class="btn btn-sm bg-danger btn-icon text-white delete-listing btn-m-right">
                            <i class="fa fa-trash"></i>
                          </a>{item.BankDetails.length > 0 &&  <span className='delivery-add-link btn-m-right delivery-fontsize' onClick={() => handleDeliveryaddressClick(item)}>Bank Details</span>}
                  
                       
                        </td>
                      </tr>

                    </>
                  ))

                ) : (
                  <tr>
                    <td colSpan="8" className="text-center py-4">No Data Found</td>
                  </tr>
                )}
            </tbody>
          </table>

        </div>




      </div>
      {console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhh",showDbPopup == true)}
      {showDbPopup == true &&
        <BankDetails setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup} selectedItem={selectedItem} />}
      {recordsTotal > 0 ?
        <div className='pagination-wrapper'>
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={recordsTotal}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
          />
        </div>
        : ""}

      {/* <div>
                {(show && type == 'delete') &&
                    <DeleteListing listingObj={listingDetails} getListingDetails={getListingDetails} />}
                {(show && type == 'toggle_status') &&
                    <ToggleStatusListing listingObj={listingDetails} getListingDetails={getListingDetails} />}
            </div> */}

      {(show && type == 'delete') &&
        <DeleteUser user={userDetails} getListingDetails={getListingDetails} />}
      {(show && type == 'suspend') &&
        <SuspendUser user={userDetails} getListingDetails={getListingDetails} />}
    </>
  );
}
