import React, { useEffect } from 'react'
import primetic_logo from '../../assets/images/primetic_logo.png'
import default_img from '../../assets/images/default-img.jpeg'
import { navigationStructure } from '../../utils/nav_structure'
import { Link, useNavigate } from 'react-router-dom'
import { app_urls } from '../../utils/urls/app_urls'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../config/redux/slice/authSlice'
import { logoutCustomerUser } from '../../config/redux/slice/customerSlice'
import { logoutAppUser, setBreadCrumbName } from '../../config/redux/slice/appSlice'
import { system_user_roles } from '../../constants/english'
import { logoutAdminUser } from '../../config/redux/slice/adminSlice'

export default function NavbarLayout() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    userData,
    token
  } = useSelector((state) => state.authData)

  const { isActive } = useSelector((state) => state.app)

  const logout = (e) => {
    e.preventDefault()
    dispatch(logoutUser())
    dispatch(logoutCustomerUser())
    dispatch(logoutAppUser())
    dispatch(logoutAdminUser())
    
  }

  return (
    <nav className="navbar customer navbar-expand-lg fixed-top navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand order-1 order-lg-1 app-logo-mr" to={app_urls.home}>
          <img className='app-logo' src={primetic_logo} alt='primetic logo' />
        </Link>
        <button className="navbar-toggler border-0 order-3 order-lg-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 xillidevSud */}
        <div className='d-none d-md-flex mx-auto order-1 text-center flex-column'>
          <span style={{ fontSize: "0.9em", color: "#142862" }} className='content-center-width'>
          As a marketplace, sellers set the prices for tickets listed on PrimeTic.
         
            <br />
            <span style={{ display: "block", textAlign: "center" }}>
            Prices may be higher or lower than face value.            </span>
          </span>
        </div>
        {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 xillidevSud */}

        <div className="collapse navbar-collapse order-3 order-lg-1" id="navbarNav" style={{flexGrow:"0"}}>

          <ul className="navbar-nav ms-lg-auto">
            {navigationStructure.general_navigation.map((option) => {
              return <li key={option.id} className="nav-item">
                <Link to={option.url} className={`${option.url == isActive ? 'active' : ''} nav-link fw-bold text-primary`} aria-current="page">{option.name}</Link>
              </li>
            })}
          </ul>

          {/* <ul className='p-0 m-0'>
    
                  <li className="dropdown user-menu">
                    <button className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                      <img src={default_img} className="user-image rounded-circle" alt="User" />            
                    </button>
                    <ul style={{fontSize: '0.8rem'}} className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={app_urls.profile}>
                          <i className="mdi mdi-account-outline"></i>
                          <span className="nav-text">My Profile</span>
                        </Link>
                      </li>
  

                      <li className="dropdown-footer">
                        <a onClick={(e)=>{logout(e)}} className="dropdown-footer-item" href="#"> <i className="mdi mdi-logout"></i> Log Out </a>
                      </li>
                    </ul>
                  </li>
                </ul> */}
        </div>
         
       
        {/* {system_user_roles.includes((userData.user_role).toLowerCase()) == false && 
            <> */}
        {(token && token != '' && userData?.email != '') ?
          <ul style={{ width: '60px', flexBasis: 'auto' }} className='ms-auto ms-lg-0 p-0 m-0 order-2 order-lg-3'>

            <li className="dropstart user-menu">
              <button className="dropdown-toggle nav-link" data-bs-toggle="dropdown">
                <img crossOrigin='anonymous' src={(!userData?.avatar || userData.avatar == '') ? default_img : userData?.avatar} className="user-image rounded-circle" alt="User" />
              </button>
              <ul style={{ fontSize: '0.8rem' }} className="dropdown-menu">
                {system_user_roles.includes((userData.user_role).toLowerCase()) == false ?
                  <>
                    {/* <li>
                          <Link className="dropdown-item" to={app_urls.dashboard}>
                            <i className="mdi mdi-view-dashboard"></i>
                            <span className="nav-text">Dashboard</span>
                          </Link>
                        </li> */}
                    <li>
                      <Link className="dropdown-item" to={app_urls.dashboard}>
                        <i className="mdi mdi-account-outline"></i>
                        <span className="nav-text">Dashboard</span>
                      </Link>
                    </li>
                  </>
                  :
                  <li>
                    <Link
                      onClick={() => {
                        dispatch(setBreadCrumbName([{ id: 1, name: 'Dashboard', url: app_urls.admin_dashboard }]))
                      }}
                      className="dropdown-item" to={app_urls.admin_dashboard}>
                      <i className="mdi mdi-account-outline"></i>
                      <span className="nav-text">Dashboard</span>
                    </Link>
                  </li>
                }

                <li className="dropdown-footer">
                  <a onClick={(e) => { logout(e) }} className="dropdown-footer-item" href="#"> <i className="mdi mdi-logout"></i> Log Out </a>
                </li>
              </ul>
            </li>
          </ul>
          :
          <div style={{ flexBasis: 'auto' }} className='ms-auto ms-lg-0 order-2 order-lg-3'>
            <button
              onClick={() => { navigate(app_urls.login) }}
              className='btn btn-accent rounded-pill mt-lg-0 ms-lg-3 fw-medium px-4 btn-textFont btn-textFont-m'
            >
              Login 
            </button>
            {/* <button
              onClick={() => { navigate(app_urls.register) }}
              className='btn btn-accent rounded-pill mt-lg-0 ms-lg-3 fw-medium px-4 btn-textFont'
            >
              Register
            </button> */}
          </div>
        }
        
        {/* </>
              } */}
      </div>
      {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 xillidevSud */}
      <div className='d-md-none d-flex mx-auto order-1 text-center flex-column'>
          <span style={{ fontSize: "0.6em", color: "#142862" }}>
          As a marketplace, sellers set the prices for tickets listed on PrimeTic.
            <br />
            <span style={{ display: "block", textAlign: "center" }}>
            Prices may be higher or lower than face value.            </span>
          </span>
        </div>
        {/* Move the google information "As a marketplace ......" to the top of the homepage. Make the fonts smaller SLNO:22 xillidevSud */}
    </nav>
  )
}
