import React from 'react'

export default function ButtonComponent({btn_text, color, btn_styles, onClick}) {
  return (
    <button 
      onClick={onClick}
      style={{fontSize: '0.9rem'}} 
      className={`fw-bold btn rounded-pill ${color ? color : 'btn-accent'} mt-3  px-4 btn-hovercomunity ${btn_styles}`}
    >
      {btn_text}
    </button>
  )
}
