import { core_backend_url } from "../../config"

export const onboard_api = {
    'login': `${core_backend_url}auth/login`,
    'register': `${core_backend_url}auth/register`,
    'verify_email': (token) => `${core_backend_url}auth/verify-email/${token}`,
    'forgot_password': `${core_backend_url}auth/forgot-password`,
    'reset_password': `${core_backend_url}auth/reset-password`,
    'google_auth': `${core_backend_url}auth/google`,
    'facebook_auth': `${core_backend_url}auth/facebook`,
    'apple_auth': `${core_backend_url}auth/apple`,
    'resend_verification_email': `${core_backend_url}auth/resend-verification-email`,
}