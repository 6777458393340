import React from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import PayoutDueTableNew from './PayoutDueTableNew'

export default function PayoutDueManagementNew() {
  return (
    <AdminWrapper heading={'Payout'}>
      <div className="payable-tblouter order-tbl tbl-bg-white">
        <PayoutDueTableNew/>
      </div>
    </AdminWrapper>
  )
}
