import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, setCustomerErrorMessage, setCustomerLoading, setPaymentValues } from '../../../../config/redux/slice/customerSlice'
import PageLayout from './PageLayout'
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Spinner from '../../../components/Spinner';
import spinner_loader from '../../../../assets/images/Spinner-loading.gif';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { app_api } from '../../../../utils/endpoints/app_api';
import { useForm } from 'react-hook-form';
import { app_urls } from '../../../../utils/urls/app_urls';
import visa_logo from '../../../../assets/images/visa-logo.png';
import american_express_logo from '../../../../assets/images/american-express-logo.png';
import mastercard_logo from '../../../../assets/images/mastercard-logo.png';
import card_logo from '../../../../assets/images/card-icon.png';
import jcb_logo from '../../../../assets/images/jcb-logo.png';
import unionpay_logo from '../../../../assets/images/unionpay-logo.png';
import discover_logo from '../../../../assets/images/discover-logo.png';
import dinersclub_logo from '../../../../assets/images/dinersclub-logo.png';
import { useNavigate } from 'react-router-dom';

// const stripePromise = loadStripe('pk_test_51OJahnIBhxOGHFtBrHyTc35yyCCpMCWuMyEirnxCqBviYb7aaBb5fUpqb7cr7wstrMeOY050VvyiSUPs882tlSov001A8Yprcf');

export default function CheckoutForm() {
    const stripe = useStripe();
  const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {register, handleSubmit, watch, clearErrors, resetField, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [info_list, set_info_list] = useState([])
    const [confirming, setConfirming] = useState(false)
    // const [useExistingDetails, setUseExistingDetails] = useState(true)
    // const [saveCardDetails, setSaveCardDetails] = useState(true);
  const [clientSecretPayment, setClientSecretPayment] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [address, setAddress] = useState('');
    const [postCode, setPostCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [town, setTown] = useState('');

    const {
      cartManagement:{cartData, cartNumber}
    } = useSelector((state) => state.app)

     const {
    token
    } = useSelector((state) => state.authData)

    const {
          paymentManagement: {paymentMethods, saveCardDetails, clientPayment, useExistingDetails}
    } = useSelector((state) => state.customer)

     useEffect(()=>{
      console.log(",...........cartData..............XDSD",cartData);
      
        dispatch(clearAll())
        // setStripePromise(loadStripe('pk_test_51OJahnIBhxOGHFtBrHyTc35yyCCpMCWuMyEirnxCqBviYb7aaBb5fUpqb7cr7wstrMeOY050VvyiSUPs882tlSov001A8Yprcf'))
        // getSetupIntent()
    },[])

    // const getPaymentIntent = async (isHandlePayment=false) =>{
    //   dispatch(clearAll({}))
    //   const res = await get_delete_request(
    //     'get',
    //     token,
    //     `${app_api.create_payment_intent}`,
    //     {},
    //     dispatch,
    //     setCustomerLoading,
    //     "",
    //     ""
    //   )
    //   if(res !== undefined && res.status < 400){
    //     dispatch(setPaymentValues({propertyKey: 'clientPayment', value:res?.data?.data}))
    //     if(isHandlePayment==true){
    //       handlePayment(res?.data?.data)
    //     }else{
    //       pay_or_setup(res?.data?.data)
    //     }
    //   }
    // }

    const locationState = useSelector(state =>  state.customer.deliveryDetails);

    useEffect(() => {
        if (locationState) {
            const { address, postCode, phoneNumber, town } = locationState;
            setAddress(address || '');
            setPostCode(postCode || '');
            setPhoneNumber(phoneNumber || '');
            setTown(town || '');
        }
        {console.log("locationstate",locationState)}
    }, [locationState]);

    const handleCheckTicketAlert = async ()=>{
      
      const cartDataValue = cartData[0]
      const addressData = {
        eventId :cartDataValue.eventId, 
        numberOfTickets :cartDataValue.Tickets?.length,
        price :cartDataValue.pricePerTicket,
        sectionId :cartDataValue.sectionId,
        subSectionId :cartDataValue.subSectionId,
        ticketType :cartDataValue.ticketsType,
      };
      dispatch(clearAll({}))
      console.log("..............XDSDcheckTicketAlertaddressData",addressData);
      const res = await post_put_patch_request(
        'post',
        token,
        `${app_api.checkTicketAlert}`,
        addressData,
        dispatch,
        "",
        "",
        setCustomerErrorMessage
      )
    }
     const getPaymentIntent = async (isHandlePayment=false) =>{
      dispatch(clearAll({}))
      const res = await get_delete_request(
        'get',
        token,
        `${app_api.create_payment_intent}`,
        {},
        dispatch,
        setCustomerLoading,
        "",
        ""
      )
      if(res !== undefined && res.status < 400){
        dispatch(setPaymentValues({propertyKey: 'clientPayment', value:res?.data?.data}))
        handleCheckTicketAlert()
        if(isHandlePayment==true){
          handlePayment(res?.data?.data)
        }else{
          pay_or_setup(res?.data?.data)
        }
      }
    }

   

    const confirmPayment = async (orderId) =>{
      const addressData = {
        address: address, // Pass the address details from the state
        postCode: postCode,
        phoneNumber: phoneNumber,
        town: town
      };
      dispatch(clearAll({}))
      const res = await post_put_patch_request(
        'post',
        token,
        `${app_api.confirm_payment(orderId)}`,
        addressData,
        dispatch,
        "",
        "",
        setCustomerErrorMessage
      )
      if(res !== undefined && res.status < 400){
       setConfirming(false)
       navigate(app_urls.payment_successful)
      }else{
        setConfirming(false)
      }
    }
  
    const pay_or_setup = async (paymentIntentRes) =>{
      if(saveCardDetails){
        handlePaymentSetup()
      }else{
        handlePaymentOnce(paymentIntentRes)
      }
    }

    const handlePaymentSetup = async () => {
    // Confirm the Setup Intent to set up the payment method
    setConfirming(true)
    const setupResult = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: `${window.location.origin}${app_urls.buy_ticket_tab}/2`,
        expand: ['payment_method']
      },
      redirect: 'if_required'
    });

    if (setupResult.error) {
      setConfirming(false)
      dispatch(setCustomerErrorMessage({message: 'Something Went Wrong, please try again later!'}))
    } else {  
      setConfirming(false)
      // Setup successful, now create a Payment Intent
       dispatch(setPaymentValues({propertyKey: 'paymentMethods', value: [setupResult.setupIntent.payment_method, ...paymentMethods]}))
       setPaymentId(setupResult.setupIntent.payment_method.id)
       dispatch(setPaymentValues({propertyKey: 'useExistingDetails', value: true}))
      //  setUseExistingDetails(true)
      // getPaymentIntent()
    }
  };

   const handlePayment = async (paymentIntentRes) => {
    setConfirming(true)
    // Confirm the Payment Intent with the payment method from the Setup Intent
    const paymentResult = await stripe.confirmPayment({
      clientSecret: paymentIntentRes?.client_secret,
      confirmParams: {
    // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: `${window.location.origin}${app_urls.payment_successful}`,
        payment_method: paymentId,
      },
      redirect: 'if_required'
    });

    if (paymentResult.error) {
      setConfirming(false)
      dispatch(setCustomerErrorMessage({message: 'Something Went Wrong, please try again later!'}))
      console.error(paymentResult.error.message);
    } else {
      confirmPayment(paymentIntentRes?.metadata?.order_id)
      // Payment successful
    }
    
  };

  const handlePaymentOnce = async (paymentIntentRes) => {
    setConfirming(true)
    // Confirm the Payment Intent with the payment method from the Setup Intent
    const paymentResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
    // Return URL where the customer should be redirected after the SetupIntent is confirmed.
        return_url: `${window.location.origin}${app_urls.payment_successful}`,
      },
      redirect: 'if_required'
    });

    if (paymentResult.error) {
      setConfirming(false)
      dispatch(setCustomerErrorMessage({message: 'Something Went Wrong, please try again later!'}))
      console.error(paymentResult.error.message);
    } else {
      confirmPayment(paymentIntentRes?.metadata?.order_id)
      // Payment successful
      // dispatch(setPaymentValues({propertyKey: 'selectedPaymentMethod', value: paymentResult.paymentIntent.payment_method}))
      // confirmAddress(paymentIntentRes?.metadata?.order_id)
    }
    
  };

  return (

          <>
          {useExistingDetails ? 
          <>
          <div className='mt-3'>
            {paymentMethods.map((option, ind)=>{
              return <div key={option.id} className='form-check gap-3 border border-2 d-flex align-items-center px-5 py-2'>
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="flexRadioDisabled" 
                  id={`flexRadioDisabled${ind}`}
                  checked={paymentId == option.id}
                  onChange={()=>{
                    setPaymentId(option.id)
                  }}
                />
                <label style={{columnGap: '1rem'}} className="form-check-label mb-0 d-flex align-items-center" htmlFor={`flexRadioDisabled${ind}`}>
                {option?.card?.brand == 'visa' ?
                  <img width={40} src={visa_logo} alt='visa logo'/>
                  : option?.card?.brand == 'mastercard' ?
                  <img width={40} src={mastercard_logo} alt='mastercard logo'/>
                  : option?.card?.brand == 'amex' ?
                  <img width={43} src={american_express_logo} alt='american express logo'/>
                  : option?.card?.brand == 'jcb' ?
                  <img width={35} src={jcb_logo} alt='american express logo'/>
                  : option?.card?.brand == 'discover' ?
                  <img width={35} src={discover_logo} alt='american express logo'/>
                  :
                  <img width={25} src={card_logo} alt='card logo'/>
                }
                    <span>**** **** **** {option?.card?.last4}</span>
                  </label>
                    </div>
              })}
          </div>
          {paymentMethods.length <=10 &&
          <div>
            <button onClick={()=>{
              // setUseExistingDetails(false)
              dispatch(setPaymentValues({propertyKey: 'useExistingDetails', value: false}))
              }}  className='btn btn-sm btn-outline-primary mt-2'>
              <i style={{fontSize: '1rem'}} className="mdi mdi-plus-circle me-1"></i>
              Add Payment Method
            </button>
          </div>
          }
          {paymentMethods.length > 0 && <>
            {!stripe || confirming ? 
              <Spinner isForm={true} class_styles={'mt-2 text-center'}/> :
              <button disabled={paymentId == '' || !paymentId} onClick={()=>{getPaymentIntent(true)}} className="w-100 btn btn-primary btn-lg mt-4" type="button">Pay Now</button>
            }  
          </>}
          <div className="link-text-paynow">By clicking on “pay now” you agree to our <a href="/terms-and-condition" target="_blank" rel="noopener noreferrer" className="link-paynow">Terms and Conditions</a> and the <a href="/privacy-policy" target="_blank"  className="link-paynow" rel="noopener noreferrer">Privacy Policy</a>.</div>
          </> :
            <form onSubmit={handleSubmit(getPaymentIntent)} noValidate>
              <div className='text-end mb-1'>
                <a onClick={()=>{
                  dispatch(setPaymentValues({propertyKey: 'useExistingDetails', value: true}))
                  // setUseExistingDetails(true)
                  dispatch(setPaymentValues({propertyKey: 'saveCardDetails', value: true}))
                  // setSaveCardDetails(true)
                  }} href='#'>Use saved details</a>
              </div>
              <PaymentElement />
              {/* <div className="form-group d-flex align-items-center justify-content-end">
                    <span id="add_statusName">Save Card Details</span>
                    <button type="button" className="btn-icon">
                        <label className="switch">
                            <input onChange={(e)=>{
                              dispatch(setPaymentValues({propertyKey: 'saveCardDetails', value: e.target.checked}))
                              // setSaveCardDetails(e.target.checked)
                              }} checked={saveCardDetails} type="checkbox" name="status" id="add_status"/>
                            <span className="slider round"></span>
                        </label>
                    </button>
                </div> */}
              {!stripe || !elements || confirming ? 
              <Spinner isForm={true} class_styles={'mt-2 text-center'}/> :
              <>
                {saveCardDetails ? 
                 <button className="w-100 btn btn-primary btn-lg mt-4" type="submit">Add Card</button> :
                 <button className="w-100 btn btn-primary btn-lg mt-4" type="submit">Pay Now</button>
                }
              </>
            }  
            </form>}
          </>

  )
}
