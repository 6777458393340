import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  customerLoading: false,
  customerErrorMsg: null,
  customerSuccessMsg: null,
  customerSuccessModal: null,
  isSidebarOpen: false,
  lastVisitedPage: null,
  eventManagement: {
    eventDetails: {},
    selectedMapSection: '',
  },
  sectionManagement: {
    sectionDetails: {},
    sectionsData: [],
  },
  subSectionManagement: {
    subSectionDetails: {},
    subSectionsData: [],
  },
  ticketManagement: {
    newData: false,
    ticketDetails: {},
    ticketsData: [],
    showTicketModal: {
      show: false,
      type: null,
    },
  },
  listingManagement: {
    newData: false,
    listingDetails: {},
    listingFormData: {},
    singleListingDetails: {},
    showListingModal: {
      show: false,
      type: null,
    },
  },
  alertManagement: {
    newData: false,
    listingDetails: {},
    listingFormData: {},
    showAlertModal: {
      show: false,
      type: null,
    },
  },
  profileManagement: {
    newData: false,
    bankDetails: {},
    showProfileModal: {
      show: false,
      type: null,
    },
  },
  orderManagement: {
    newData: false,
    orderDetails: {},
    orderItemsData: [],
    singleOrderDetails: {},
    showOrderModal: {
      show: false,
      type: null,
    },
  },
  payoutManagement: {
    newData: false,
    payoutDetails: {},
    updatedInfo:{},
    payoutItemsData: [],
    singlePayoutDetails: {},
    showPayoutModal: {
      show: false,
      type: null,
    },
  },
  salesManagement: {
    newData: false,
    salesDetails: {},
    salesItemsData: [],
    singleSalesDetails: {},
    showSalesModal: {
      show: false,
      type: null,
    },
  },
  profileListingManagement: {
    newData: false,
    profileListingDetails: {},
    profileListingData: [],
  },
  profileSaleManagement: {
    newData: false,
    profileSaleDetails: {},
    profileSaleData: [],
  },
  profileOrderManagement: {
    newData: false,
    profileOrderDetails: {},
    profileOrderData: [],
  },
  profilePayoutManagement: {
    newData: false,
    profilePayoutDetails: {},
    profilePayoutData: [],
  },
  paymentManagement: {
    paymentMethods: [],
    selectedPaymentMethod: {},
    saveCardDetails: true,
    clientSetup: null,
    clientPayment: null,
    useExistingDetails: true,
    activeTab: 1,
  },
  deliveryDetails: {
    address: '',
    postCode: '',
    phoneNumber: '',
    town: '',
  },
}

export const customerSlice = createSlice({
  name: 'customerApp',
  initialState,
  reducers: {
    logoutCustomerUser: (state, action) => {
      return {
        ...initialState,
        lastVisitedPage: state.lastVisitedPage,
        listingManagement: {
          ...initialState.listingManagement,
          listingFormData: state.listingManagement.listingFormData,
        },
      }
    },
    setDeliveryDetails: (state, action) => {
      const { address, postCode, phoneNumber, town } = action.payload;
      state.deliveryDetails = { address, postCode, phoneNumber, town };
    },
    setAddressDetails: (state, action) => {
      const { firstLineAddress, postalCode, city, countryId } = action.payload;
      state.addressDetails = { firstLineAddress, postalCode, city, countryId };
    },
    clearDeliveryDetails: (state) => {
      state.deliveryDetails = {
        address: '',
        postCode: '',
        phoneNumber: '',
        town: '',
      };
    },
    clearAddressDetails: (state) => {
      state.addressDetails = {
        firstLineAddress: '',
        postalCode: '',
        countryId: '',
        city: '',
      };
    },
    clearAll: (state, action) => {
      state.customerLoading = false
      state.customerErrorMsg = null
      state.customerSuccessMsg = null
    },
    clearResetBot: (state) => {
      state.listingManagement.newData = false
      state.ticketManagement.newData = false
      state.profileListingManagement.newData = false
      state.profileSaleManagement.newData = false
      state.profileOrderManagement.newData = false
      state.profilePayoutManagement.newData = false
      state.profileManagement.newData = false
      state.orderManagement.newData = false
      state.payoutManagement.newData = false
      state.salesManagement.newData = false
    },
    clearOnReload: (state, action) => {
      if (action?.payload?.type === 'payment') {
        if (action?.payload?.clearAll) {
          state.paymentManagement = initialState.paymentManagement
        } else {
          state.paymentManagement = {
            ...initialState.paymentManagement,
            useExistingDetails: state.paymentManagement.useExistingDetails,
            activeTab: 1,
          }
        }
      }
      state.listingManagement.showListingModal = {
        show: false,
        type: null,
      }
    },
    setCustomerLoading: (state, action) => {
      state.customerLoading = action?.payload?.loading
    },
    setCustomerErrorMessage: (state, action) => {
      let msg = action.payload.message
      state.customerLoading = false
      state.customerErrorMsg = msg
    },
    setCustomerSuccessMessage: (state, action) => {
      let msg = action.payload.message
      state.customerLoading = false
      state.customerSuccessMsg = msg
    },
    setCustomerSuccessModal: (state, action) => {
      let msg = action.payload.message
      state.customerLoading = false
      state.customerSuccessModal = msg
    },
    setToggleSidebar: (state, action) => {
      state.isSidebarOpen = !state.isSidebarOpen
    },
    setLastVisitedPage: (state, action) => {
      state.lastVisitedPage = action.payload
    },
    setDetails: (state, action) => {
      if (action.payload.type === 'section') {
        state.sectionManagement.sectionDetails = action.payload.details
      } else if (action.payload.type === 'event') {
        state.eventManagement.eventDetails = action.payload.details
      } else if (action.payload.type === 'subsection') {
        state.subSectionManagement.subSectionDetails = action.payload.details
      } else if (action.payload.type === 'ticket') {
        state.ticketManagement.ticketDetails = action.payload.details
      } else if (action.payload.type === 'listing') {
        state.listingManagement.listingDetails = action.payload.details
      } else if (action.payload.type === 'listing-items') {
        state.listingManagement.singleListingDetails = action.payload.details
      } else if (action.payload.type === 'order') {
        state.orderManagement.orderDetails = action.payload.details
      } else if (action.payload.type === 'order-items') {
        state.orderManagement.singleOrderDetails = action.payload.details
      } else if (action.payload.type === 'payout') {
        state.payoutManagement.payoutDetails = action.payload.details
      } else if (action.payload.type === 'payout-items') {
        state.payoutManagement.singlePayoutDetails = action.payload.details
      } else if (action.payload.type === 'sales') {
        state.salesManagement.salesDetails = action.payload.details
      } else if (action.payload.type === 'sales-items') {
        state.salesManagement.singleSalesDetails = action.payload.details
      } else if (action.payload.type === 'profile') {
        state.profileManagement.bankDetails = action.payload.details
      }
    },
    setData: (state, action) => {
      if (action.payload.type === 'section') {
        state.sectionManagement.sectionsData = action.payload.data
      } else if (action.payload.type === 'ticket') {
        state.ticketManagement.ticketsData = action.payload.data
      }
    },
    onAddTicket: (state, action) => {
      state.ticketManagement.ticketsData = action.payload
    },
    onAddListing: (state, action) => {
      state.customerLoading = false
      state.customerSuccessModal = 'Listing Added Successfully!'
      state.customerErrorMsg = null
    },
    onTicketUploadComplete: (state, action) => {
      state.customerLoading = false
      state.customerSuccessModal = 'Ticket(s) Uploaded Successfully!'
      state.customerErrorMsg = null
    },
    setFormData: (state, action) => {
      if (action.payload.type === 'listing') {
        state.listingManagement.listingFormData = action.payload.data
      }
    },
    onGetTickets: (state, action) => {
      state.customerSuccessMsg = ''
      state.ticketManagement.ticketsData = action.payload
      state.ticketManagement.newData = true
    },
    onGetProfileListings: (state, action) => {
      state.customerSuccessMsg = ''
      state.profileListingManagement.profileListingData = action.payload
      state.profileListingManagement.newData = true
    },
    onGetProfileSales: (state, action) => {
      state.customerSuccessMsg = ''
      state.profileSaleManagement.profileSaleData = action.payload
      state.profileSaleManagement.newData = true
    },
    onGetProfileOrders: (state, action) => {
      state.customerSuccessMsg = ''
      state.profileOrderManagement.profileOrderData = action.payload
      state.profileOrderManagement.newData = true
    },
    onGetProfilePayouts: (state, action) => {
      state.customerSuccessMsg = ''
      state.profilePayoutManagement.profilePayoutData = action.payload
      state.profilePayoutManagement.newData = true
    },
    onUpdate: (state, action) => {
      
      state.customerSuccessMsg = action.payload.message
      console.log("......................................XDSD",state.customerSuccessMsg);

      if (action?.payload?.type === 'ticket') {
        state.ticketManagement.showTicketModal = {
          show: false,
          type: null,
        }
      } else if (action?.payload?.type === 'listing') {
        state.listingManagement.showListingModal = {
          show: false,
          type: null,
        }
      } else if (action?.payload?.type === 'profile') {
        state.profileManagement.showProfileModal = {
          show: false,
          type: null,
        }
        if (action?.payload?.action_type === 'delete') {
          state.profileManagement.newData = true
        }
      } else if (action?.payload?.type === 'order') {
        state.orderManagement.showOrderModal = {
          show: false,
          type: null,
        }
      } else if (action?.payload?.type === 'payout') {
        state.payoutManagement.showPayoutModal = {
          show: false,
          type: null,
        }
      } else if (action?.payload?.type === 'sales') {
        state.salesManagement.showSalesModal = {
          show: false,
          type: null,
        }
      }      
    },
    showTicketModalFunc: (state, action) => {
      state.ticketManagement.showTicketModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showProfileModalFunc: (state, action) => {
      state.profileManagement.showProfileModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showListingModalFunc: (state, action) => {
      state.listingManagement.showListingModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },

    showAlertModalFunc: (state, action) => {
      state.alertManagement.showAlertModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    
    // showAlertsModalFunc: (state, action) => {
    //   state.listingManagement.showListingModal = {
    //     show: action.payload.modal,
    //     type: action.payload.type,
    //   }
    // },
    showOrderModalFunc: (state, action) => {
      state.orderManagement.showOrderModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showPayoutModalFunc: (state, action) => {
      state.payoutManagement.showPayoutModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showPayoutModalFunc1: (state, action) => {
      state.payoutManagement.showPayoutModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showPayoutModalFunc2: (state, action) => {
      state.payoutManagement.showPayoutModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    showSalesModalFunc: (state, action) => {
      state.salesManagement.showSalesModal = {
        show: action.payload.modal,
        type: action.payload.type,
      }
    },
    setPaymentValues: (state, action) => {
      state.paymentManagement[action.payload.propertyKey] = action.payload.value
    },
    setListingValues: (state, action) => {
      state.listingManagement[action.payload.propertyKey] = action.payload.value
    },
    setOrderValues: (state, action) => {
      state.orderManagement[action.payload.propertyKey] = action.payload.value
    },
    setPayoutValues: (state, action) => {
      state.payoutManagement[action.payload.propertyKey] = action.payload.value
    },
    setSalesValues: (state, action) => {
      state.salesManagement[action.payload.propertyKey] = action.payload.value
    },
    // setSelectedMapSection: (state, action) =>{
    //     state.eventManagement.selectedMapSection = action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const {
  clearAll,
  clearResetBot,
  setCustomerErrorMessage,
  setCustomerLoading,
  setCustomerSuccessMessage,
  setCustomerSuccessModal,
  logoutCustomerUser,
  setToggleSidebar,
  setDetails,
  setData,
  setLastVisitedPage,
  onAddListing,
  onAddTicket,
  setFormData,
  onGetTickets,
  onUpdate,
  showTicketModalFunc,
  showProfileModalFunc,
  showOrderModalFunc,
  showSalesModalFunc,
  setPaymentValues,
  clearOnReload,
  onTicketUploadComplete,
  onGetProfileListings,
  onGetProfileSales,
  showListingModalFunc,
  showAlertModalFunc,
  setListingValues,
  setOrderValues,
  setPayoutValues,
  setSalesValues,
  onGetProfileOrders,
  onGetProfilePayouts,
  setDeliveryDetails, // Exporting the new reducers
  setAddressDetails,
  clearDeliveryDetails,
} = customerSlice.actions

export default customerSlice.reducer
