import AdminWrapper from "../../../layout/AdminWrapper";
import React, { useState, useEffect, useRef } from 'react';
import {
  clearAll, onUpdate, setAdminErrorMessageUser,
  setAdminLoadingUser,
} from "../../../../config/redux/slice/adminSlice";
import { app_api } from '../../../../utils/endpoints/app_api';
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request';
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { format } from 'date-fns';
import Spinner from '../../../components/Spinner'



export default function ManualEmail() {
  const [selectedOption, setSelectedOption] = useState('');
  const [recipientType, setRecipientType] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [sellerUserData, setSellerUserData] = useState([]);
  const [buyerUserData, setBuyerUserData] = useState([]);
  const [errors, setErrors] = useState({ step1: '', step2: '', name: '', email: '' });
  const [executivesList, setExecutivesList] = useState([]);
  const { token } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const [selectedUserType, setSelectedUserType] = useState('');
  const [userTypes, setUserTypes] = useState([])

  const [externalEmail, setExternalEmail] = useState('');
  const [externalEmailName, setExternalEmailName] = useState('');
  const [externalReceipts, setExternalReceipts] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [listingId, setListingId] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);

  const {
    adminLoading,
  } = useSelector((state) => state.admin)

  const options = [
    { value: '1', label: 'Reminders with offers' },
    { value: '2', label: 'Cold introduction (Buyers)' },
    { value: '3', label: 'Cold introduction (Sellers)' },
    { value: '4', label: 'Coming soon (Before launch)' },
    { value: '5', label: 'Welcome/post sign-up (Buyer)' },
    { value: '6', label: 'Welcome/post sign-up (Seller)' },
    { value: '7', label: 'Confirmation of purchase (Buyer)' },
    { value: '8', label: 'Ticket Delivery (Buyer)' },
    { value: '9', label: 'Listing confirmation (Seller)' },
    { value: '10', label: 'Confirmation of sale (Seller)' },
    { value: '11', label: 'Confirmation of delivery (Seller)' },
    { value: '12', label: 'Post-event follow up (Buyer)' },
    { value: '13', label: 'Failed order Delivery (Seller)' },
    { value: '14', label: 'Ticket delivery reminder (Seller)' },

  ];


  useEffect(() => {
    getSellerUserDetails();
    getBuyerUserDetails();
    getAllUserTypes();
  }, []);

  const getSellerUserDetails = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=true`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setSellerUserData(res?.data?.data);
      const allSellersList = res.data.data.map(user => ({
        value: user.id,
        label: `${user.name} | ${user.email} | ${user.phone}`,
      }));
      setExecutivesList(allSellersList);
    }
  };
  const getBuyerUserDetails = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_users}?isSeller=false`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setBuyerUserData(res?.data?.data);
      const allBuyersList = res.data.data.map(user => ({
        value: user.id,
        label: `${user.name} | ${user.email} | ${user.phone}`,
      }));
      setExecutivesList(prevList => [...prevList, ...allBuyersList]);
    }
  };

  const getCompletedEvents = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_all_completed_events}`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setEvents(res.data.data || []);
    }
  }
  const getActiveEvents = async () => {
    dispatch(clearAll({}));
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_all_active_events}`,
      {},
      dispatch,
      "",
      "",
      ""
    );
    if (res && res.status < 400) {
      setEvents(res.data.data || []);
    }
  }
  const handleChange = (event) => {
    setRecipientType('');
    setOrderId('');
    setListingId('');
    setErrors('');
    setSelectedEvents([]);
    setSelectedOption(event.target.value);
    if (event.target.value === '12' || event.target.value === '13') {
      getCompletedEvents();
    }
    if (event.target.value === '14') {
      getActiveEvents();
    }
  };

  const handleRecipientChange = async (event) => {
    const value = event.target.value;
    setRecipientType(value);
    setSelectedUserType('');
    setSelectedUsers([]);

    setExecutivesList([]);

    if (value === 'seller') {
      await getSellerUserDetails();
    } else if (value === 'buyer') {
      await getBuyerUserDetails();
    }
    else {
      setExecutivesList([]);
    }
  };


  const handleUserSelection = (selectedOptions) => {
    setSelectedUsers(selectedOptions.map(option => option.value));
  };

  const formatOptionLabel = ({ label }) => {
    const [name, email, phone] = label.split(" | ");
    return (
      <div>
        <b>{name}</b> | {email} | {phone}
      </div>
    );
  };

  const validateForm = () => {
    let isValid = true;
    let errors = { step1: '', step2: '', orderId: '' };
    if (!selectedOption) {
      errors.step1 = 'Please select a subject for the email.';
      isValid = false;
    }
    if (selectedOption === '7' || selectedOption === '8' || selectedOption === '10' || selectedOption === '11') {
      if (!orderId) {
        errors.orderId = 'Order ID is required';
        isValid = false;
      }
    }
    else if (selectedOption === '9') {
      if (!listingId) {
        errors.listingId = 'Listing ID is required';
        isValid = false;
      }
    }
    else if (selectedOption === '12' || selectedOption === '13' || selectedOption === '14') {
      if (!selectedEvents || selectedEvents.length === 0) {
        errors.selectedEvents = 'Please select at least one event';
        isValid = false;
      }
    }
    else {

      if (!recipientType) {
        errors.step2 = 'Please select a recipient type.';
        isValid = false;
      } else if (recipientType === 'selectMembers' && selectedUsers.length === 0) {
        errors.step2 = 'Please select at least one member.';
        isValid = false;
      }
    }

    setErrors(errors);
    return isValid;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const resetForm = () => {
    setSelectedOption('');
    setRecipientType('');
    setSelectedUsers([]);
    setSellerUserData([]);
    setBuyerUserData([]);
    setErrors({ step1: '', step2: '' });
    setExecutivesList([]);
    setSelectedUserType('');
    setExternalEmail('');
    setExternalReceipts('');
  };
  const handleSubmit = async () => {
    if (validateForm()) {
      let selectedData;
      let url = `${app_api.manual_email}`;

      if (selectedOption === '7' || selectedOption === '8' || selectedOption === '10' || selectedOption === '11') {



        selectedData = { selectedOption: selectedOption, orderId: orderId };

      } else if (selectedOption === '12' || selectedOption === '13' || selectedOption === '14') {
        selectedData = { selectedOption: selectedOption, eventIds: selectedEvents };
      }
      else if (selectedOption === '9') {
        selectedData = { selectedOption: selectedOption, listingId: listingId };

      } else {

        if (recipientType === 'everyone') {
          selectedData = { selectedOption: selectedOption, selectedUsers: [] };
          url += `?isEveryOne=true`;
        } else if (recipientType === 'external_receipt') {

          if (externalReceipts.length === 0) {
            let newErrors = { name: '', email: '' };
            newErrors.name = true;
            newErrors.email = true;
            setErrors(newErrors);
            return;
          }

          selectedData = { selectedOption: selectedOption, externalRecipients: externalReceipts };
          url += `?isExternalReceipt=true&isEveryOne=false`;


        } else {
          selectedData = { selectedOption: selectedOption, selectedUsers: selectedUsers };
          if (recipientType === 'seller') {

            if (selectedUserType) {
              url += `?isSeller=true&usertype=${selectedUserType}&isEveryOne=false`;
            }
            else {
              url += `?isSeller=true&isEveryOne=false`;
            }
          } else if (recipientType === 'buyer') {
            if (selectedUserType) {
              url += `?isSeller=false&usertype=${selectedUserType}&isEveryOne=false`;
            }
            else {
              url += `?isSeller=false&isEveryOne=false`;

            }
          }
        }
      }
      let res = await post_put_patch_request(
        "post",
        token,
        url,
        { selectedData },
        dispatch,
        setAdminLoadingUser,
        "",
        setAdminErrorMessageUser,
      );
      if (res !== undefined && res.status < 400) {
        if (res.data.message === 'No data found') {
          dispatch(onUpdate({ message: 'Email Sent Successfully' }))
        } else {
          dispatch(onUpdate({ message: res.data.message }))
        }
        resetForm();
      }
      else {

        dispatch(onUpdate({ message: res.data.message }))

      }
    }
  };

  const onChangeUserType = async (data) => {
    setSelectedUserType(data);

    let filteredUsers;

    if (data) {
      filteredUsers = sellerUserData.filter(user => user.userType === data && user.isSeller === true);
    } else {
      filteredUsers = sellerUserData.filter(user => user.isSeller === true);
    }

    const updatedExecutivesList = filteredUsers.map(user => ({
      value: user.id,
      label: `${user.name} | ${user.email} | ${user.phone}`,
    }));

    setExecutivesList(updatedExecutivesList);
  };

  const onChangeBuyerUserType = async (data) => {
    setSelectedUserType(data);

    let filteredUsers;

    if (data) {
      filteredUsers = buyerUserData.filter(user => user.userType === data && user.isSeller === false);
    } else {
      filteredUsers = buyerUserData.filter(user => user.isSeller === false);
    }

    const updatedExecutivesList = filteredUsers.map(user => ({
      value: user.id,
      label: `${user.name} | ${user.email} | ${user.phone}`,
    }));

    setExecutivesList(updatedExecutivesList);
  };


  const getAllUserTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_auth_user_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {

      setUserTypes(res?.data?.data)
    }
  }
  const handleAddExternalReceipt = () => {
    let newErrors = { name: '', email: '' };

    // Validate the fields
    if (!externalEmailName) {
      newErrors.name = 'Name is required.';
    }

    if (!externalEmail) {
      newErrors.email = 'Email is required.';
    } else {
      const emails = externalEmail.split(',').map(email => email.trim());
      const invalidEmails = emails.filter(email => !validateEmail(email));
      if (invalidEmails.length > 0) {
        newErrors.email = `Invalid email(s): ${invalidEmails.join(', ')}`;
      }
    }

    if (newErrors.name || newErrors.email) {
      setErrors(newErrors);
      return;
    }

    // Add valid emails to the list
    const emails = externalEmail.split(',').map(email => email.trim());
    const newReceipts = emails.map(email => ({ name: externalEmailName, email }));

    setExternalReceipts([...externalReceipts, ...newReceipts]);

    // Clear the input fields
    setExternalEmail('');
    setExternalEmailName('');
    setErrors({ name: '', email: '' });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false); // Close dropdown when clicked outside
    }
  };

  // Attach event listener for clicks outside the dropdown
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEventSelection = (selectedOptions) => {
    setSelectedEvents(selectedOptions.map(option => option.value));
    if (selectedOptions.length > 0) {
      setErrors(prevErrors => ({ ...prevErrors, selectedEvents: '' }));
    }
  };

  const formatEventOptionLabel = ({ name, date, venue }) => (
    <div>
      <span>{name}</span> | <span>{formatDate(new Date(date).toLocaleDateString())}</span> | <span>{venue}</span>
    </div>
  );

  const handleDelete = (indexToDelete) => {
    setExternalReceipts(prevReceipts => prevReceipts.filter((_, index) => index !== indexToDelete));
  };
  const handleOrderIdChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleListingIdChange = (e) => { setListingId(e.target.value) };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'eee, dd MMM yyyy'); // Format date as Mon, 01 July 2024

  }
  return (
    <AdminWrapper>
      <div className="email-bg-white">
        <div>
          <h5 className="email-mainhead">Step 1</h5>
          <h6 className="email-subtext">Email Type</h6>
          <select className="form-select form-select-email" value={selectedOption} onChange={handleChange}>
            <option value="" disabled></option>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {errors.step1 && <p className="text-danger">{errors.step1}</p>}
        </div>
        <div className="mt-5 ml-3">
          <h5 className="email-mainhead">Step 2</h5>
          {(selectedOption !== '7' && selectedOption !== '8' && selectedOption !== '10' && selectedOption !== '11' &&
            selectedOption !== '12' && selectedOption !== '13' && selectedOption !== '14'
          ) && (
            <h6 className="email-subtext">Who do you want to send this email to?</h6>
          )}
          {selectedOption === '7' || selectedOption === '8' || selectedOption === '10' || selectedOption === '11' ? (
            <div className="row align-items-center mb-3">
              <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                <span className="labl-manualemail">Order ID</span>
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  id="orderId"
                  placeholder="Enter Order ID"
                  className="form-control"
                  value={orderId}
                  onChange={handleOrderIdChange}
                />
                {errors.orderId && <p className="text-danger">{errors.orderId}</p>}
              </div>
            </div>
          ) : selectedOption === '9' ? (
            // Show Listing ID for option 9
            <div className="row align-items-center mb-3">
              <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                <span className="labl-manualemail">Listing ID</span>
              </label>
              <div className="col-md-6">
                <input
                  type="text"
                  id="listingId"
                  placeholder="Enter Listing ID"
                  className="form-control"
                  value={listingId}
                  onChange={handleListingIdChange}
                />
                {errors.listingId && <p className="text-danger">{errors.listingId}</p>}
              </div>
            </div>

          ) : selectedOption === '12' || selectedOption === '13' || selectedOption === '14' ? (
            <>
              <div className="row align-items-center mb-3">
                <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                  <span className="labl-manualemail">Select Event</span>
                </label>
                <div className="col-md-6">
                  <Select
                    isMulti
                    id="Event"
                    placeholder="Select Events"
                    options={events.map(event => ({
                      value: event.id,
                      label: event.name,
                      name: event.name,
                      date: event.date,
                      venue: event.venue,
                    }))}
                    value={events
                      .filter(event => selectedEvents.includes(event.id))
                      .map(event => ({
                        value: event.id,
                        label: event.name,
                        name: event.name,
                        date: event.date,
                        venue: event.venue,
                      }))
                    }
                    formatOptionLabel={formatEventOptionLabel}
                    onChange={handleEventSelection}
                  />
                  {errors.selectedEvents && (
                    <span className="text-danger">{errors.selectedEvents}</span>
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="">
              {selectedOption !== '5' && selectedOption !== '6' && (
                <div className="mr-3 d-flex align-items-center">
                  <label>
                    <input
                      type="radio"
                      name="recipient"
                      value="everyone"
                      checked={recipientType === 'everyone'}
                      onChange={handleRecipientChange}
                    />
                    <span className="labl-manualemail">Everyone</span>
                  </label>
                </div>
              )}
              {selectedOption !== '5' && (

                <div className="mr-3 d-flex align-items-center">
                  <label>
                    <input
                      type="radio"
                      name="recipient"
                      value="seller"
                      checked={recipientType === 'seller'}
                      onChange={handleRecipientChange}
                    />
                    <span className="labl-manualemail">Seller</span>
                  </label>
                </div>
              )}




              {recipientType === 'seller' && (
                <>
                  <div className="row align-items-center mb-3">
                    <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                      <span className="labl-manualemail">User Type</span>
                    </label>
                    <div className="col-md-6">
                      <select
                        name="userType"
                        id="userType"
                        className="form-select"
                        value={selectedUserType}
                        onChange={(e) => onChangeUserType(e.target.value)}
                      >
                        <option value="">All</option>
                        {userTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                      <span className="labl-manualemail">Select Members</span>
                    </label>
                    <div className="col-md-6">
                      <Select
                        isMulti
                        id="Member"
                        placeholder="Select Members"
                        options={executivesList}
                        value={executivesList.filter(option => selectedUsers.includes(option.value))}
                        formatOptionLabel={formatOptionLabel}
                        onChange={handleUserSelection}
                        isDisabled={recipientType === 'everyone'}
                      />
                      {errors.step2 && <p className="text-danger">{errors.step2}</p>}
                    </div>
                  </div>
                </>
              )}


              {selectedOption !== '6' && (
                <div className="mr-3 d-flex align-items-center">
                  <label>
                    <input
                      type="radio"
                      name="recipient"
                      value="buyer"
                      checked={recipientType === 'buyer'}
                      onChange={handleRecipientChange}
                    />
                    <span className="labl-manualemail">Buyer</span>
                  </label>
                </div>
              )}

              {recipientType === 'buyer' && (
                <>
                  <div className="row align-items-center mb-3">
                    <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                      <span className="labl-manualemail">User Type</span>
                    </label>
                    <div className="col-md-6">
                      <select
                        name="userType"
                        id="userType"
                        className="form-select"
                        value={selectedUserType}
                        onChange={(e) => onChangeBuyerUserType(e.target.value)}
                      >
                        <option value="">All</option>
                        {userTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row align-items-center mb-3">
                    <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                      <span className="labl-manualemail">Select Members</span>
                    </label>
                    <div className="col-md-6">
                      <Select
                        isMulti
                        id="Member"
                        placeholder="Select Members"
                        options={executivesList}
                        value={executivesList.filter(option => selectedUsers.includes(option.value))}
                        formatOptionLabel={formatOptionLabel}
                        onChange={handleUserSelection}
                        isDisabled={recipientType === 'everyone'}
                      />
                      {errors.step2 && <p className="text-danger">{errors.step2}</p>}
                    </div>
                  </div>
                </>
              )}

              {selectedOption !== '5' && selectedOption !== '6' && (
                <div className="mr-3 d-flex align-items-center">
                  <label>
                    <input
                      type="radio"
                      name="recipient"
                      value="external_receipt"
                      checked={recipientType === 'external_receipt'}
                      onChange={handleRecipientChange} // Make sure to have an onChange handler
                    />
                    <span className="labl-manualemail"> External Recipient</span>
                  </label>
                </div>
              )}
              {recipientType === 'external_receipt' && (
                <>
                  <div className="row align-items-center mb-3">
                    <label className="col-md-2 col-form-label" style={{ textAlign: "right" }}>
                      <span className="labl-manualemail">Add External Recipient</span>
                    </label>
                    <div className="col-md-3">
                      <input
                        type="text"
                        id="external_receipt_name"
                        placeholder="Name"
                        className={`form-control ${errors.name && 'is-invalid'}`} // Apply error style
                        value={externalEmailName}
                        onChange={(e) => setExternalEmailName(e.target.value)}
                      />
                      {errors.name && <p className="text-danger">{errors.name}</p>}
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        id="external_receipt"
                        placeholder="Email"
                        disabled={recipientType === 'everyone'} // Changed `isDisabled` to `disabled`
                        className={`form-control ${errors.email && 'is-invalid'}`} // Apply error style
                        value={externalEmail} // Assuming you're tracking external email in the state
                        onChange={(e) => setExternalEmail(e.target.value)} // Update state for email input
                      />
                      {errors.email && <p className="text-danger">{errors.email}</p>}
                    </div>
                    <div className="col-md-1">
                      <button type="button" className="form-control btn btn-info" onClick={handleAddExternalReceipt}>Add</button>
                    </div>
                  </div>
                  {/* List of added external receipts */}
                  {externalReceipts.length > 0 && (
                    <div className="row">
                      <div className="col-md-5">
                        <ul className="list-group">
                          {externalReceipts.map((receipt, index) => (
                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                              <strong>{receipt.name}</strong>: {receipt.email}
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(index)}
                              >
                                X
                              </button>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="mt-5 ml-3 btn-right-align">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button disabled={adminLoading ? true : false} className="btn btn-primary" onClick={handleSubmit}>Submit</button>

            }
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
}
