import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, onAddTeam, setAdminErrorMessageUser, setAdminLoadingUser, showAddTeamFormFunc } from '../../../../config/redux/slice/adminSlice'
import { truncateMiddle } from '../../../../helpers/helpers'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import AddFormLayout from '../../../layout/admin/AddFormLayout'

export default function AddTeam() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf'];
    
    const {
            adminLoading,
            adminErrorMsg,
            adminSuccessMsg
    } = useSelector((state) => state.admin)

    const {
        token
    } = useSelector((state) => state.authData)

    const [formData, setFormData] = useState(new FormData());

  const closeForm = () => {
      dispatch(showAddTeamFormFunc({modal: false}))
    }

    const onSubmit = async (data) => {
      const newFormData = new FormData();
        dispatch(clearAll({}))

        for (let key in data){ 
          if(typeof data[key] == "object"){
            if(data[key].length != 0){
              newFormData.append(key, data[key][0])
            }
          } else{
            newFormData.append(key, data[key])
          }
        }

        const res = await post_put_patch_request(
          "post",
            token,
            `${app_api.add_team}`,
            newFormData,
            dispatch,
            setAdminLoadingUser,
            onAddTeam,
            setAdminErrorMessageUser,
            true
        )
        if(res !== undefined && res.status < 400){
          reset()
        }
    }

    function addFileName(e, idValue){
      let fileName = e.target.value;
      if(fileName !== ''){
       // fileName = fileName.replace(/^.*[\\\/]/, '')
        fileName = fileName.replace(/^.*[\\\/]/, '').replace(/\s+/g, '_');
        document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30);
        const fileExtension = fileName.split('.').pop().toLowerCase();

        // Validate file extension
        if (!allowedExtensions.includes(fileExtension)) {
          alert('Only JPG, JPEG, PNG, and PDF files are allowed.'); // Inform user about invalid file type
          fileName = ''; // Reset file input to clear invalid selection
          document.getElementById(idValue).textContent = "No file chosen";
        }
    
      }else{
        document.getElementById(idValue).innerHTML = "No file Chosen"
      }
    }

  return (
    <AddFormLayout onClick={closeForm} heading_title='Add New Team' sub_text='Fill in the fields below'>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
            <div style={{rowGap: '1.2rem'}} className="row mb-2">

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor='name'>Name <span className="text-danger">*</span></label>
                  <input 
                  type="text" 
                  className="form-control input-lg" 
                  id="name"
                  {...register("name", {
                      required: {
                      value: true,
                      message: '*this field is required'
                      }
                  })
                  } 
                  />
                  {
                  errors?.name?.message &&
                  <div className="text-danger small mt-1">
                      {errors?.name?.message}
                  </div>
                  }
              </div> 
              </div>

            <div className="col-md-6 col-lg-4">
                <div className='form-group'>
                  <label htmlFor="logo">Logo</label>
                  <div>
                    <div className="custom-file mb-1">
                      <input 
                          type="file" 
                          className="custom-file-input" 
                          id="logo"
                          accept=".jpg,.jpeg,.png,.pdf"
                          {...register("logo", {
                              // required: {
                              // value: true,
                              // message: '*this field is required'
                              // }
                          })
                          } 
                          onChange={(e) => {
                            addFileName(e, 'custom-certificate')
                          }}
                        
                      />
                      <label id="custom-certificate" className="custom-file-label">Choose file...</label>
                      {
                          errors?.logo?.message &&
                          <div className="text-danger small mt-1">
                              {errors?.logo?.message}
                          </div>
                          }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-4">
                <div className="form-group">
                      <label htmlFor='city'>Venue 
                        {/* <span className="text-danger">*</span> */}
                        </label>
                      
                      <SelectComponent
                        // searchValue={search_cities}
                        register={register}
                        isReq={false}
                        fieldName={'venueId'}
                        fieldName2={'venue'}
                        text='Select Venue'
                        setValue={setValue}
                        setErrorFunc={setError}
                        getValueFunc={getValues}
                        errors={errors?.venue?.message}
                        clearErrorsFunc={clearErrors}
                        getEndpoint={app_api.get_venues}
                        searchEndpoint={app_api.search_venue}
                      />
                      {/* {
                        errors?.country?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.country?.message}
                        </div>
                        } */}
                  </div> 
              </div>
            </div>

        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
        <div>
          {adminLoading ? 
            <Spinner isForm={true}/> :
            <button type="submit" className="btn btn-primary me-3">Submit Form</button>
          }
        </div>
          <button disabled={adminLoading ? true : false} onClick={closeForm} type="button" className="btn btn-info">Cancel</button>
        </div>
      </form>
    </AddFormLayout>
  )
}
