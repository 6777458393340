import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import { truncateMiddle } from '../../../../helpers/helpers'
import { clearAll, clearResetBot, onUpdate, setCustomerErrorMessage, setCustomerLoading, setDetails, showProfileModalFunc } from '../../../../config/redux/slice/customerSlice'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { onGetUser } from '../../../../config/redux/slice/authSlice'
import PhoneNumberComponent from '../../../components/Form/PhoneNumberComponent'
import spinner_loader from '../../../../assets/images/Spinner-loading.gif';

export default function BankInformation() {
    const dispatch = useDispatch()
    const {register, handleSubmit, watch, getValues, setError, clearErrors, setValue, formState: {errors}, reset} = useForm({mode: 'onTouched'})
    const [retrieving_details, set_retrieving_details] = useState(true)
    const [bank_details, set_bank_details] = useState([])
    const [add_bank_detail, set_add_bank_detail] = useState(false)
    const {
      token
    } = useSelector((state) => state.authData)

    const {
      customerLoading,
      profileManagement: {bankDetails, newData}
    } = useSelector((state) => state.customer)

    useEffect(()=>{
      getBankInfo()
    }, [])

    // XD_J
    useEffect(() => {
      if (bank_details.length > 0) {
        const bankDetail = bank_details[0];
        setValue('bankName', bankDetail.bankName);
        setValue('accountNo', bankDetail.accountNo);
        setValue('accountName', bankDetail.accountName);
        setValue('sortCode', bankDetail.sortCode);
        setValue('IBAN', bankDetail.IBAN);
      }
    }, [bank_details, setValue]);
// XD_J
  const onSubmit = async (data) => {
    // XD_J

    dispatch(clearAll({}))
    // Check if bank_details array contains a value
    const hasBankDetails = bank_details && bank_details.length > 0;

    // Determine the request method and URL based on the presence of bank details
    const requestMethod = hasBankDetails ? "patch" : "post";
    const requestUrl = hasBankDetails
      ? `${app_api.update_bank_info(bank_details[0]?.id)}` // Update existing bank detail
      : `${app_api.create_bank_info()}`; // Create new bank detail

    try {
      const res = await post_put_patch_request(
        requestMethod,
        token,
        requestUrl,
        data,
        dispatch,
        setCustomerLoading,
        "",
        setCustomerErrorMessage
      );

      if (res !== undefined && res.status < 400) {
        dispatch(onUpdate({ message: res?.data?.message }))
        reset()
        getBankInfo()
        set_add_bank_detail(false)  
      }
    }catch (error) {
      console.error('Error in submitting bank details:', error);
      // Handle the error appropriately here
    }
    // XD_J
  }
  

    const getBankInfo = async (data) => {
        dispatch(clearAll({}))
        set_retrieving_details(true)
          const res = await get_delete_request(
            "get",
              token,
              `${app_api.get_bank_info}`,
              data,
              dispatch,
              "",
              "",
              setCustomerErrorMessage
          )
          if(res !== undefined && res.status < 400){
            console.log(res?.data?.data)
            set_bank_details(res?.data?.data)
            set_retrieving_details(false)
          }
    }

    useEffect(()=>{
      if(newData){
        getBankInfo()
        dispatch(clearResetBot())
      }
    }, [newData])


  return (
    <>
      {retrieving_details ?
        <div style={{height: '93%', position: 'absolute', top: 0, zIndex: 1000, backgroundColor: 'rgba(255,255,255,0.9)'}} className={`w-100 border-top d-flex justify-content-center align-items-center`}>
            <img height={60} src={spinner_loader} alt='loader' />
        </div>
        :
        <div className='border-top py-3'>
          {add_bank_detail ?
          <div className="px-4 px-md-7">
            <button onClick={()=>{set_add_bank_detail(false)}} className='btn btn-outline-accent mb-2'>
                <i style={{fontSize: '1rem'}} className="mdi mdi-arrow-left me-1"></i>
                View Bank Details
            </button>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div style={{rowGap: '1.2rem'}} className="row mb-2">

                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor='bankName'>Bank Name<span className="text-danger">*</span></label>
                        <input 
                        type="text" 
                        autoComplete='off'
                        className="form-control input-lg" 
                        id="bankName"
                        {...register("bankName", {
                                  required: {
                                  value: true,
                                  message: '*this field is required'
                                  }
                              })
                        } 
                        />
                        {
                        errors?.bankName?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.bankName?.message}
                        </div>
                        }
                    </div> 
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor='accountName'>Account Name<span className="text-danger">*</span></label>
                        <input 
                        type="text" 
                        autoComplete='off'
                        className="form-control input-lg" 
                        id="accountName"
                        {...register("accountName", {
                                  required: {
                                  value: true,
                                  message: '*this field is required'
                                  }
                              })
                        } 
                        />
                        {
                        errors?.accountName?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.accountName?.message}
                        </div>
                        }
                    </div> 
                    </div>
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor='IBAN'>IBAN<span className="text-danger">*</span></label>
                        <input 
                        type="text" 
                        autoComplete='off'
                        className="form-control input-lg" 
                        id="IBAN"
                        {...register("IBAN", {
                          required: {
                          value: true,
                          message: '*this field is required'
                          }
                      })
                        } 
                        />
                        {
                        errors?.IBAN?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.IBAN?.message}
                        </div>
                        }
                    </div> 
                    </div>
                    <div className='col-xl-6'>
                      <div className="form-group">
                            <label htmlFor='accountNo'>Swift Code</label>
                            <input 
                            type="text" 
                            autoComplete='off'
                            className="form-control input-lg" 
                            id="accountNo"
                            {...register("accountNo")
                            } 
                            />
                            {
                            errors?.accountNo?.message &&
                            <div className="text-danger small mt-1">
                                {errors?.accountNo?.message}
                            </div>
                            }
                        </div> 
                    </div>
                   
                    <div className="col-xl-6">
                      <div className="form-group">
                        <label htmlFor='sortCode'>BIC</label>
                        <input 
                        type="text" 
                        autoComplete='off'
                        className="form-control input-lg" 
                        id="sortCode"
                        {...register("sortCode")
                        } 
                        />
                        {
                        errors?.sortCode?.message &&
                        <div className="text-danger small mt-1">
                            {errors?.sortCode?.message}
                        </div>
                        }
                    </div> 
                    </div>
                    

                  </div>
            <div className="mt-4 d-flex align-items-center">
              <div>
                {customerLoading ? 
                  <Spinner isForm={true}/> :
                  <button type="submit" className="btn btn-accent me-3">Submit Form</button>
                }
              </div>
              </div> 
            </form>
          </div>  
            :
            <div>
             {/* {bank_details.length <= 2? && */}
             {/* XD_J */}
               <button onClick={()=>{set_add_bank_detail(true)}} className='btn btn-accent mb-2 mx-4 mx-md-7'>
                 {bank_details.length > 0 ? 'Edit Bank Details' : 'Add Bank Details'} </button>
                 
             {/* } */}
             {/* XD_J */}
             {bank_details.length > 0 ?
              <>
                {bank_details.map((bank_detail)=>{
                  return ( 
                    <div className='mb-3'>
                      <ul key={bank_detail?.id} className="list-group rounded-0">
                        <li className="list-group-item border-start-0">
                            <span className='fw-bold'>Bank Name: </span> 
                            <span>{bank_detail?.bankName}</span>
                        </li>
                        <li style={{columnGap: '0.8rem'}} className="d-flex flex-wrap align-items-center list-group-item border-start-0">
                            <span>
                              <span className='fw-bold'>Account Name: </span> 
                              <span>{bank_detail?.accountName ?? 'N/A' }</span>
                            </span>
                        </li>
                        {(bank_detail?.IBAN && bank_detail?.IBAN != "") &&
                        <li className="list-group-item border-start-0">
                            <span className='fw-bold'>IBAN: </span> 
                            <span>{bank_detail?.IBAN ?? 'N/A' }</span>
                        </li>}
                        <li className="list-group-item border-start-0">
                            <span className='fw-bold'>Swift Code: </span> 
                            <span>{bank_detail?.accountNo}</span>
                        </li>
                       
                        {(bank_detail?.sortCode && bank_detail?.sortCode != "") &&
                        <li className="list-group-item border-start-0">
                            <span className='fw-bold'>BIC: </span> 
                            <span>{bank_detail?.sortCode ?? 'N/A' }</span>
                        </li>}
                     
                    </ul>
                    <div className='text-end w-100 px-4 px-md-7 mt-2'>
                      {/* XD_J */}
                      {/* <button onClick={()=>{
                        dispatch(setDetails({type: 'profile', details: bank_detail}))
                        dispatch(showProfileModalFunc({modal:true, type: 'delete'}))
                        }} className='btn btn-danger'>Delete</button> */}
                        {/* XD_J */}
                    </div>
                  </div> 
                  )
                })}
              </>
          :
          <p className='mx-4 mx-md-7'>You have not added any bank details yet</p>
          }
          </div>
        }
        </div>
      }
    </>
  )
}
