import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { post_put_request_promoCode } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { clearAll, onAddPromocode, setAdminErrorMessageUser, setAdminLoadingUser, onUpdate } from '../../../../config/redux/slice/adminSlice'
import { get_delete_request } from '../../../../helpers/request'
import { format } from 'date-fns';


const AddPromoCodeType = ({ show, allPromoCodeTypes, handleClose, promoCodeType }) => {
    const [newPromoCodeType, setNewPromoCodeType] = useState('');
    const [promoCodeTypes, setPromoCodeTypes] = useState(promoCodeType || []);
    const [editingId, setEditingId] = useState(null);
    const [editingValue, setEditingValue] = useState('');
    const dispatch = useDispatch()
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const {
        token
    } = useSelector((state) => state.authData)
    useEffect(() => {
        setPromoCodeTypes(promoCodeType || []);
    }, [promoCodeType]);
    const onEdit = (data) => {
        console.log('dat', data);
        // setItem(data, 'edit')
    };
    const {
        adminLoading,
    } = useSelector((state) => state.admin)

    const handleInputChange = (e) => {
        setNewPromoCodeType(e.target.value);
    };
    const handleEditInputChange = (e) => {
        setEditingValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent default form submission if in a form
            addPromoCodeType(newPromoCodeType);
        }
    };

    const handleEditKeyDown = async (e, id) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            await updatePromoCodeType(id, editingValue); // Call the update function with the ID and new value
            setEditingId(null); // Exit edit mode
        }
    };

    
  const formatDate = (dateString) => {
    return format(new Date(dateString), 'eee, dd MMM yyyy'); 

}

    const addPromoCodeType = async (name) => {
        if (isProcessing) return; 
        setIsProcessing(true); 
        try {
            const res = await post_put_request_promoCode(
                "post",
                token,
                `${app_api.add_promocode_type}`,
                { name },
                dispatch,
                '',
                onAddPromocode,
                '',
                true
            );
            if (res && res.status < 400) {
                setNewPromoCodeType('');
                getAllPromoCodeTypes();
                allPromoCodeTypes();

                setSuccessMessage(res.data.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } else {
                setErrorMessage(res.data.error); 
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);

                         }
        } catch (error) {
            console.error('Error:', error);
        }
        finally {
            setIsProcessing(false);
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const updatePromoCodeType = async (id, name) => {
        if (isProcessing) return; 
        setIsProcessing(true);
        try {
            const res = await post_put_request_promoCode(
                "patch", 
                token,
                `${app_api.update_promocode_type}/${id}`, 
                { name, isActive: true },
                dispatch,
                '',
                onAddPromocode,
                '',
                true
            );
            if (res && res.status < 400) {
                getAllPromoCodeTypes(); 
                allPromoCodeTypes();

                setSuccessMessage(res.data.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } else {
               setErrorMessage(res.data.error);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
        finally {
            setIsProcessing(false); // Reset processing state after request
            setTimeout(() => {
                setSuccessMessage('');
            }, 3000);
        }
    };

    const getAllPromoCodeTypes = async () => {
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_promocode_type}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res != undefined && res.status < 400) {
            console.log(res);
            console.log(res.data);
            setPromoCodeTypes(res?.data?.data);
        }
    }

    const setItem = (data, type) => {
        console.log('data', data);
        if (type === 'toggle_status') {
            togglePromoCodeStatus(data.id, data.isActive, data.name);
        }
    }

    const togglePromoCodeStatus = async (id, currentStatus, name) => {
        try {
            const newStatus = !currentStatus;
            console.log('newStatus1', newStatus);
            const res = await post_put_request_promoCode(
                "patch",
                token,
                `${app_api.update_promocode_type}/${id}`,
                { name: name, isActive: newStatus }, // Sending only the isActive field for toggling status
                dispatch,
                '',
                onAddPromocode,
                '',
                true
            );
            if (res && res.status < 400) {
                getAllPromoCodeTypes(); 
                setSuccessMessage(res.data.message);
                setTimeout(() => {
                    setSuccessMessage('');
                }, 3000);
            } else {
                setErrorMessage(res.data.error);
                setTimeout(() => {
                    setErrorMessage('');
                }, 3000);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseModal = () => {
        setNewPromoCodeType('');
        setEditingId(null);
        setEditingValue('');
        setSuccessMessage('');
        setErrorMessage('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleCloseModal} className='modal-popup-commission'>
            <Modal.Header closeButton>
                <Modal.Title>Add PromoCode Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card widget-messages mt-4">
                    <div className="card-body-scroll">
                        <div className="list-group list-group-flush">
                            <div className="row mb-2 align-items-center m-2">
                                <div className="col-auto">
                                    <label>Add PromoCode Type: </label>
                                </div>
                                <div className="col">
                                    <input
                                        type="text"
                                        value={newPromoCodeType}
                                        onChange={handleInputChange}
                                        onKeyDown={handleKeyDown}
                                        disabled={isProcessing}
                                        placeholder="Enter promo code type"
                                        className="form-control"
                                    />
                                    {successMessage && ( 
                                    <div className="text-success mt-2">
                                        {successMessage}</div> )}
                                        {errorMessage && ( 
                                    <div className="text-danger mt-2">
                                        {errorMessage}
                                    </div>
                                )}
                                </div>
                            </div>
                            <table class="table table-bordered" style={{width:"98%",margin:"0 auto"}}>
                                <thead>
                                    <tr>
                                        <th scope="col" className='no-wrap'>PromoCodeType</th>
                                        <th scope="col" className='no-wrap'>Created On</th>
                                        <th scope="col" className='no-wrap'>Status</th>
                                        <th scope="col" className='no-wrap'>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {promoCodeTypes && promoCodeTypes && promoCodeTypes?.length > 0 ? (
                                        promoCodeTypes.map((item) => (
                                            <tr>
                                                <td>
                                                    {editingId === item.id ? (

                                                        <input
                                                            type="text"
                                                            value={editingValue}
                                                            onChange={handleEditInputChange}
                                                            onKeyDown={(e) => handleEditKeyDown(e, item.id)}
                                                            className="form-control"
                                                            disabled={isProcessing}
                                                        />
                                                    ) : (
                                                        <div className="text-ellipse-single" title={item.name}>
                                                            {item.name}
                                                        </div>
                                                    )}
                                                </td>
                                                <td>{formatDate(item?.createdAt)}</td>
                                                <td>{item.isActive ? 'Active' : 'Deactive'}</td>
                                                <td>
                                                    <div className="text-ellipse-single" style={{width:"auto"}}>
                                                        <a
                                                            title="Edit PromoCode Type"
                                                            id={item?.id}
                                                            onClick={() => {
                                                                setEditingId(item.id);
                                                                setEditingValue(item.name);
                                                            }}
                                                            class="btn btn-sm bg-grey btn-icon text-white edit-event btn-m-right">
                                                            <i class="fa fa-pencil"></i>
                                                        </a>
                                                        <a
                                                            onClick={() => setItem(item, 'toggle_status')}
                                                            title={item?.isActive ? 'Deactivate' : 'Activate'}
                                                            className={item?.isActive ? "btn btn-sm bg-primary text-white btn-icon toggle-listing btn-m-right" : "btn btn-sm bg-success text-white btn-icon toggle-listing btn-m-right"}
                                                        >
                                                            <i className={item?.isActive ? 'fa fa-pause' : 'fa fa-play'}></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3">No promocode type available</td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddPromoCodeType;
