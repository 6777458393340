import React from 'react'
import { navigationStructure } from '../../../utils/nav_structure'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { dispatch } from 'd3'
import { setBreadCrumbName, setCurrentNavDetails } from '../../../config/redux/slice/appSlice'

export default function SideBar() {

  const dispatch = useDispatch()

  const { isAdminActive } = useSelector((state) => state.app)

  const {
    userData: { user_role },
  } = useSelector((state) => state.authData)

  // Filter navigation items based on user role
  const filteredNavItems = user_role === 'FINANCE'
    ? navigationStructure.admin_navigation.filter(item => [1,13,17].includes(item.id))
    : user_role === 'SUPPORT'
      ? navigationStructure.admin_navigation.filter(item => [1,4,5,8,9,10,11].includes(item.id))
      : user_role === 'CUSTOMER_SUPPORT'
        ? navigationStructure.admin_navigation.filter(item => [1,6,7,12,14,17].includes(item.id))
        : navigationStructure.admin_navigation;


  return (
    <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-primary admin-sidebar sidebar collapse">
      <div className="position-sticky pt-3">
        <div className="d-none d-md-block pt-2 px-md-3">
          <span className="ms-3 text-uppercase fs-12">Navigation</span>
          <hr className="mt-2" />
        </div>
        <ul className="nav flex-column">
          {(filteredNavItems).map((option) => {
            return <li key={option.id} className={`nav-item ${isAdminActive == option.url ? 'active' : ''}`}>
              <Link
                onClick={() => {
                  dispatch(setBreadCrumbName([{ id: 1, name: option.name, url: option.url }]))
                  dispatch(setCurrentNavDetails(option))
                }}
                to={option.url}
                className={`nav-link  ${isAdminActive == option.url ? 'active' : ''}`}
                aria-current="page">
                <span className={`${option.icon} me-2 fs-5`}></span>
                {option.name}
              </Link>
            </li>
          })}
        </ul>
      </div>
    </nav>
  )
}
