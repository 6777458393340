import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, showTicketModalFunc, setDetails, showListingModalFunc, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import { Link, useNavigate } from 'react-router-dom'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import moment from 'moment'
import Pagination from "react-js-pagination";
import EditTicket from '../ticket_management/EditTicket';
import ViewTicket from '../ticket_management/ViewTicket';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'

// import { format } from 'date-fns';
export default function ListingNewTableDesign(props) {
    const dispatch = useDispatch()
    const { token } = useSelector((state) => state.authData)
    const [listingDetails, setListingDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorListingId, setErrorListingId] = useState(null);
    const [localValues, setLocalValues] = useState({});
    const [localPrice, setLocalPrice] = useState({});
    const [localReservePrice, setLocalReservePrice] = useState({});
    const [loadingStates, setLoadingStates] = useState({});
    const [selectedSectionIds, setSelectedSectionIds] = useState({});
    const [selectedSubSectionIds, setSelectedSubSectionIds] = useState({});

    const [initialValues, setInitialValues] = useState({});

    const [startValue, setStartValue] = useState({});



    const setLoadingByListingId = (listingId, isLoading) => {
        setLoadingStates(prevLoadingStates => ({
            ...prevLoadingStates,
            [listingId]: isLoading
        }));
    };

    useEffect(() => {
        localStorage.setItem("activePage", activePage);
        setListingDetails(props.listingDetails)
        setRecordsTotal(props.listingCount)
    }, [props])

    useEffect(() => {
        if (props.listingDetails)
            setLoading(false)
    }, [])

    const { ticketManagement: { showTicketModal: { show, type } } } = useSelector((state) => state.customer)

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };

    const setItem = (data, type) => {
        if (type == 'delete' || type == 'edit' || type == 'duplicate') {
            dispatch(setDetails({ details: data, type: 'listing-items' }))
            dispatch(showListingModalFunc({ modal: true, type }))

        }
    }
    const setItemTicket = (data, type) => {
        if (type == 'view' || type == 'edit') {
            dispatch(setDetails({ details: data, type: 'ticket' }))
            setTicketData(data)
            dispatch(showTicketModalFunc({ modal: true, type }))
        }

    }
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        localStorage.setItem("searchTerm", searchTerm);
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_auth_search_listing}?search=&page=1&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        } else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }
    const handlePageChange = async (pageValue) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        setActivePage(pageValue)
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_auth_search_listing}?search=&page=${pageValue}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        }
        else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }

    const handleAvailableTicketsChange = (event, id) => {
        const { value } = event.target;
        setLocalValues(prev => ({ ...prev, [id]: value }));
    };

    const handlePriceChange = (event, id) => {
        const { value } = event.target;
        setLocalPrice(prev => ({ ...prev, [id]: value }));
    };

    const handleReservedPriceChange = (event, id) => {
        const { value } = event.target;
        setLocalReservePrice(prev => ({ ...prev, [id]: value }));
    };

    useEffect(() => {
        setInitialValues({
            pricePerTicket: props?.pricePerTicketWithoutCommission,
            reservedPricePerTicket: props?.reservedPricePerTicket,
            totalAvailableTickets: props?.totalAvailableTickets,
        });
    }, [props]);

    const handleFocus = (listingId, value) => {
        // Store the initial value when the input gains focus
        setStartValue(prev => ({ ...prev, [listingId]: value }));
    };


    const handleSave = async (type, listingId, event) => {

        event.preventDefault();

        let priceValue, reservePriceValue, availableTicketValue;

        const currentValue = event.target.value;

        // Check if the event is from a desktop view (tr) or mobile view (mob-table-section)
        if (event.target.closest('tr')) {
            // Desktop view: Use the closest <tr> and query the input elements
            const rowElement = event.target.closest('tr');
            priceValue = rowElement.querySelector('.input-price').value;
            reservePriceValue = rowElement.querySelector('.input-reservedPricePerTicket').value;
            availableTicketValue = rowElement.querySelector('.input-availableTicketz').value;
        } else if (event.target.closest('.mob-table-section')) {
            // Mobile view: Use the closest .mob-table-section and query the input elements
            const mobileSection = event.target.closest('.mob-table-section');
            priceValue = mobileSection.querySelector('.input-price').value;
            reservePriceValue = mobileSection.querySelector('.input-reservedPricePerTicket').value;
            availableTicketValue = mobileSection.querySelector('.input-availableTicketz').value;
        } else {
            console.error("Cannot find the closest table row or mobile section.");
            return;
        }


        // Get current input values
        // const priceValue = event.target.closest('tr').querySelector('.input-price').value;
        // const reservePriceValue = event.target.closest('tr').querySelector('.input-reservedPricePerTicket').value;
        // const availableTicketValue = event.target.closest('tr').querySelector('.input-availableTicketz').value;

        // Convert empty strings to zero
        const priceNumber = priceValue === '' ? 0 : parseFloat(priceValue);
        const reservePriceNumber = reservePriceValue === '' ? 0 : parseFloat(reservePriceValue);
        const availableTicketsNumber = availableTicketValue === '' ? 0 : parseFloat(availableTicketValue);

        // Retrieve initial values from the listing details
        const initialListing = listingDetails.find(item => item.listings.find(listing => listing.id === listingId)).listings.find(listing => listing.id === listingId);
        const initialValues = {
            pricePerTicket: initialListing?.pricePerTicketWithoutCommission,
            reservedPricePerTicket: initialListing?.reservedPricePerTicket,
            totalAvailableTickets: initialListing?.totalAvailableTickets,
        };

        // Check if the values have changed
        if (
            priceNumber === initialValues.pricePerTicket &&
            reservePriceNumber === initialValues.reservedPricePerTicket &&
            availableTicketsNumber === initialValues.totalAvailableTickets
        ) {
            return;
        }

        if (startValue[listingId] === currentValue) {
            return
        }

        // Validation: Ensure values are not zero
        if (priceNumber <= 0 || reservePriceNumber <= 0 || availableTicketsNumber <= 0) {
            setErrorMessage("Price, reserved price, and available tickets must be greater than zero.");
            setErrorListingId(listingId); // Track the listing with the error
            return; // Stop further execution if validation fails
        }

        // Validation: Reserved price per ticket should not be greater than ticket price per ticket
        if (reservePriceNumber > priceNumber) {
            setErrorMessage("Reserved price cannot be greater than the price per ticket.");
            setErrorListingId(listingId); // Track the listing with the error
            return; // Stop further execution if the validation fails
        }

        setLoadingByListingId(listingId, true);

        // Prepare data for the update
        let updateData = {
            pricePerTicket: priceNumber,
            reservedPricePerTicket: reservePriceNumber,
            numberOfTickets: availableTicketsNumber,
        };

        setErrorMessage('');
        setErrorListingId(null);

        // Make the API call to update the listing
        try {
            const res = await post_put_patch_request(
                "patch",
                token,
                `${app_api.update_price_listing(listingId)}`,
                updateData,
                dispatch,
                setCustomerLoading,
                "",
                setCustomerErrorMessage
            );
            if (res !== undefined && res.status < 400) {
                props.getList()
                dispatch(onUpdate({ message: res?.data?.message, type: 'listing' }))
                setLoading(false); // End loading
            }

        } catch (error) {
            console.error('Error updating listing:', error);
        } finally {
            setLoadingByListingId(listingId, false); // End loading for this specific listingId
        }
    };

    const handleKeyDown = (type, listingId, event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission or other behaviors
            event.stopPropagation(); // Stop the event from propagating further
            handleSave(type, listingId, event);
        }
    };

    useEffect(() => {
        if (listingDetails && listingDetails.length > 0) {
            const sectionIds = {};
            const subSectionIds = {};

            listingDetails.forEach(item => {
                item.listings.forEach(listing => {
                    sectionIds[listing.id] = listing.section?.id || '';
                    subSectionIds[listing.id] = listing.subSection?.id || '';
                });
            });

            setSelectedSectionIds(sectionIds);
            setSelectedSubSectionIds(subSectionIds);
        }
    }, [listingDetails]);

    const handleSectionChange = (listingId, sectionId) => {
        // Reset the sub-section when section changes
        setSelectedSectionIds(prev => ({
            ...prev,
            [listingId]: sectionId,
        }));

        // Reset the corresponding sub-section ID to empty or a default value
        setSelectedSubSectionIds(prev => ({
            ...prev,
            [listingId]: '', // Reset to default when section changes
        }));
    };

    const updateBlock = async (listingId, sectionId, subSectionId) => {

        console.log("listingId:", listingId)
        console.log("sectionId:", sectionId)
        console.log("subSectionId:", subSectionId)

        setLoadingByListingId(listingId, true);

        let data = {
            sectionId: sectionId,
            subSectionId: subSectionId,
        }
        try {
            const res = await post_put_patch_request(
                "patch",
                token,
                `${app_api.update_section_listing(listingId)}`,
                data,
                dispatch,
                setCustomerLoading,
                "",
                setCustomerErrorMessage
            );
            if (res !== undefined && res.status < 400) {
                props.getList()
                dispatch(onUpdate({ message: res?.data?.message, type: 'listing' }))
                setLoading(false); // End loading
            }

        } catch (error) {
            console.error('Error updating listing:', error);
        } finally {
            setLoadingByListingId(listingId, false); // End loading for this specific listingId
        }

    };

    return (
        <div >
            <div className="payable-tblouter order-tbl">
                <div className="form-group search-input-top">
                    <input autoComplete='off' type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
                    <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>
                    {/* <button  className="btn btn-primary me-3 ml-2" style ={{marginLeft: "10px"}} onClick={()=>handleClearChange()}>Clear</button> */}
                </div>
                {(show && type == 'edit') &&
                    <EditTicket ticketObj={ticketData} getList={props.getList} />
                }
                {(show && type == 'view') &&
                    <ViewTicket ticketObj={ticketData} />
                }
                <div className="table-responsive web-show-tbl">
                    <table className="table table-striped mt-3 tbl-pending">
                        <thead>
                            <tr>
                                <th scope="col" className='no-wrap'>No of Listing</th>
                                <th scope="col" className='no-wrap'>Event</th>
                                <th scope="col" className='no-wrap'>Event Date</th>
                                <th scope="col" className='no-wrap'>Remaining Tickets</th>
                                <th scope="col" className='no-wrap'>Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                                </tr>
                            )
                                : listingDetails && listingDetails.length > 0 ? (
                                    listingDetails?.map((item, index) => (
                                        <>
                                            <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                                <td>{item?.listings?.length || 0}</td>
                                                <td>{item?.eventName}</td>
                                                <td>{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                <td>{item?.totalAvailableCount}</td>
                                                <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                    {expandedRows[index] ? (
                                                        <i className="fa fa-angle-up fa-sizeset"></i>
                                                    ) : (
                                                        <i className="fa fa-angle-down fa-sizeset"></i>
                                                    )} </a>
                                                </td>
                                            </tr>
                                            {/* Error Message */}

                                            {(expandedRows[index] && item?.listings?.length > 0) && (
                                                <tr>
                                                    <td colSpan="8">
                                                        <table className="table table-striped tbl-inner-width">
                                                            <thead>
                                                                <tr>
                                                                    <th className='no-wrap'>Listing Id</th>
                                                                    <th className='no-wrap'>Section</th>
                                                                    <th className='no-wrap'>Block</th>
                                                                    <th className='no-wrap'>Available Tickets</th>
                                                                    <th className='no-wrap'>Price/Ticket</th>
                                                                    <th className='no-wrap'>Reserved Price/Ticket</th>
                                                                    <th className='no-wrap'>Ticket Type </th>
                                                                    <th className='no-wrap'>Actions</th>
                                                                </tr>
                                                            </thead>
                                                            {item?.listings?.map((items, orderIndex) => (
                                                                <tbody>
                                                                    <tr key={orderIndex} style={{ opacity: loadingStates[items?.id] ? 0.5 : 1 }}>
                                                                        <td className={nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ""}>{items?.listingRef}</td>
                                                                        <td>
                                                                            <select
                                                                                className="form-select form-select-listng"
                                                                                id="sectionId"
                                                                                value={selectedSectionIds[items.id] || ''} // Default value for section
                                                                                onChange={(e) => handleSectionChange(items.id, e.target.value)}
                                                                                title={item.sections.find(section => section.sectionId === selectedSectionIds[items.id])?.sectionName || "Select Section"} // Tooltip on hover

                                                                            >
                                                                                <option value="">Select Section</option>
                                                                                {item.sections.map(section => (
                                                                                    <option
                                                                                        key={section.sectionId}
                                                                                        value={section.sectionId}
                                                                                        title={section.sectionName}>
                                                                                        {section.sectionName}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                            {/* <span title={items?.section?.name} class="text-ellipsis">{items?.section?.name}</span> */}
                                                                            {errorListingId === items?.id && errorMessage && (
                                                                                <div style={{ color: 'red', marginTop: '8px', fontSize: '11px', background: "#fff", padding: "3px" }}>
                                                                                    {errorMessage}
                                                                                </div>
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                className="form-select form-select-listng form-select-listng-block"
                                                                                id="subSectionId"
                                                                                value={selectedSubSectionIds[items.id] || ''} // Default value for sub-section
                                                                                onChange={(e) => {
                                                                                    const newSubSectionId = e.target.value;
                                                                                    const sectionId = selectedSectionIds[items.id]; // Get the selected section ID
                                                                                    setSelectedSubSectionIds(prev => ({ ...prev, [items.id]: newSubSectionId }));
                                                                                    updateBlock(items.id, sectionId, newSubSectionId); // Pass both IDs
                                                                                }}
                                                                            >
                                                                                <option value="">N/A</option>
                                                                                {item.sections
                                                                                    .filter(section => section.sectionId === selectedSectionIds[items.id])
                                                                                    .flatMap(section => section.subSections.map(subSection => (
                                                                                        <option key={subSection.subSectionId} value={subSection.subSectionId}>
                                                                                            {subSection.subSectionName}
                                                                                        </option>
                                                                                    )))}
                                                                            </select>
                                                                            {/* <span title={items?.subSection?.name} class="text-ellipsis">{items?.subSection?.name}</span> */}
                                                                        </td>
                                                                        <td>
                                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                                <input type="number" class="form-control input-availableTicketz" min="1"
                                                                                    value={localValues[items?.id] ?? items?.totalAvailableTickets}
                                                                                    onFocus={(e) => handleFocus(items?.id, e.target.value)}
                                                                                    onChange={(e) => handleAvailableTicketsChange(e, items?.id)}
                                                                                    onBlur={(e) => handleSave('availableTicket', items?.id, e)}
                                                                                    onKeyDown={(e) => handleKeyDown('availableTicket', items?.id, e)}
                                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                                    data-available-tickets={localValues[items?.id] ?? items?.totalAvailableTickets}

                                                                                />

                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                                <span class="">{strText.currency_symbol} </span>
                                                                                <input class="form-control input-price" type="text"
                                                                                    value={localPrice[items?.id] ?? items?.pricePerTicketWithoutCommission}
                                                                                    onFocus={(e) => handleFocus(items?.id, e.target.value)}
                                                                                    onChange={(e) => handlePriceChange(e, items?.id)}
                                                                                    onBlur={(e) => handleSave('price', items?.id, e)}
                                                                                    onKeyDown={(e) => handleKeyDown('price', items?.id, e)}
                                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                                    data-price={localPrice[items?.id] ?? items?.pricePerTicketWithoutCommission}
                                                                                />
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                                <span class="">{strText.currency_symbol} </span>
                                                                                <input class="form-control input-reservedPricePerTicket" type="text"
                                                                                    value={localReservePrice[items?.id] ?? items?.reservePricePerTicketWithoutCommission}
                                                                                    onFocus={(e) => handleFocus(items?.id, e.target.value)}
                                                                                    onChange={(e) => handleReservedPriceChange(e, items?.id)}
                                                                                    onBlur={(e) => handleSave('reservePrice', items?.id, e)}
                                                                                    onKeyDown={(e) => handleKeyDown('reservePrice', items?.id, e)}
                                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                                    data-reserve-price={localReservePrice[items?.id] ?? items?.reservedPricePerTicket}
                                                                                />
                                                                                {loadingStates[items?.id] && (
                                                                                    <i className="fa fa-refresh fa-spin"
                                                                                        style={{ fontSize: '18px', opacity: 1, marginLeft: '10px' }}
                                                                                    ></i>
                                                                                )}
                                                                            </div>
                                                                        </td>

                                                                        <td>{items?.ticketsType}</td>
                                                                        <td>
                                                                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>

                                                                                <a title="Clone Listing"
                                                                                    onClick={() => setItem(items, 'duplicate')}
                                                                                    data-id={items?.id}
                                                                                    class="btn btn-sm bg-info btn-icon text-white duplicate-listings btn-m-right">
                                                                                    <i class="fa fa-copy"></i>
                                                                                </a>
                                                                                {/* <a title="View Listing Details"
                                                                        data-id={items?.id}
                                                                        class="btn btn-sm bg-info btn-icon text-white view-listing">
                                                                        <i class="fa fa-eye"></i>
                                                                    </a> */}
                                                                                <a
                                                                                    title="View Tickets"
                                                                                    onClick={(event) => toggleNestedRow(index, orderIndex, event)}
                                                                                    data-id={items?.id}
                                                                                    class="btn btn-sm bg-success btn-icon text-white view-tickets btn-m-right">
                                                                                    <i class="fa fa-ticket"></i>
                                                                                </a>
                                                                                <a
                                                                                    onClick={() => setItem(items, 'edit')}
                                                                                    title="Edit Listing"
                                                                                    data-id={items?.id}
                                                                                    // style={{ backgroundColor: '#646060' }}
                                                                                    class="btn btn-sm bg-grey btn-icon text-white edit-listing btn-m-right">
                                                                                    <i class="fa fa-pencil"></i>
                                                                                </a>
                                                                                <a title="Delete Listing"
                                                                                    onClick={() => setItem(items, 'delete')}
                                                                                    data-id={items?.id}
                                                                                    class="btn btn-sm bg-danger btn-icon text-white delete-listing">
                                                                                    <i class="fa fa-trash"></i>
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>

                                                                    {
                                                                        nestedExpandedRows[index]?.[orderIndex] && (
                                                                            <tr>
                                                                                <td colSpan="7">
                                                                                    <table className="table table-bordered tbl-subinner-width">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th className='no-wrap'>Row</th>
                                                                                                {/* <th className='no-wrap'>Seat Number</th> */}
                                                                                                <th className='no-wrap'>Status</th>
                                                                                                <th className='no-wrap'>Sold on</th>
                                                                                                <th className='no-wrap'>Actions</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {items.Tickets.filter(item => item.soldAt == null).length === 0 ? (
                                                                                                <tr>
                                                                                                    <td colSpan="4" className="text-center">No Tickets available</td>
                                                                                                </tr>
                                                                                            ) : (
                                                                                                items.Tickets.filter(item => item.soldAt == null).map((itemx, itemIndex) => (
                                                                                                    <tr key={itemIndex}>
                                                                                                        <td>{itemx?.row}</td>
                                                                                                        <td>
                                                                                                            <span className="badge badge-pill text-white text-capitalize bg-danger">
                                                                                                                Not Sold
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            N/A
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {items?.ticketsType?.toLowerCase() === 'electronic' ? (
                                                                                                                <span>
                                                                                                                    <a
                                                                                                                        onClick={() => setItemTicket(itemx, 'view')}
                                                                                                                        title="View Electronic Ticket"
                                                                                                                        data-id={itemx?.id}
                                                                                                                        className="btn btn-sm bg-info btn-icon text-white view-electronic-ticket btn-m-right">
                                                                                                                        <i className="mdi mdi-file-image"></i>
                                                                                                                    </a>
                                                                                                                    <a
                                                                                                                        onClick={() => setItemTicket(itemx, 'edit')}
                                                                                                                        title={
                                                                                                                            items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                                                ? 'Edit Ticket'
                                                                                                                                : 'Upload Ticket'
                                                                                                                        }
                                                                                                                        data-id={itemx?.id}
                                                                                                                        className={
                                                                                                                            items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                                                ? 'bg-warning btn btn-sm btn-icon text-white edit-ticket'
                                                                                                                                : 'bg-danger btn btn-sm btn-icon text-white edit-ticket'
                                                                                                                        }>
                                                                                                                        <i className={
                                                                                                                            items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                                                ? 'fa-pencil fa'
                                                                                                                                : 'fa-upload fa'
                                                                                                                        }></i> {items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file ? 'Change' : 'Upload'}
                                                                                                                    </a>
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                'N/A'
                                                                                                            )}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))
                                                                                            )}
                                                                                        </tbody>

                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }

                                                                </tbody>
                                                            )
                                                            )}
                                                        </table>
                                                    </td>
                                                </tr >
                                            )}

                                        </>
                                    ))

                                ) : (
                                    <tr>
                                        <td colSpan="8" className="text-center py-4">No Data Found</td>
                                    </tr>
                                )}
                        </tbody>
                    </table>
                </div>

                <div className="mob-table-section">
                    {loading ? (
                        <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                    ) : listingDetails ? (
                        listingDetails?.map((item, index) => (
                            <>

                                <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                    <div className="position-relative">
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">No of Listing</h3>
                                            <div className="mob-tbl-value">{item?.listings?.length || 0}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event</h3>
                                            <div className="mob-tbl-value" title={item?.eventName}>{item?.eventName}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event Date</h3>
                                            <div className="mob-tbl-value">{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Remaining Tickets</h3>
                                            <div className="mob-tbl-value">{item?.totalAvailableCount}</div>
                                        </div>

                                        <div className="mob-table-sec-expand">
                                            <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )} </a>
                                        </div>

                                    </div>


                                    {(expandedRows[index] && item?.listings?.length > 0) && (
                                        <>

                                            {item?.listings?.map((items, orderIndex) => (
                                                <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Listing Id</h3>
                                                        <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{items?.listingRef}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Section</h3>
                                                        <div className="mob-tbl-value"> <select
                                                            className="form-select form-select-listng"
                                                            id="sectionId"
                                                            value={selectedSectionIds[items.id] || ''} // Default value for section
                                                            onChange={(e) => handleSectionChange(items.id, e.target.value)}
                                                            title={item.sections.find(section => section.sectionId === selectedSectionIds[items.id])?.sectionName || "Select Section"} // Tooltip on hover

                                                        >
                                                            <option value="">Select Section</option>
                                                            {item.sections.map(section => (
                                                                <option
                                                                    key={section.sectionId}
                                                                    value={section.sectionId}
                                                                    title={section.sectionName}>
                                                                    {section.sectionName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                            {/* <span title={items?.section?.name} class="text-ellipsis">{items?.section?.name}</span> */}
                                                            {errorListingId === items?.id && errorMessage && (
                                                                <div style={{ color: 'red', marginTop: '8px', fontSize: '11px', background: "#fff", padding: "3px" }}>
                                                                    {errorMessage}
                                                                </div>
                                                            )}<br></br>{items?.subSection?.name}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Block</h3>
                                                        <div className="mob-tbl-value">
                                                            <select
                                                                className="form-select form-select-listng form-select-listng-block"
                                                                id="subSectionId"
                                                                value={selectedSubSectionIds[items.id] || ''} // Default value for sub-section
                                                                onChange={(e) => {
                                                                    const newSubSectionId = e.target.value;
                                                                    const sectionId = selectedSectionIds[items.id]; // Get the selected section ID
                                                                    setSelectedSubSectionIds(prev => ({ ...prev, [items.id]: newSubSectionId }));
                                                                    updateBlock(items.id, sectionId, newSubSectionId); // Pass both IDs
                                                                }}
                                                            >
                                                                <option value="">N/A</option>
                                                                {item.sections
                                                                    .filter(section => section.sectionId === selectedSectionIds[items.id])
                                                                    .flatMap(section => section.subSections.map(subSection => (
                                                                        <option key={subSection.subSectionId} value={subSection.subSectionId}>
                                                                            {subSection.subSectionName}
                                                                        </option>
                                                                    )))}
                                                            </select>
                                                            {/* <span title={items?.section?.name} class="text-ellipsis">{items?.section?.name}</span> */}
                                                            {errorListingId === items?.id && errorMessage && (
                                                                <div style={{ color: 'red', marginTop: '8px', fontSize: '11px', background: "#fff", padding: "3px" }}>
                                                                    {errorMessage}
                                                                </div>
                                                            )}<br></br>{items?.subSection?.name}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Available Tickets</h3>
                                                        <div className="mob-tbl-value ">
                                                            {/* {items?.totalAvailableTickets} */}
                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                <input type="number" class="form-control input-availableTicketz" min="1"
                                                                    value={localValues[items?.id] ?? items?.totalAvailableTickets}
                                                                    onChange={(e) => handleAvailableTicketsChange(e, items?.id)}
                                                                    onBlur={(e) => handleSave('availableTicket', items?.id, e)}
                                                                    onKeyDown={(e) => handleKeyDown('availableTicket', items?.id, e)}
                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                    data-available-tickets={localValues[items?.id] ?? items?.totalAvailableTickets}

                                                                />

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Price/Ticket</h3>
                                                        <div className="mob-tbl-value ">
                                                            {/* {items?.pricePerTicketWithoutCommission ? `${strText.currency_symbol}${formatNumber(items?.pricePerTicketWithoutCommission)}` : 'N/A'} */}
                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                <span class="">{strText.currency_symbol} </span>
                                                                <input class="form-control input-price" type="text"
                                                                    value={localPrice[items?.id] ?? items?.pricePerTicketWithoutCommission}
                                                                    onChange={(e) => handlePriceChange(e, items?.id)}
                                                                    onBlur={(e) => handleSave('price', items?.id, e)}
                                                                    onKeyDown={(e) => handleKeyDown('price', items?.id, e)}
                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                    data-price={localPrice[items?.id] ?? items?.pricePerTicketWithoutCommission}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Reserved Price/Ticket</h3>
                                                        <div className="mob-tbl-value ">
                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                <span class="">{strText.currency_symbol} </span>
                                                                <input class="form-control input-reservedPricePerTicket" type="text"
                                                                    value={localReservePrice[items?.id] ?? items?.reservePricePerTicketWithoutCommission}
                                                                    onChange={(e) => handleReservedPriceChange(e, items?.id)}
                                                                    onBlur={(e) => handleSave('reservePrice', items?.id, e)}
                                                                    onKeyDown={(e) => handleKeyDown('reservePrice', items?.id, e)}
                                                                    disabled={loadingStates[items?.id]} // Disable input if loading
                                                                    data-reserve-price={localReservePrice[items?.id] ?? items?.reservePricePerTicketWithoutCommission}
                                                                />
                                                                {loadingStates[items?.id] && (
                                                                    <i className="fa fa-refresh fa-spin"
                                                                        style={{ fontSize: '18px', opacity: 1, marginLeft: '10px' }}
                                                                    ></i>
                                                                )}

                                                            </div>

                                                            {errorListingId === items?.id && errorMessage && (
                                                                <div style={{ color: 'red', marginTop: '8px', fontSize: '11px', background: "#fff", padding: "3px" }}>
                                                                    {errorMessage}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Ticket Type</h3>
                                                        <div className="mob-tbl-value ">{items?.ticketsType}</div>
                                                    </div>
                                                    <div className="mob-table-sec-action">
                                                        <a title="Clone Listing"
                                                            onClick={() => setItem(items, 'duplicate')}
                                                            data-id={items?.id}
                                                            class="btn btn-sm bg-info btn-icon text-white duplicate-listings  btn-m-right btn-i-padd">
                                                            <i class="fa fa-copy"></i>
                                                        </a>
                                                        {/* <a title="View Listing Details"
                                                                        data-id={items?.id}
                                                                        class="btn btn-sm bg-info btn-icon text-white view-listing">
                                                                        <i class="fa fa-eye"></i>
                                                                    </a> */}
                                                        <a
                                                            title="View Tickets"
                                                            onClick={(event) => toggleNestedRow(index, orderIndex, event)}
                                                            data-id={items?.id}
                                                            class="btn btn-sm bg-success btn-icon text-white view-tickets  btn-m-right btn-i-padd">
                                                            <i class="fa fa-ticket"></i>
                                                        </a>
                                                        <a
                                                            onClick={() => setItem(items, 'edit')}
                                                            title="Edit Listing"
                                                            data-id={items?.id}
                                                            // style={{ backgroundColor: '#646060' }}
                                                            class="btn btn-sm bg-grey btn-icon text-white edit-listing  btn-m-right btn-i-padd">
                                                            <i class="fa fa-pencil"></i>
                                                        </a>
                                                        <a title="Delete Listing"
                                                            onClick={() => setItem(items, 'delete')}
                                                            data-id={items?.id}
                                                            class="btn btn-sm bg-danger btn-icon text-white delete-listing  btn-m-right btn-i-padd">
                                                            <i class="fa fa-trash"></i>
                                                        </a>

                                                    </div>

                                                    {nestedExpandedRows[index]?.[orderIndex] && (
                                                        <>
                                                            {
                                                                items.Tickets.every(item => item.soldAt != null) ? (
                                                                    <div className="text-center">No Tickets available</div>) :
                                                                    <>
                                                                        {items.Tickets.filter(ticket => ticket.soldAt == null).map((itemx, itemIndex) => (

                                                                            <div className="mob-tb-subinner" key={itemIndex}>
                                                                                <div className="mob-table-sec">
                                                                                    <h3 className="mob-tbl-head">Row</h3>
                                                                                    <div className="mob-tbl-value">{itemx?.row}</div>
                                                                                </div>
                                                                                <div className="mob-table-sec">
                                                                                    <h3 className="mob-tbl-head">Seat Number</h3>
                                                                                    <div className="mob-tbl-value" title={item?.section?.name}>{itemx?.seat}</div>
                                                                                </div>
                                                                                <div className="mob-table-sec">
                                                                                    <h3 className="mob-tbl-head">Status</h3>
                                                                                    <div className="mob-tbl-value" > {itemx?.soldAt ? (
                                                                                        <span className="badge badge-pill text-white text-capitalize bg-success">
                                                                                            Sold
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="badge badge-pill text-white text-capitalize bg-danger">
                                                                                            Not Sold
                                                                                        </span>
                                                                                    )}</div>
                                                                                </div>
                                                                                <div className="mob-table-sec">
                                                                                    <h3 className="mob-tbl-head">Sold on</h3>
                                                                                    <div className="mob-tbl-value"> {itemx.soldAt ? moment(itemx?.soldAt).format(strText.full_date) : 'N/A'}</div>
                                                                                </div>
                                                                                <div className="mob-table-sec-action">
                                                                                    {items?.ticketsType?.toLowerCase() === 'electronic' ? (
                                                                                        <span>
                                                                                            <a
                                                                                                onClick={() => setItemTicket(itemx, 'view')}
                                                                                                title="View Electronic Ticket"
                                                                                                data-id={itemx?.id}
                                                                                                className="btn btn-sm bg-info btn-icon text-white view-electronic-ticket  btn-m-right btn-i-padd ">
                                                                                                <i className="mdi mdi-file-image"></i>
                                                                                            </a>
                                                                                            <a
                                                                                                onClick={() => setItemTicket(itemx, 'edit')}
                                                                                                title={
                                                                                                    items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                        ? 'Edit Ticket'
                                                                                                        : 'Upload Ticket'
                                                                                                }
                                                                                                data-id={itemx?.id}
                                                                                                className={
                                                                                                    items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                        ? 'bg-warning btn btn-sm btn-icon text-white edit-ticket btn-m-right'
                                                                                                        : 'bg-danger btn btn-sm btn-icon text-white edit-ticket btn-m-right'
                                                                                                }>
                                                                                                <i className={
                                                                                                    items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file
                                                                                                        ? 'fa-pencil fa'
                                                                                                        : 'fa-upload fa'
                                                                                                }></i> {items?.ticketsType?.toLowerCase() === 'electronic' && itemx?.file ? 'Change' : 'Upload'}
                                                                                            </a>
                                                                                        </span>
                                                                                    ) : (
                                                                                        'N/A'
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                            }
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </>)}
                                </div>


                            </>

                        ))

                    ) : (
                        <div className="text-center py-4">No Data Found</div>

                    )}

                </div>





                {recordsTotal && recordsTotal > 10 ?
                    <div className='pagination-wrapper'>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={recordsTotal}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                    : ""}
            </div>
        </div >
    );
}
