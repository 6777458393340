import React, { memo, useEffect, useState } from 'react'
import Spinner from '../../../components/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  setCustomerErrorMessage,
} from '../../../../config/redux/slice/customerSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { strText } from '../../../../constants/english'

function TicketAlertSummaryCards() {
  const dispatch = useDispatch()

  const { token } = useSelector((state) => state.authData)

  const [cardValueList, setCardValueList] = useState([
    {
      id: 3,
      heading: 'Open Alert',
      color: 'bg-success',
      icon: 'fa fa-ticket text-success',
      icon_color: 'text-success',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
    {
      id: 2,
      heading: 'Matching Alert',
      color: 'bg-primary',
      icon: 'fa fa-ticket text-primary',
      icon_color: 'text-primary',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
    {
      id: 1,
      heading: 'Expired Alert',
      color: 'bg-warning',
      icon: 'fa fa-ticket text-warning',
      icon_color: 'text-warning',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
  ])

  useEffect(() => {
    dispatch(clearAll())

    ticketAlertSummary()
  }, [])
  const formatNumber = (num) => {
   return Number(num).toFixed(2);
  };
  const ticketAlertSummary = async () => {
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.alertSummary}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    { console.log("🚀 ~ paymentSummary ~ payoutPayment_summary:", res) }
    if (res !== undefined && res.status < 400) {
      let newArray = [
        {
          id: 1,
          heading: 'Open Alerts',
          color: 'bg-success',
          icon: 'fa fa-ticket text-success',
          icon_color: 'text-success',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.totalOpenTicketAlerts),
          total_count:res?.data?.data?.totalOpenTicketAlerts,
        },
        {
          id: 2,
          heading: 'Matching Alerts',
          color: 'bg-info',
          icon: 'fa fa-ticket text-primary',
          icon_color: 'text-primary',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.totalMatchingTicketAlert),
          total_count: res?.data?.data?.totalMatchingTicketAlert,
        },
        {
          id: 4,
          heading: 'Closed Alerts',
          color: 'bg-warning',
          icon: 'fa fa-ticket text-warning',
          icon_color: 'text-danger',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.totalClosedTicketAlert),
          total_count: res?.data?.data?.totalClosedTicketAlert,
        },
        {
          id: 3,
          heading: 'Expired Alerts',
          color: 'bg-warning',
          icon: 'fa fa-ticket text-warning',
          icon_color: 'text-warning',
          loading: false,
          sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
          total_amount: formatNumber(res?.data?.data?.totalExpiredTicketAlert),
          total_count: res?.data?.data?.totalExpiredTicketAlert,
        },
      ]
      setCardValueList(newArray)
    }
  }
 
  return (
    <section style={{ rowGap: '1rem' }} className="d-flex flex-column mb-4">
      <div style={{ rowGap: '0.7rem' }} className="w-100 m-0 row row-sm py-3">
        {cardValueList.map((option) => {
          { console.log("🚀 ~ {cardValueList.map ~ cardValueList:", cardValueList) }
          return (
            <div key={option.id} className="col-sm-6 col-xl-3">
              <div className={`card border-0 p-4 bg-white`}>
                <div className="d-flex justify-content-between align-items-center">
                  <span style={{ height: '45px' }}>
                    <span
                      style={{ fontSize: '30px' }}
                      className={`${option.icon} ${option.icon_color}`}
                    ></span>
                  </span>
                  <div className="d-flex flex-column align-items-end gap-3">
                    {option.loading == true ? (
                      <Spinner color={`${option.icon_color}`} />
                    ) : (
                      <div className="text-end">
                        <h5 className={`${option.icon_color} mb-2`}>
                          {option.total_count}
                        </h5>
                        {/* <h5 className={`${option.icon_color} mb-0`}>
                          {strText.currency_symbol}
                          {option.total_amount}
                        </h5> */}
                      </div>
                    )}
                    <h6 className="text-uppercase dashboard-card-heading mb-0 tx-spacing-1 text-dark">
                      {option.heading}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default memo(TicketAlertSummaryCards)
