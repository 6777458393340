import { app_urls } from './urls/app_urls'

export const navigationStructure = {
  general_navigation: [
    {
      id: '01',
      has_children: false,
      name: 'Home',
      url: app_urls.home,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 2,
      has_children: false,
      name: 'Sell Tickets',
      url: app_urls.sellers,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 3,
      has_children: false,
      name: 'Buy Tickets',
      url: app_urls.buyers,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 1,
      has_children: false,
      name: 'About Us',
      url: app_urls.about_us,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.about_us,
      subPath: [],
      defaultHref: false,
    },
  ],

  event_navigation: [
    {
      id: 1,
      has_children: false,
      name: 'Sell Tickets',
      url: (id) => app_urls.sell_ticket_dyn(id),
      isFunc: true,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    // {
    // 	id: 2,
    // 	has_children: false,
    // 	name: 'My Tickets',
    // 	isFunc: false,
    // 	url: app_urls.about_us,
    // 	icon: 'mdi mdi-wechat',
    // 	mainPath: app_urls.about_us,
    // 	subPath: [],
    // 	defaultHref: false
    // }
  ],

  admin_navigation: [
    {
      id: 1,
      has_children: false,
      name: 'Dashboard',
      url: app_urls.admin_dashboard,
      icon: 'icon ion-ios-home-outline',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 2,
      has_children: false,
      name: 'User Management',
      url: app_urls.users_admin,
      icon: 'icon ion-person',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 3,
      has_children: false,
      name: 'Admin Management',
      url: app_urls.admin_admin,
      icon: 'icon ion-person',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 4,
      has_children: false,
      name: 'Venue Management',
      url: app_urls.stadium_admin,
      icon: 'icon ion-map',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
      subs: [
        {
          id: '4.1',
          has_children: false,
          name: 'Section Management',
          url: app_urls.sections_admin,
          icon: 'icon ion-map',
          // mainPath: app_urls.home,
          // subPath: [],
          defaultHref: false,
        },
        {
          id: '4.2',
          has_children: false,
          name: 'Block Management',
          url: app_urls.subsections_admin,
          icon: 'icon ion-map',
          // mainPath: app_urls.home,
          // subPath: [],
          defaultHref: false,
        },
      ],
    },
    {
      id: 5,
      has_children: false,
      name: 'Event Management',
      url: app_urls.events_admin,
      icon: 'icon ion-calendar',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 14,
      has_children: false,
      name: 'Manual Email',
      url: app_urls.manual_email,
      icon: 'icon ion-email',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 15,
      has_children: false,
      name: 'Promo Code',
      url: app_urls.promo_code,
      icon: 'icon ion-email',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 12,
      has_children: false,
      name: 'Refund Requests',
      url: app_urls.order_refunds,
      icon: 'fa fa-undo',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 6,
      has_children: false,
      name: 'Listing Management',
      url: app_urls.listings_admin,
      icon: 'icon ion-clipboard',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 7,
      has_children: false,
      name: 'Order Management',
      url: app_urls.orders_admin,
      icon: 'icon ion-filing',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    // {
    //   id: 13,
    //   has_children: false,
    //   name: 'Payouts Due',
    //   url: app_urls.payouts_due_admin,
    //   icon: 'mdi mdi-currency-usd',
    //   // mainPath: app_urls.home,
    //   // subPath: [],
    //   defaultHref: false,
    //   subs: [
    //     {
    //       id: '12.1',
    //       has_children: false,
    //       name: 'Payouts',
    //       url: app_urls.payouts_admin,
    //       icon: 'icon ion-map',
    //       // mainPath: app_urls.home,
    //       // subPath: [],
    //       defaultHref: false,
    //     },
    //   ],
    // },
    // {
    //   id: 16,
    //   has_children: false,
    //   name: 'PD Latest',
    //   url: app_urls.payouts_due_admin_latest,
    //   icon: 'mdi mdi-currency-usd',
    //   // mainPath: app_urls.home,
    //   // subPath: [],
    //   defaultHref: false,
    //   subs: [
    //     {
    //       id: '16.1',
    //       has_children: false,
    //       name: 'Payouts New',
    //       url: app_urls.payouts_admin_latest,
    //       icon: 'icon ion-map',
    //       // mainPath: app_urls.home,
    //       // subPath: [],
    //       defaultHref: false,
    //     },
    //   ],
    // },
    {
      id: 10,
      has_children: false,
      name: 'Team Management',
      url: app_urls.teams_admin,
      icon: 'icon ion-android-people',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 11,
      has_children: false,
      name: 'Competition Management',
      url: app_urls.competitions_admin,
      icon: 'icon ion-trophy',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 8,
      has_children: false,
      name: 'Country Management',
      url: app_urls.countries_admin,
      icon: 'icon ion-android-globe',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    // {
    //   id: 17,
    //   has_children: false,
    //   name: 'Payout Management',
    //   url: app_urls.payout_management,
    //   icon: 'mdi mdi-currency-usd',
    //   // mainPath: app_urls.home,
    //   // subPath: [],
    //   defaultHref: false,
    // },
    {
      id: 17,
      has_children: false,
      name: 'Payout Management',
      url: app_urls.payout_management,
      icon: 'mdi mdi-currency-usd',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
    {
      id: 18,
      has_children: false,
      name: 'Ticket Alerts',
      url: app_urls.ticket_alert,
      icon: 'mdi mdi-wechat',
      // mainPath: app_urls.home,
      // subPath: [],
      defaultHref: false,
    },
  ],

  customer_navigation: [
    {
      id: 1,
      has_children: false,
      name: 'Dashboard',
      url: app_urls.dashboard,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 2,
      has_children: false,
      name: 'Orders',
      url: app_urls.orders,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 3,
      has_children: false,
      name: 'Listings',
      url: app_urls.user_listings,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 4,
      has_children: false,
      name: 'Sales',
      url: app_urls.sales,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 5,
      has_children: false,
      name: 'Payouts',
      url: app_urls.user_payout,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.home,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 6,
      has_children: false,
      name: 'Profile',
      url: app_urls.profile,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.about_us,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 7,
      has_children: false,
      name: 'Alerts',
      url: app_urls.alerts,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.alerts,
      subPath: [],
      defaultHref: false,
    },
    {
      id: 7,
      has_children: false,
      name: 'Alert Request',
      url: app_urls.alerts_request,
      icon: 'mdi mdi-wechat',
      mainPath: app_urls.alerts_request,
      subPath: [],
      defaultHref: false,
    },
  ],
}
