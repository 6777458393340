import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, showTicketModalFunc, setDetails, showAlertModalFunc, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import moment from 'moment'
import Pagination from "react-js-pagination";
import EditTicket from '../ticket_management/EditTicket';
import ViewTicket from '../ticket_management/ViewTicket';
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'
import EditAlerts from './EditAlerts';
import DeleteAlerts from './DeleteAlerts';
import { useNavigate } from 'react-router'
import { app_urls } from '../../../../utils/urls/app_urls'
import {
    onGetCart,

    setData,
} from '../../../../config/redux/slice/appSlice'
// import { format } from 'date-fns';
export default function MatchingAlertTableList(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token, userData } = useSelector((state) => state.authData)
    const [listingDetails, setListingDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorListingId, setErrorListingId] = useState(null);
    const [localValues, setLocalValues] = useState({});
    const [localPrice, setLocalPrice] = useState({});
    const [localReservePrice, setLocalReservePrice] = useState({});
    const [loadingStates, setLoadingStates] = useState({});
    const [selectedSectionIds, setSelectedSectionIds] = useState({});
    const [selectedSubSectionIds, setSelectedSubSectionIds] = useState({});
    const [alertData, setAlertData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [selectedAlert, setSelectedAlert] = useState(null);

    const [startValue, setStartValue] = useState({});
    const [eventId, setEventId] = useState({})
    const [venueId, setVenueId] = useState({})

    useEffect(() => {
        console.log("........................XDSDlistingDetails,", props);

        localStorage.setItem("activePage", activePage);
        setListingDetails(props.listingDetails)
        setRecordsTotal(props.listingCount)

    }, [props])

    useEffect(() => {
        if (props.listingDetails)
            setLoading(false)
        // removeCartItems()
    }, [])

    const { alertManagement: { showAlertModal: { show, type } } } = useSelector((state) => state.customer)

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
    };

    // const removeCartItems = async () => {
    //     const res = await get_delete_request(
    //         'delete',
    //         token,
    //         `${app_api.remove_items_from_cart(userData.id)}`,
    //         {},
    //         dispatch,
    //         '',
    //         '',
    //         setCustomerErrorMessage
    //     )

    //     if (res !== undefined && res.status < 400) {
    //     }
    // }


    const onEdit = (data, eventId, venueId) => {
        console.log('111', data);
        setEventId(eventId)
        setVenueId(venueId);
        setItem(data, 'edit')
    };

    const setItem = async (value, type, eventId) => {
        console.log('inside setItem', eventId)
        if (type == 'edit') {
            setAlertData(value);
            setSelectedAlert(value); // Store the alert data

            dispatch(setDetails({ details: value, type: 'alert' }))
            dispatch(showAlertModalFunc({ modal: true, type }))

        }
        else if (type == 'delete') {
            setSelectedAlert(value); // Store the alert data

            dispatch(showAlertModalFunc({ modal: true, type }));

        }

        else if (type == 'buy') {
            const item = {
                eventId: eventId,
                numberOfTickets: value.numberOfTickets,
                price: value.price,
                sectionId: value.sectionId,
                subSectionId: value.subSectionId,
                ticketType: value.ticketType
            }
            const res = await post_put_request_promoCode(
                "post",
                token,
                `${app_api.check_ticket_alert}`,
                item,
                dispatch,
                setCustomerLoading,
                "",
                setCustomerErrorMessage,
                true
            )
            if (res !== undefined && res.status < 400) {
                console.log('res12', res?.data?.data?.data);
                let alertValue = res?.data?.data?.data?.ticketIds;
                console.log('alertValue', alertValue);
                let tickets = alertValue.map(ticketOption => ({
                    ticketId: ticketOption,
                    amount: Number(value.price),
                }));

                // let data = { tickets };

                // const resp = await post_put_patch_request(
                //     'patch',
                //     token,
                //     `${app_api.add_to_cart}`,
                //     data,
                //     dispatch,
                //     setCustomerLoading,
                //     '',
                //     setCustomerErrorMessage
                // );
                // if (resp && resp.status < 400) {
                    // dispatch(onGetCart(data))
                    // dispatch(onGetCart(resp.data))
                    // console.log('1111', resp);

                    // dispatch(setData({ data: resp.data.data, type: 'cart' }))
                    // setIsAddingToCart({ [index]: false });
                    navigate(app_urls.single_event_dyn(eventId), {
                        state: {
                            alertValue: value, // Pass the value or any relevant data
                        },
                    });
                // }

            }
        }
    }

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        localStorage.setItem("searchTerm", searchTerm);
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_matching_ticket_alerts}?page=1&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        } else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }
    const handlePageChange = async (pageValue) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        setActivePage(pageValue)
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_matching_ticket_alerts}?search=&page=${pageValue}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        }
        else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }

    useEffect(() => {
        setInitialValues({
            pricePerTicket: props?.pricePerTicketWithoutCommission,
            reservedPricePerTicket: props?.reservedPricePerTicket,
            totalAvailableTickets: props?.totalAvailableTickets,
        });
    }, [props]);


    return (
        <div >
            <div className="payable-tblouter order-tbl">
                <div className="form-group search-input-top">
                    <input autoComplete='off' type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
                    <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>
                    {/* <button  className="btn btn-primary me-3 ml-2" style ={{marginLeft: "10px"}} onClick={()=>handleClearChange()}>Clear</button> */}
                </div>

                <div className="table-responsive web-show-tbl">
                    <table className="table table-striped mt-3 tbl-pending">
                        <thead>
                            <tr>
                                <th scope="col" className="no-wrap">Event</th>
                                <th scope="col" className="no-wrap">Event Date</th>
                                <th scope="col" className="no-wrap">No of Alerts</th>
                                <th scope="col" className="no-wrap">Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="8" className="text-center py-4">
                                        <i className="fa fa-refresh fa-spin"></i>
                                    </td>
                                </tr>
                            ) : listingDetails && listingDetails.length > 0 ? (
                                listingDetails.map((item, index) => {
                                    // Check if at least one alert isAvailable
                                    // const hasAvailableAlert = item?.alerts?.some(alert => alert.isAvailable);

                                    return (
                                        <React.Fragment key={index}>
                                            <tr className={expandedRows[index] ? 'fontWeight' : ''}>
                                                <td>
                                                    {/* {hasAvailableAlert && (
                                                        <div className="alert-available" title="Ticket Available">
                                                            Ticket Available
                                                        </div>
                                                    )} */}
                                                    {item?.eventName}

                                                </td>
                                                <td>{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                <td>{item?.alerts?.length || 0}</td>
                                                <td className='position-relative'>
                                                    <div className="ticket-sec-wrpr">
                                                        <a
                                                            title="View Items"
                                                            className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd"
                                                            onClick={(event) => toggleRow(index, event)}
                                                        >
                                                            {expandedRows[index] ? (
                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                            ) : (
                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                            )}
                                                        </a>
                                                        {/* {hasAvailableAlert && (
                                                            <div className="alert-available" title="Ticket Available">
                                                                Ticket Available
                                                            </div>
                                                        )} */}
                                                    </div>
                                                </td>
                                            </tr>
                                            {expandedRows[index] && item?.alerts?.length > 0 && (
                                                <tr>
                                                    <td colSpan="8">
                                                        <table className="table table-striped tbl-inner-width">
                                                            <thead>
                                                                <tr>
                                                                    <th className="no-wrap">No of Tickets</th>
                                                                    <th className="no-wrap">Price</th>
                                                                    <th className="no-wrap">Expiry Date</th>
                                                                    <th className="no-wrap">Section/Block</th>
                                                                    <th className="no-wrap">Ticket Type</th>
                                                                    <th className="no-wrap">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            {item?.alerts?.map((items, orderIndex) => (
                                                                <tbody key={orderIndex}>
                                                                    <tr style={{ opacity: loadingStates[items?.id] ? 0.5 : 1 }}>
                                                                        <td>{items?.numberOfTickets}</td>
                                                                        <td>{items?.price ? items?.price : 'N/A'}</td>
                                                                        <td>{moment(items?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                                        <td>{items?.sectionName ? items.sectionName : 'ANY'}<br />
                                                                            {items?.subSectionName}
                                                                        </td>
                                                                        <td>{items?.ticketType ? items.ticketType : 'ALL'}</td>
                                                                        <td>


                                                                            <a
                                                                                onClick={() => onEdit(items, item.eventId, item.venueId)}
                                                                                title="Edit Listing"
                                                                                data-id={items?.id}
                                                                                className="btn btn-sm bg-grey btn-icon text-white edit-listing btn-m-right"
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </a>
                                                                            <a
                                                                                title="Delete Listing"
                                                                                onClick={() => setItem(items, 'delete', item.eventId)}
                                                                                data-id={items?.id}
                                                                                className="btn btn-sm bg-danger btn-icon text-white delete-listing btn-m-right"
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </a>
                                                                            {items?.isAvailable && (
                                                                                <a
                                                                                    title="View Ticket"
                                                                                    onClick={() => setItem(items, 'buy', item.eventId)}
                                                                                    class="btn btn-sm bg-success btn-icon text-white view-complaints btn-m-right btn-i-padd">
                                                                                    <i class="fa fa-eye"></i>
                                                                                </a>

                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                        </table>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center py-4">No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                </div>

                <div className="mob-table-section">
                    {loading ? (
                        <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>
                    ) : listingDetails ? (
                        listingDetails.map((item, index) => {
                            // const hasAvailableAlert = item?.alerts?.some(alert => alert.isAvailable);

                            return (
                                <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                    <div className="position-relative">
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event</h3>
                                            <div className="mob-tbl-value" title={item?.eventName}>{item?.eventName}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">Event Date</h3>
                                            <div className="mob-tbl-value">{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                        </div>
                                        <div className="mob-table-sec">
                                            <h3 className="mob-tbl-head">No of Alerts</h3>
                                            <div className="mob-tbl-value">{item?.alerts?.length || 0}</div>
                                        </div>
                                        <div className="mob-table-sec-expand">
                                            <a title="View Items" className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )}
                                            </a>
                                            {/* {hasAvailableAlert && (
                                                <div className="alert-available" title="Ticket Available">
                                                    Ticket Available
                                                </div>
                                            )} */}
                                        </div>
                                    </div>

                                    {(expandedRows[index] && item?.alerts?.length > 0) && (
                                        <>
                                            {item?.alerts?.map((alert, orderIndex) => (
                                                <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">No of Tickets</h3>
                                                        <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{alert?.numberOfTickets}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Price</h3>
                                                        <div className="mob-tbl-value">{alert?.price ? alert?.price : 'N/A'}</div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Expiry Date</h3>
                                                        <div className="mob-tbl-value">
                                                            <div className="input-group-prepend d-flex align-items-center">
                                                                {moment(alert?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Section/Block</h3>
                                                        <div className="mob-tbl-value">
                                                            {alert?.sectionName ? alert.sectionName : 'ANY'}
                                                            <br />
                                                            {alert?.subSectionName}
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Ticket Type</h3>
                                                        <div className="mob-tbl-value">
                                                            {alert?.ticketType ? alert.ticketType : 'ALL'}
                                                        </div>
                                                    </div>
                                                    <div className="mob-table-sec">
                                                        <h3 className="mob-tbl-head">Actions</h3>
                                                        <div className="mob-tbl-value">

                                                            <a
                                                                onClick={() => onEdit(alert, item.eventId, item.venueId)}
                                                                title="Edit Listing"
                                                                data-id={alert?.id}
                                                                className="btn btn-sm bg-grey btn-icon text-white edit-listing btn-m-right"
                                                            >
                                                                <i className="fa fa-pencil"></i>
                                                            </a>
                                                            <a
                                                                title="Delete Listing"
                                                                onClick={() => setItem(alert, 'delete', item.eventId)}
                                                                data-id={alert?.id}
                                                                className="btn btn-sm bg-danger btn-icon text-white delete-listing btn-m-right"
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </a>
                                                            {alert?.isAvailable && (
                                                                <button
                                                                    onClick={() => setItem(alert, 'buy', alert.eventId)}
                                                                    className="btn fw-bold btn-buyNowsec"
                                                                >
                                                                    Buy Now
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-center py-4">No Data Found</div>
                    )}
                </div>;






                {recordsTotal && recordsTotal > 10 ?
                    <div className='pagination-wrapper'>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={recordsTotal}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                    : ""}

                {(show && type == 'edit') &&
                    <EditAlerts key={alertData} getList={props.getList1} alertObj={alertData} eventId={eventId} venueId={venueId} />
                }
                {(show && type == 'delete') &&
                    <DeleteAlerts getList={props.getList1} alertObj={selectedAlert} />
                }
            </div>
        </div >
    );
}
