import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { post_put_patch_request } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { onUpdate } from '../../../../config/redux/slice/adminSlice'


export default function AddComplaint({showRegisterComplaintPopup, setShowRegisterComplaintPopup}) {
//   const [description, setDescription] = useState('');
//   const [reportedBy, setReportedBy] = useState('buyer'); // Default to 'Reported by buyer'
//   const dispatch = useDispatch()
//   const { token } = useSelector((state) => state.authData)
//   const [submitting, setSubmitting] = useState(false);

  const closeComplaintPopup = () => {
    setShowRegisterComplaintPopup(false);

  };

  const handleSubmit = async () => {
    // if (submitting) return; // Prevent further submission if already submitting

    // setSubmitting(true);    const complaintData = {
    //   description,
    // };

    // if (reportedBy === 'buyer') {
    //   complaintData.hasBuyer = true;
    //   complaintData.userId = selectedItem.OrderItems[0]?.order?.userId;
    // } else {
    //   complaintData.hasBuyer = false;
    //   complaintData.userId = selectedItem.OrderItems[0]?.ticket?.listing?.user?.id;
    // }

    // const orderId = selectedItem.id;
    // const baseURL = `${app_api.complaints}`;
    // const endpoint = "/complaint";

    // const url = `${baseURL}/${orderId}${endpoint}`;
    // try {
    //   const res = await post_put_patch_request(
    //     "post",
    //     token,
    //     url,
    //     complaintData,
    //     dispatch,
    //     "",
    //     "",
    //     ""
    //   );
  
    //   if (res !== undefined && res.status < 400) {
    //     dispatch(onUpdate({ message: res.data.message, type: 'refund' }));
    //     closeComplaintPopup();
    //   }
    // } catch (error) {
    //   console.error('Error submitting complaint:', error);
    // } finally {
    //   setSubmitting(false); 
    // }
  };

//   useEffect(() => {
//     console.log('inside addComment');
//     console.log('showComplaintPopup', showComplaintPopup);
//     if (showComplaintPopup) {
//       // // Reset the form fields when the popup is opened
//       // setDescription('');
//       // setReportedBy('buyer');
//     }
//   }, [showComplaintPopup]);

 
  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showRegisterComplaintPopup ? 'flex' : 'none' }}>
      <div className="modal__content">
        <div className="modalFields">
          <div className='add-complaint-wrapper'>
            <div className="form-group mb-2">
              <label htmlFor="description" className='lbl-text-font'>Add Complaint</label>
              <textarea
                id="description"
                className="form-control"
                // value={description}
                // onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
          
            <div className="text-right-btn">
              <button className="btn btn-primary" 
              onClick={handleSubmit} 
              // disabled={submitting}
              >Submit</button>
            </div>
          </div>
        </div>
        <a 
        onClick={closeComplaintPopup}
         className="modal__close" style={{ cursor: 'pointer' }}>
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
