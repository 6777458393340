import React, { useState, useEffect } from 'react';
import { app_api } from '../../../../utils/endpoints/app_api'
import { post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { setCustomerErrorMessage, setCustomerLoading} from '../../../../config/redux/slice/customerSlice'
import { onUpdate, setAdminErrorMessageUser, setAdminLoadingUser } from '../../../../config/redux/slice/adminSlice'
import Spinner from '../../../components/Spinner'

export default function AddComment({ showComplaintPopup, setShowComplaintPopup, selectedItem, actionType, setOrderStatusId,getPendingList }) {
  const [description, setDescription] = useState('');
  const [reportedBy, setReportedBy] = useState('buyer'); // Default to 'Reported by buyer'
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.authData)
  const [submitting, setSubmitting] = useState(false);
  const [inputError, setInputError] = useState(false); // Add state for input validation

  const closeComplaintPopup = () => {
    setShowComplaintPopup(false);

  };
  const {
    adminLoading,
    
  } = useSelector((state) => state.admin)

  const handleSubmit = async () => {
    if (submitting) return; // Prevent further submission if already submitting

    if (!description.trim()) {
      setInputError(true); // Set error state if the input is empty
      return;
  }


    setSubmitting(true);
 
    const orderId = selectedItem;
    let url = '';
    let updateData = {};

    if (actionType === "REGISTER COMPLAINT") {

      updateData = {
        statusId: setOrderStatusId,
        comment: description,
      };

      await post_put_request_promoCode(
        "post",
        token,
        `${app_api.orderStatus(orderId)}`,
        updateData,
        dispatch,
        setAdminLoadingUser,
        '',
        setAdminErrorMessageUser,
        true
    );

      url = `${app_api.orderStatusComplaint(orderId)}`;

    } else if (actionType === "ON HOLD") {
      updateData = {
        statusId: setOrderStatusId,
        comment: description,
      };
      url = `${app_api.orderStatus(orderId)}`;

    } else {
      console.log("something went wrong")
    }

    try {

      const res = await post_put_patch_request(
        "post",
        token,
        url,
        updateData,
        dispatch,
        setCustomerLoading,
        "",
        setCustomerErrorMessage
      );

      if (res !== undefined && res.status < 400) {
        dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
      }
else{
  dispatch(onUpdate({ message: res.data.message, type: 'promocode' }))
}
      closeComplaintPopup()
      getPendingList()

    } catch (error) {
      console.error("Error in saving status:", error);
    }
  };

  useEffect(() => {
    if (showComplaintPopup) {
    }
  }, [showComplaintPopup]);


  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showComplaintPopup ? 'flex' : 'none' }}>
      <div className="modal__content">
        <div className="modalFields">
          <div className='add-complaint-wrapper'>
            <div className="form-group mb-2">
              <label htmlFor="description" className='lbl-text-font'>Add Comment</label>
              <textarea
                id="description"
                className={`form-control ${inputError ? 'border-danger' : ''}`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>

            <div className="text-right-btn">
            {adminLoading ?
                    <Spinner isForm={true} /> :
              <button className="btn btn-primary" onClick={handleSubmit} disabled={submitting}>Submit</button>
            }
            </div>
          </div>
        </div>
        <a onClick={closeComplaintPopup} className="modal__close" style={{ cursor: 'pointer' }}>
          <i className="fa fa-close"></i>
        </a>
      </div>
    </div>
  );
}
