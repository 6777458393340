import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser, setAdminLoadingUser, setCustomerLoading, setCustomerErrorMessage, showAlertModalFunc, setAdminPromoCodeErrorMessageUser } from '../../../../config/redux/slice/customerSlice'
import { convertToDateTime, truncateMiddle } from '../../../../helpers/helpers'
import { post_put_request_promoCode } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'
import { get_delete_request } from '../../../../helpers/request'
import { strText } from '../../../../constants/english'
import { setAppLoading } from '../../../../config/redux/slice/appSlice'


export default function SetTicketAlerts({ showAlertPopup, setShowAlertPopup, venueObj, eventDetails }) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const [startDate, setStartDate] = useState("");
  const [sectionsData, setSections] = useState([])
  const [subSections, setSubSections] = useState([])
  const [selectedSection, setSelectedSection] = useState('')
  const [selectedSubSection, setSelectedSubSection] = useState('')
  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const {
    customerLoading,
    listingManagement: { singleListingDetails, listingDetails }
  } = useSelector((state) => state.customer)

  useEffect(() => {
    getAllSections()
  }, [venueObj])

  useEffect(() => {
    if (selectedSection !== '') {
      getAllSUbSections()
    }
  }, [selectedSection])

  const getAllSections = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_sections(venueObj?.id)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      // dispatch(
      //   setEventValues({ propertyKey: 'sectionData', value: res.data.data })
      // )
      setSections(res.data.data)
    }
  }

  const getAllSUbSections = async (id) => {
    console.log('idddd', id);
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_subsections(id)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      setSubSections(res.data.data)
    }
  }
  const onSubmit = async (data) => {
    console.log('formData1111', data);
    dispatch(clearAll({}))

    const convertToDateTime = (date, time = "00:00") => {
      const isoString = new Date(`${date}T${time}`).toISOString();
      return isoString;
    };
    // dispatch(clearAll({}));
    let dateTime = null;
    const formatDateToISO = (date) => {
      if (!date) return null;
      const formattedDate = moment(date)
        .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
      return formattedDate;
    };

    // Convert expiryDate to the required format
    const formattedExpiryDate = formatDateToISO(data.expiryDate);
    console.log('formattedExpiryDate', formattedExpiryDate);
    data = {
      ...data,
      expiryDate: formattedExpiryDate,
      eventId: eventDetails.id,
      price: Number(data.price),
      numberOfTickets: Number(data.numberOfTickets)
    };

    console.log('Formatted formData:', data);
    const res = await post_put_request_promoCode(
      "post",
      token,
      `${app_api.ticket_alert}`,
      data,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage
      
    )
    if (res !== undefined && res.status < 400) {
      setFormData(new FormData())
      dispatch(onUpdate({ message: res.data.message, type: 'alert' }))

      setShowAlertPopup(false);

    }
  }


  const closeModal = () => {
    dispatch(showAlertModalFunc({ modal: false, type: null }));
    setShowAlertPopup(false); // Close the popup
    reset(); // Reset form values
    setFormData(new FormData()); // Reset formData if needed
  };

  useEffect(() => {
    if (showAlertPopup) {
      reset(); // Reset form values whenever the modal is reopened
      setFormData(new FormData());
    }
  }, [showAlertPopup, reset]);

  return (
    <div id="demo-modal" className="modal delivery-addresssec" style={{ display: showAlertPopup ? 'flex' : 'none' }}>
      <div className="modal__content modal__content-padd pt-4 modal-widthSetAnAlert">
        {/* <a onClick= {closeModal()} className="modal__close">
          <i className="fa fa-close"></i>
        </a>         */}
        <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
          <div className="modal-body">
            <div className="px-4 px-md-7">
              <div style={{ rowGap: '1.2rem' }} className="row mb-2">

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='description'>No of Tickets<span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      id="numberOfTickets"
                      {...register("numberOfTickets", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />
                    {
                      errors?.numberOfTickets?.message &&
                      <div className="text-danger small mt-1">
                        {errors?.numberOfTickets?.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='description'>Price/each({strText.currency_symbol}) <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      id="price"
                      {...register("price", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />

                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='ticketType'>Ticket Type</label>
                    <select
                      className="form-control input-lg"
                      id="ticketType"
                      {...register("ticketType", {
                        required: {
                          value: true,
                          message: '*this field is required',
                        },
                      })}
                    >
                      <option value="ALL">ALL</option>
                      <option value="MOBILE">MOBILE</option>
                      <option value="PHYSICAL">PHYSICAL</option>
                      <option value="ELECTRONIC">ELECTRONIC</option>
                    </select>
                    {errors?.ticketType?.message && (
                      <div className="text-danger small mt-1">
                        {errors.ticketType.message}
                      </div>
                    )}
                  </div>
                </div>


                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='startdate'>Expiry Date <span className="text-danger">*</span></label>
                    <input
                      type="date"
                      min={moment().format('YYYY-MM-DD')}
                      style={{ cursor: "pointer" }}
                      onFocus={(e) => e.currentTarget.showPicker()}
                      className="form-control input-lg"
                      id="expiryDate"
                      {...register("expiryDate", {
                        required: "*Expiry date is required",

                        onChange: (e) => setStartDate(e.target.value),
                      })
                      }

                    />
                    {errors?.expiryDate?.message && (
                      <div className="text-danger small mt-1">{errors.expiryDate.message}</div>
                    )}
                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  {sectionsData.length !== 0 && (
                    <div className="form-group mb-3">
                      <label htmlFor="sectionId" className="form-label">
                        Section
                      </label>
                      <select
                        className="form-select"
                        style={{padding: '0.65rem 35px', paddingLeft: '1rem'}}
                        id="sectionId"
                        {...register('sectionId', {

                        })}
                        onChange={(e) => {
                          getAllSUbSections(e.target.value)
                        }}
                      >
                        <option value="ANY">ANY</option>
                        {sectionsData.map((section) => {
                          return (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          )
                        })}
                      </select>
                      {errors?.sectionId?.message && (
                        <div className="text-danger p mt-1">
                          {errors?.sectionId?.message}
                        </div>
                      )}
                    </div>
                  )}
                </div>


                <div className="col-md-6 col-lg-6">
                  {subSections.length !== 0 && (
                    <div className="form-group mb-4">
                      <label htmlFor="subSectionId" className="form-label">
                        Block
                      </label>
                      <select
                        className="form-select"
                        id="subSectionId"
                        {...register('subSectionId')}
                      >
                        {/* <option value="">Select Subsection</option> */}
                        <option value="">N/A</option>
                        {subSections.map((subSection) => {
                          return (
                            <option key={subSection.id} value={subSection.id}>
                              {subSection.name}
                            </option>
                          )
                        })}
                      </select>
                      {errors?.subSectionId?.message && (
                        <div className="text-danger p mt-1">
                          {errors?.subSectionId?.message}
                        </div>
                      )}
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
          <div className="modal-footer border-top-0 d-flex align-items-center">
            <div>
              {customerLoading ?
                <Spinner isForm={true} /> :
                <button type="submit" className="btn btn-primary me-3">Submit Form</button>
              }
            </div>
            <button
              disabled={customerLoading}
              onClick={(e) => {
                e.preventDefault(); // Prevent default form action
                closeModal();
              }}
              type="button"
              className="btn btn-info"
            >
              Cancel
            </button>          </div>
        </form>
        {/* <a onClick={closeAlertPopup} className="modal__close btn-close-restrictn btn">
          Close
        </a>    */}
      </div>
    </div>
  );
}
