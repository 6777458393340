import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, showTicketModalFunc, setDetails, showAlertModalFunc, setCustomerLoading, onGetProfileSales, setCustomerErrorMessage } from '../../../../config/redux/slice/customerSlice'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
import moment from 'moment'
import Pagination from "react-js-pagination";

import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'

import { useNavigate } from 'react-router'
import { app_urls } from '../../../../utils/urls/app_urls'
import {
    onGetCart,

    setData,
} from '../../../../config/redux/slice/appSlice'
// import { format } from 'date-fns';
export default function AlertsTableList(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token, userData } = useSelector((state) => state.authData)
    const [listingDetails, setListingDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [nestedExpandedRowsList, setNestedExpandedRowsList] = useState({});
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [ticketData, setTicketData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [errorListingId, setErrorListingId] = useState(null);
    const [localValues, setLocalValues] = useState({});
    const [localPrice, setLocalPrice] = useState({});
    const [localReservePrice, setLocalReservePrice] = useState({});
    const [loadingStates, setLoadingStates] = useState({});
    const [selectedSectionIds, setSelectedSectionIds] = useState({});
    const [selectedSubSectionIds, setSelectedSubSectionIds] = useState({});
    const [alertData, setAlertData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [selectedAlert, setSelectedAlert] = useState(null);

    const [startValue, setStartValue] = useState({});
    const [eventId, setEventId] = useState({})
    const [venueId, setVenueId] = useState({})


    useEffect(() => {
        localStorage.setItem("activePage", activePage);
        setListingDetails(props.listingDetails)
        setRecordsTotal(props.listingCount)
    }, [props])

    useEffect(() => {
        if (props.listingDetails)
            setLoading(false)
        removeCartItems()
    }, [])

    const { alertManagement: { showAlertModal: { show, type } } } = useSelector((state) => state.customer)

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
    };

    const removeCartItems = async () => {
        const res = await get_delete_request(
            'delete',
            token,
            `${app_api.remove_items_from_cart(userData.id)}`,
            {},
            dispatch,
            '',
            '',
            setCustomerErrorMessage
        )

        if (res !== undefined && res.status < 400) {
        }
    }


    const onEdit = (data, eventId, venueId) => {
        debugger
        console.log('111', data);
        setEventId(eventId)
        setVenueId(venueId);
        setItem(data, 'edit')
    };

    const setItem = async (value, type, eventId) => {
        debugger
        console.log('inside setItem')
        if (type == 'edit') {
            setAlertData(value);
            setSelectedAlert(value); // Store the alert data

            dispatch(setDetails({ details: value, type: 'alert' }))
            dispatch(showAlertModalFunc({ modal: true, type }))

        }
        else if (type == 'delete') {
            setSelectedAlert(value); // Store the alert data

            dispatch(showAlertModalFunc({ modal: true, type }));

        }

        else if (type == 'buy') {

            const item = {
                eventId: eventId,
                numberOfTickets: value.numberOfTickets,
                price: value.price,
                sectionId: value.sectionId,
                subSectionId: value.subSectionId,
                ticketType: value.ticketType
            }
            const res = await post_put_request_promoCode(
                "post",
                token,
                `${app_api.check_ticket_alert}`,
                item,
                dispatch,
                setCustomerLoading,
                "",
                setCustomerErrorMessage,
                true
            )
            if (res !== undefined && res.status < 400) {
                console.log('res12', res?.data?.data?.data);
                let alertValue = res?.data?.data?.data?.ticketIds;
                console.log('alertValue', alertValue);
                let tickets = alertValue.map(ticketOption => ({
                    ticketId: ticketOption,
                    amount: Number(value.price),
                }));

                let data = { tickets };

                const resp = await post_put_patch_request(
                    'patch',
                    token,
                    `${app_api.add_to_cart}`,
                    data,
                    dispatch,
                    setCustomerLoading,
                    '',
                    setCustomerErrorMessage
                );
                if (resp && resp.status < 400) {
                    // dispatch(onGetCart(data))
                    // dispatch(onGetCart(resp.data))
                    console.log('1111', resp);

                    dispatch(setData({ data: resp.data.data, type: 'cart' }))
                    // setIsAddingToCart({ [index]: false });
                    navigate(app_urls.buy_ticket);
                }

            }
        }
    }

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        localStorage.setItem("searchTerm", searchTerm);
    };
    const handleSearchChange = async (event) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.list_ticket_alerts}?page=1&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        } else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }
    const handlePageChange = async (pageValue) => {
        setLoading(true);
        setExpandedRows({})
        setNestedExpandedRows({});
        setNestedExpandedRowsList({});
        setActivePage(pageValue)
        dispatch(clearAll({}));
        const pageSize = 10; // Adjust this value as per your requirements
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.list_ticket_alerts}?search=&page=${pageValue}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );
        console.log("🚀 ~ handleSearchChange ~ res:", res);
        if (res !== undefined && res.status < 400) {
            setListingDetails(res.data.data)
            setRecordsTotal(res.data.recordsFiltered)
            setLoading(false);
        }
        else {
            setListingDetails([])
            setRecordsTotal("")
            setLoading(false);
        }

    }

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };

    useEffect(() => {
        setInitialValues({
            pricePerTicket: props?.pricePerTicketWithoutCommission,
            reservedPricePerTicket: props?.reservedPricePerTicket,
            totalAvailableTickets: props?.totalAvailableTickets,
        });
    }, [props]);


    return (
        <div >
            <div className="payable-tblouter order-tbl">
                <div className="form-group search-input-top">
                    <input autoComplete='off' type="text" className="form-control" id="searchValue" placeholder="Search..." onChange={handleInputChange} />
                    <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={() => handleSearchChange()}>Search</button>
                </div>

                <div className="table-responsive web-show-tbl">
                    <table className="table table-striped mt-3">
                        <thead>
                            <tr>
                                <th scope="col" className="no-wrap">Event</th>
                                <th scope="col" className="no-wrap">Event Date</th>
                                <th scope="col" className="no-wrap">No of Alerts</th>
                                <th scope="col" className="no-wrap">Expand</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="6" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                                </tr>
                            ) : listingDetails.length > 0 ? (
                                listingDetails.map((item, index) => (
                                    <>
                                        <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                            <td>
                                                {item?.eventName}

                                            </td>
                                            <td>{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                            <td>{item?.totalNumberOfAlerts || 0}</td>
                                            <td> <a title='Details Payout' className="btn btn-sm bg-primary text-white btn-icon view-details btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                                {expandedRows[index] ? (
                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                ) : (
                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                )} </a></td>
                                        </tr>


                                        {(expandedRows[index] && item.buyerDetails.length > 0) && (
                                            <tr>
                                                <td colSpan="8">
                                                    <table className="table table-striped tbl-inner-width">
                                                        <thead>
                                                            <tr>
                                                                <th className="no-wrap">Buyer Name</th>
                                                                <th className="no-wrap">Buyer Email</th>
                                                                <th className="no-wrap">Buyer Phone</th>

                                                                <th className="no-wrap">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        {item.buyerDetails.map((items, orderIndex) => (
                                                            <tbody>
                                                                <tr key={orderIndex}>
                                                                    <td>{items?.buyerName}</td>
                                                                    <td>{items?.buyerEmail}</td>
                                                                    <td>{items?.buyerPhone}</td>
                                                                    <td>
                                                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                                            {nestedExpandedRows[index]?.[orderIndex] ? (
                                                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                                                            ) : (
                                                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                                                            )}
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                                    <tr>
                                                                        <td colSpan="7">
                                                                            <table className="table table-bordered tbl-subinner-width">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="no-wrap">No of Tickets</th>
                                                                                        <th className="no-wrap">Price</th>
                                                                                        <th className="no-wrap">Expiry Date</th>
                                                                                        <th className="no-wrap">Section/Block</th>
                                                                                        <th className="no-wrap">Ticket Type</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {items?.alerts?.map((alerts, alertIndex) => (
                                                                                        <tr key={orderIndex}>
                                                                                            <td>{alerts?.numberOfTickets}</td>
                                                                                            <td>{alerts?.price ? alerts?.price : 'N/A'}</td>
                                                                                            <td>{moment(alerts?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                                                                            <td>{alerts?.sectionName ? alerts.sectionName : 'N/A'}<br />
                                                                                                {alerts?.subSectionName ? alerts.subSectionName : 'N/A'}
                                                                                            </td>
                                                                                            <td>{alerts?.ticketType}</td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>))}
                                                    </table>
                                                </td>
                                            </tr>
                                        )}

                                    </>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6" className="text-center py-4">No Data Found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                <div className="mob-table-section">
                    {loading ? (
                        <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>
                    ) : listingDetails.length > 0 ? (
                        listingDetails.map((item, index) => (
                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.eventName}>{item?.eventName}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.eventDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Alerts</h3>
                                        <div className="mob-tbl-value">{item?.totalNumberOfAlerts || 0}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        <a title="View Items" className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )}
                                        </a>
                                    </div>
                                </div>

                                {expandedRows[index] && item.buyerDetails.length > 0 && (
                                    <div className="mob-tbl-inner-sec">
                                        {item.buyerDetails.map((buyer, buyerIndex) => (
                                            <div key={buyerIndex} className="mob-table-sec">
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Name</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerName}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Email</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerEmail}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Buyer Phone</h3>
                                                    <div className="mob-tbl-value">{buyer?.buyerPhone}</div>
                                                </div>
                                                <div className="mob-table-sec-expand">
                                                    <a title="View Alerts" className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, buyerIndex, event)}>
                                                        {nestedExpandedRows[index]?.[buyerIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>
                                                </div>

                                                {/* {nestedExpandedRows[index]?.[buyerIndex] && (
                                                    <div className="mob-tb-subinner">
                                                        {buyer.alerts.map((alert, alertIndex) => (
                                                            <div key={alertIndex} className="mob-table-sec">
                                                                <h3 className="mob-tbl-head">No of Tickets</h3>
                                                                <div className="mob-tbl-value">{alert?.numberOfTickets || 0}</div>
                                                                <h3 className="mob-tbl-head">Price</h3>
                                                                <div className="mob-tbl-value">{alert?.price || 'N/A'}</div>
                                                                <h3 className="mob-tbl-head">Expiry Date</h3>
                                                                <div className="mob-tbl-value">{moment(alert?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                                                <h3 className="mob-tbl-head">Section/Block</h3>
                                                                <div className="mob-tbl-value">{`${alert?.sectionName || 'N/A'} ${alert?.subSectionName || ''}`}</div>
                                                                <h3 className="mob-tbl-head">Ticket Type</h3>
                                                                <div className="mob-tbl-value">{alert?.ticketType}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )} */}

                                                   {nestedExpandedRows[index] && (
                                                    <>
                                                        {buyer.alerts.map((alert, itemIndex) => {
                                                            return (

                                                                <div className="mob-tb-subinner" key={itemIndex}>

                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">No of Tickets</h3>
                                                                        <div className={`mob-tbl-value`}>{alert?.numberOfTickets || 0}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Price</h3>
                                                                        <div className="mob-tbl-value">
                                                                            {alert?.price || 'N/A'}
                                                                           </div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Expiry Date</h3>
                                                                        <div className="mob-tbl-value">{moment(alert?.expiryDate).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Section/Block</h3>
                                                                        <div className="mob-tbl-value">{alert?.sectionName ? alert.sectionName : 'N/A'}
                                                            <br />
                                                            {alert?.subSectionName ? alert.subSectionName : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Ticket Type</h3>
                                                                        <div className="mob-tbl-value">{alert?.ticketType}</div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="text-center py-4">No Data Found</div>
                    )}
                </div>




                {recordsTotal && recordsTotal > 10 ?
                    <div className='pagination-wrapper'>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={recordsTotal}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                    : ""}


            </div>
        </div >
    );
}
