import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setDetails, setOrderValues, showOrderModalFunc, setSalesValues, showSalesModalFunc } from '../../../../config/redux/slice/customerSlice'
import { setPayoutValues } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
// import ConfirmDelivery from './ConfirmDelivery'
import moment from 'moment'
import Pagination from "react-js-pagination";

import { admin_urls } from '../../../../utils/urls/admin_urls'
import { setBreadCrumbName } from '../../../../config/redux/slice/appSlice'


export default function PayoutDueTableNew() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [payoutData, setPayoutData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');

    const {
        payoutManagement: { dueNewData }
      } = useSelector((state) => state.admin)
    

    const { breadCrumbName, currentNavDetails } = useSelector((state) => state.app)

    console.log("currentNavDetails", currentNavDetails)


    useEffect(() => {
        getPayoutDue()
    }, [])




    const getPayoutDue = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_payouts}?page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getPendingList ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let payoutList = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            console.log("🚀 ~ getPayoutList ~ res:...................", payoutList)
            console.log("🚀 ~ getPayoutCount ~ res:...................", totalCount)
            setPayoutData(payoutList);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }


    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_payouts}?page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handlePageChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let payoutList = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handlePageChange ~ payoutList:...................", payoutList);
            console.log("🚀 ~ handlePageChange ~ totalCount:...................", totalCount);

            setPayoutData(payoutList);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(pageNumber);
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const jumpToPayoutTable = async (id) => {

        dispatch(setPayoutValues({ propertyKey: 'payoutEventId', value: id }))


        let payouts = currentNavDetails?.subs?.filter((option) => app_urls.payouts_admin_latest == option.url)

        dispatch(setBreadCrumbName([...breadCrumbName, { id: breadCrumbName.length + 1, name: payouts[0].name, url: payouts[0].url }]))
        navigate(admin_urls.payouts_admin_latest)

    }

    const handleSearchChange = async (event) => {
        // const value = event.target.value;
        // setSearchTerm(value);
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_payouts}?search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handleSearchChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let payoutList = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handleSearchChange ~ payoutList:...................", payoutList);
            console.log("🚀 ~ handleSearchChange ~ totalCount:...................", totalCount);

            setPayoutData(payoutList);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(1);
    }


    return (

        <>


            <div className="form-group search-input-top">
                <input autoComplete='off' type="text" className="form-control" id="" placeholder="Search..." onChange={handleInputChange} />
                <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleSearchChange}>Search</button>

            </div>


            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>Date</th>
                            <th scope="col" className='no-wrap'>Poster</th>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Amount due</th>
                            <th scope="col" className='no-wrap'>Payout Status</th>
                            <th scope="col" className='no-wrap'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : payoutData.length > 0 ? (
                            payoutData.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? ''}</td>
                                        <td><img src={item?.poster} crossorigin='anonymous' width='40' height='40' /></td>
                                        <td>{item?.name}</td>
                                        <td>{strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}</td>
                                        <td>{item?.amount?.payoutInitiated}</td>
                                        <td> <a
                                            onClick={() => jumpToPayoutTable(item?.id)}
                                            title="Manage Payouts"
                                            data-id={item?.id}
                                            class="btn btn-sm bg-primary btn-icon text-white">
                                            <i class="fa fa-bars"></i>
                                        </a></td>

                                    </tr>
                                </>

                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>


                </table>
            </div>


            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}
