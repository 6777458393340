import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import { truncateMiddle } from '../../../../helpers/helpers'
import {
  clearAll,
  onUpdate,
  setCustomerErrorMessage,
  setCustomerLoading,
} from '../../../../config/redux/slice/customerSlice'
import { post_put_patch_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { onGetUser } from '../../../../config/redux/slice/authSlice'
import PhoneNumberComponent from '../../../components/Form/PhoneNumberComponent'

export default function EditProfile() {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData())

  const { token } = useSelector((state) => state.authData)

  const {
    customerLoading,

    customerSuccessMsg,
    listingManagement: { listingFormData },
    eventManagement: { eventDetails },
  } = useSelector((state) => state.customer)

  const onSubmit = async (data) => {
    dispatch(clearAll({}))

    if (data?.code == undefined) {
      setError('code', {
        type: 'manual',
        message: 'Please select country code',
      })
    } else {

      let trimmedMobileNo = data.mobile_no;

      if (data.code && data.mobile_no) {
        // Escape the '+' character in the country code using a backslash
        const escapedCode = data.code.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escapes special characters
        const countryCodeRegex = new RegExp(`^${escapedCode}\\s?`);

        // Remove the country code from mobile_no if it starts with it
        trimmedMobileNo = data.mobile_no.replace(countryCodeRegex, '').trim();

        // Set the final phone number without duplicating the country code
        data.phone = data.code + ' ' + trimmedMobileNo;
    }

      for (let key in data) {
        if (typeof data[key] == 'object') {
          if (data[key].length != 0) {
            formData.append(key, data[key][0])
          }
        } else {
          if (data[key] && data[key] != '') {
            formData.append(key, data[key])
          }
        }
      }

      const res = await post_put_patch_request(
        'patch',
        token,
        `${app_api.update_user_info}`,
        formData,
        dispatch,
        setCustomerLoading,
        onGetUser,
        setCustomerErrorMessage,
        true
      )
      if (res !== undefined && res.status < 400) {
        dispatch(onUpdate({ message: res.data.message }))
        reset()
        resetFileInp()
        setFormData(new FormData())
      } else {
        setFormData(new FormData())
      }
    }
  }

  function addFileName(e, idValue) {
    let fileName = e.target.value
    if (fileName !== '') {
      fileName = fileName.replace(/^.*[\\\/]/, '')
      document.getElementById(idValue).innerHTML = truncateMiddle(fileName, 30)
    } else {
      document.getElementById(idValue).innerHTML = 'No file Chosen'
    }
  }

  function resetFileInp() {
    document.querySelectorAll('.custom-file-label').forEach(() => {
      resetFileInp.innerHTML = 'Choose file...'
    })
  }

  return (
    <div className="border-top py-3">
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">
            <div className="col-xl-6">
              <div className="form-group">
                <label htmlFor="avatar">Avatar</label>
                <div>
                  <div className="custom-file mb-1">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="avatar"
                      {...register('avatar')}
                      onChange={(e) => {
                        addFileName(e, 'custom-certificate')
                      }}
                    />
                    <label
                      id="custom-certificate"
                      className="custom-file-label"
                    >
                      Choose file...
                    </label>
                    {errors?.avatar?.message && (
                      <div className="text-danger small mt-1">
                        {errors?.avatar?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6">
              <div className="form-group">
                <PhoneNumberComponent
                  fieldName={'mobile_no'}
                  fieldName2={'code'}
                  error_msg={errors.mobile_new?.message ?? errors.code?.message}
                  label={'Phone Number'}
                  setValueFunc={setValue}
                  setErrorFunc={setError}
                  clearErrorsFunc={clearErrors}
                  isReq={true}
                  register={register}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  autoComplete='off'
                  className="form-control input-lg"
                  id="address"
                  {...register('address')}
                />
                {errors?.address?.message && (
                  <div className="text-danger small mt-1">
                    {errors?.address?.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {customerLoading ? (
              <Spinner isForm={true} />
            ) : (
              <button type="submit" className="btn btn-accent me-3">
                Submit Form
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  )
}
