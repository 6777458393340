import { core_backend_url } from '../../config'

export const user_api = {
  add_listing: `${core_backend_url}listing`,
  upload_ticket: (ticketId) =>
    `${core_backend_url}listing/ticket/${ticketId}/upload`,
  listing_by_event: (eventId) => `${core_backend_url}listing/event/${eventId}`,
  get_single_listing: (listingId) => `${core_backend_url}listing/${listingId}`,
  update_listing: (listingId) => `${core_backend_url}listing/${listingId}`,
  update_delivery_address: (DeliveryAddressId) => `${core_backend_url}order/update_delivery_address/${DeliveryAddressId}`,
  update_price_listing: (listingId) => `${core_backend_url}listing/price_listing/${listingId}`,

  update_section_listing: (listingId) => `${core_backend_url}listing/section_listing/${listingId}`,

  orderStatus: (orderId) => `${core_backend_url}payout/add-comment/${orderId}`,

  orderStatusComplaint: (orderId) => `${core_backend_url}payout/complaint/${orderId}`,

  orderPriceChange: (orderId) => `${core_backend_url}payout/orderDuePriceChange/${orderId}`,

  getStatusHistoryList: (orderId) => `${core_backend_url}payout/getStatusHistoryList/${orderId}`,
  getAmountHistoryList: (orderStatusId,orderId) => `${core_backend_url}payout/getAmountHistoryList/${orderStatusId}?orderId=${orderId}`,

  get_user_tickets: (listingId) =>
    `${core_backend_url}listing/${listingId}/tickets`,
  get_auth_user_listings: `${core_backend_url}listing/user`,
  get_auth_search_listing: `${core_backend_url}listing/user/search`,
  get_auth_user_listings_expand:(eventId) => `${core_backend_url}listing/user/${eventId}/expand`,
  remove_items_from_cart : (userId) =>`${core_backend_url}cart/remove/${userId}`,
  get_listings_by_event_user: (eventId) =>
    `${core_backend_url}event/${eventId}/listings`,
  add_to_cart: `${core_backend_url}cart`,
  get_cart: `${core_backend_url}cart`,
  remove_from_cart: `${core_backend_url}cart/remove`,
  remove_cart_item: `${core_backend_url}cart/removeCartItem`,
  get_cart_total: `${core_backend_url}cart/total`,
  confirm_delivery_address: `${core_backend_url}cart/confirm-delivery-address`, //XD_J
  create_payment_intent: `${core_backend_url}payment/create-intent`,
  create_setup_intent: `${core_backend_url}payment/create-setup-intent`,
  confirm_setup_intent: `${core_backend_url}payment/confirm-setup-intent`,
  get_payment_method: `${core_backend_url}payment/payment-methods`,
  checkTicketAlert : `${core_backend_url}listing/checkAlertTicket`,
  alertSummary : `${core_backend_url}listing/totalCountTicketAlerts`,
  confirm_payment: (orderId) =>
    `${core_backend_url}payment/confirm-payment/${orderId}`,
  get_user_orders: `${core_backend_url}order`,
  get_user_payouts: `${core_backend_url}payout/user`,
  get_user_sales: `${core_backend_url}sale`,
  get_pending_tbl: `${core_backend_url}payout/pendingPayout`,
  get_inprogress_tbl: `${core_backend_url}payout/inProgressPayout`,
  get_failed_tbl: `${core_backend_url}payout/failedPayout`,

  orderStatusSave: (listingId) => `${core_backend_url}payout/complaint/${listingId}`,


  get_paidPayout_tbl: `${core_backend_url}payout/paidPayout`,
  confirm_delivery: (orderItemId, role) =>
    `${core_backend_url}sale/${orderItemId}/confirm-delivery/${role}`,
  confirm_delivery_by_admin: (orderItemId, role) =>
    `${core_backend_url}sale/${orderItemId}/confirm-delivery`,
  create_connected_account: `${core_backend_url}payment/create-connected-stripe-account`,
  check_if_user_has_account: `${core_backend_url}payment/check-user-has-connected-stripe-account`,
  get_listing_price_range: (eventId) =>
    `${core_backend_url}listing/event/${eventId}/price-range`,
  request_refund: `${core_backend_url}order/refund/request`,
  payout_summary: `${core_backend_url}payout/summary`,
  payoutPayment_summary: `${core_backend_url}payout/payoutSummary`,
  apply_promoCode: `${core_backend_url}promoCode/applyPromoCode`,
  save_promoCode: `${core_backend_url}promoCode/savePromoCode`,
  get_ticket_alerts: `${core_backend_url}listing/listTicketAlerts`,
  get_closed_ticket_alerts: `${core_backend_url}listing/listClosedTicketAlerts`,
  get_matching_ticket_alerts: `${core_backend_url}listing/listMatchingTicketAlerts`,
  get_expired_ticket_alerts: `${core_backend_url}listing/listExpiredTicketAlerts`,
  list_ticket_alerts: `${core_backend_url}listing/getTicketAlerts`,
  ticket_alert: `${core_backend_url}listing/ticket-alert`,
  set_ticket_alert: `${core_backend_url}listing/setAlert`,
  total_ticket_alert: `${core_backend_url}listing/totalTicketAlertsCount`,
  check_ticket_alert: `${core_backend_url}listing/checkAlert`,
  update_ticket_alert: (alert_id) => `${core_backend_url}listing/ticket-alert/${alert_id}`,

}
