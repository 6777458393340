import moment from 'moment'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, onAddPromocode, setAdminErrorMessageUser, setAdminLoadingUser, showAddEventFormFunc } from '../../../../config/redux/slice/adminSlice'
import { convertToDateTime, truncateMiddle } from '../../../../helpers/helpers'
import { post_put_request_promoCode } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import AddFormLayout from '../../../layout/admin/AddFormLayout'
import { v4 as isUUID } from 'uuid';  // You can use a UUID validation utility
import AddPromoCodeType from './addPromoCodeType'

const formatTime = (time) => {
  const [hours, minutes] = time.split(':').map(Number);
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;
};
const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      slots.push(`${formattedHour}:${formattedMinute}`);
    }
  }
  return slots;
};

export default function AddEvent({ types, allPromoCodeTypes, usertypes, activeList, allEvents }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const [addTrending, setAddTrending] = useState(false); // State for checkbox
  const [selectedTime, setSelectedTime] = useState('00:00');
  const timeSlots = generateTimeSlots();
  const handleClosePromoCodeType = () => setShowPromoCodeType(false);
  const [showPromoCodeType, setShowPromoCodeType] = useState(false);

  const [startDate, setStartDate] = useState("");
  const handleChange = (event) => {
    console.log('event', event.target.value);
    setSelectedTime(event.target.value);
  };

  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)



  const closeForm = () => {
    dispatch(showAddEventFormFunc({ modal: false }))
  }

  const onSubmit = async (data) => {
    console.log('formData1111', data);
    // Ensure UUID is valid
    let promoCodeTypeId = String(data.promoCodeTypeId).trim();
    if (!promoCodeTypeId) {
      console.error("promoCodeTypeId is empty after trimming");
      return;
    }

    if (!isUUID(promoCodeTypeId)) {
      console.error("Invalid UUID for promoCodeTypeId");
      return;
    }
    dispatch(clearAll({}));
    const convertToDateTime = (date, time = "00:00") => {
      return new Date(`${date}T${time}`).toISOString();
    };
    let dateTime = null;
    let endDateTime = null;
    if (data?.startDate) {
      dateTime = convertToDateTime(data.startDate, "00:00");
    }
    if (data?.endDate) {
      endDateTime = convertToDateTime(data.endDate, "23:59");
    }
   
    const requestData = {
      promoCodeTypeId,
      code: String(data.code).trim(),
      Description: data.Description || "",
      startDate: dateTime,
      endDate: endDateTime,
      percentage: parseFloat(data.percentage),
      isActive: true,
      eventId: data.eventId
    };
    try {
      console.log('res', requestData);
      const res = await post_put_request_promoCode(
        "post",
        token,
        `${app_api.add_promocode}`,
        JSON.stringify(requestData),
        dispatch,
        setAdminLoadingUser,
        onAddPromocode,
        setAdminErrorMessageUser,
        true
      );
      console.log("API response:", res);
      if (res && res.status < 400) {
        reset();
        resetFileInp();
        activeList()
         setFormData(new FormData());
      } else {
        // alert(res.message)
       setFormData(new FormData());
      }
    } catch (error) {
      console.error("API request failed", error);

    }
  };

  function resetFileInp() {
    document.querySelectorAll('.custom-file-label').forEach(() => {
      resetFileInp.innerHTML = 'Choose file...'
    })
  }
  const openPromoCodeType = () => {
    setShowPromoCodeType(true); // Open the modal
  };

  return (
    <AddFormLayout onClick={closeForm} heading_title='Add New PromoCode' sub_text='Fill in the fields below'>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="px-4 px-md-7">
          <div style={{ rowGap: '1.2rem' }} className="row mb-2">
            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='promoCodeTypeId' style={{marginRight:"20px"}}>PromoCode Type <span className="text-danger">*</span></label>
                  <a href="#" onClick={openPromoCodeType}>Add PromoCode Type</a>
                <select
                  className="form-control"
                  id="promoCodeTypeId"
                  {...register("promoCodeTypeId", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                >
                  <option value={''}>Select type</option>
                  {types.map((type, indx) => {
                    return <React.Fragment key={indx}>
                      {type.name.toLowerCase() &&
                        <option className='text-capitalize' key={indx} value={type.id}>{type.name}</option>
                      }
                    </React.Fragment>
                  })}
                </select>
                {
                  errors?.promoCodeTypeId?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.promoCodeTypeId?.message}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='code'>Code <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="code"
                  {...register("code", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.code?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.code?.message}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='percentage'>Percentage <span className="text-danger">*</span></label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="percentage"
                  {...register("percentage", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                    validate: {
                      isPositive: (value) =>
                        parseFloat(value) > 0 || 'Percentage must be greater than 0',
                    },
                  })
                  }
                />
                {
                  errors?.percentage?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.percentage?.message}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='description'>Description</label>
                <input
                  type="text"
                  className="form-control input-lg"
                  id="Description"
                  {...register("Description", {

                  })
                  }
                />
                {
                  errors?.description?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.description?.message}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='startdate'>Start Date<span className="text-danger">*</span></label>
                <input
                  type="date"
                  min={moment().format('YYYY-MM-DD')}
                  className="form-control input-lg"
                  style={{ cursor: "pointer" }}
                  // onFocus={(e) => e.currentTarget.showPicker()}
                  id="startDate"
                  {...register("startDate", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    },
                    onChange: (e) => setStartDate(e.target.value),
                  })
                  }
                />
                {
                  errors?.startDate?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.startDate?.message}
                  </div>
                }
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="form-group">
                <label htmlFor='enddate'>End Date<span className="text-danger">*</span></label>
                <input
                  type="date"
                  min={startDate || moment().format("YYYY-MM-DD")}
                  className="form-control input-lg"
                  style={{ cursor: "pointer" }}
                  // onFocus={(e) => e.currentTarget.showPicker()}
                  id="endDate"
                  {...register("endDate", {
                    required: {
                      value: true,
                      message: '*this field is required'
                    }
                  })
                  }
                />
                {
                  errors?.endDate?.message &&
                  <div className="text-danger small mt-1">
                    {errors?.endDate?.message}
                  </div>
                }
              </div>
            </div>

            <div className='col-md-6 col-lg-4'>
              <div className="form-group">
                <label htmlFor='eventId'>Event</label>
                <select
                  className="form-control"
                  id="eventId"
                  {...register("eventId", {
                   
                  })}
                >
                  <option value=''>Select event</option>
                  {allEvents.map((event) => (
                    <option key={event.id} value={event.id}>
                      {event.name}
                    </option>
                  ))}
                </select>
               </div>
            </div>
          </div>
        </div>
        <div className="mt-4 px-4 d-flex align-items-center">
          <div>
            {adminLoading ?
              <Spinner isForm={true} /> :
              <button type="submit" className="btn btn-primary me-3">Submit Form</button>
            }
          </div>
          <button disabled={adminLoading ? true : false} type="button" className="btn btn-info" onClick={closeForm}>Cancel</button>
        </div>
              <AddPromoCodeType show={showPromoCodeType} allPromoCodeTypes={allPromoCodeTypes}handleClose={handleClosePromoCodeType} promoCodeType={types} />

      </form>

    </AddFormLayout>
  )
}
