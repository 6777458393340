import Pagination from "react-js-pagination";
import React, { memo, useEffect, useState } from 'react';
import { clearAll, onUpdate, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns';
import { strText } from '../../../../constants/english'
import { formatNumber } from '../../../../helpers/helpers'
import moment from 'moment'
import AddComment from "./addComment";
import CurrentstatusDescription from "./currentStatus_Description";
import { app_api } from '../../../../utils/endpoints/app_api'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import HistoryTrackPopup from "./HistoryTrackPopup";
import OtherStatusConfirm from "./OtherStatusConfirm";

export default function OnHold() {
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [all_orders, setAll_orders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showComplaintPopup, setShowComplaintPopup] = useState(false);
    const [boldRow, setBoldRow] = useState(null);
    const [actionType, setActionType] = useState('');
    const [showPopup, setShowPopup] = useState(false); // To control popup visibility
    const [historyPopup, setHistoryPopup] = useState(false); // To control popup visibility
    const { userData, token } = useSelector((state) => state.authData)
    const [dropDownValue, setDropDownValue] = useState([]);
    const [orderStatusId, setOrderStatusId] = useState(null);
    const [amountOrderStatusId, setAmountOrderStatusId] = useState(null);
    const [changedPrice, setChangedPrice] = useState({});
    const [orderId, setOrderId] = useState();
    const [showOtherPopup, setShowOtherPopup] = useState(false); // To control popup visibility
    const [orderStatusData, setOrderStatusData] = useState({});

    const dispatch = useDispatch()

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        getPendingList();
        dropDownList();
    }, [])

    const dropDownList = async () => {
        //setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_statusPayout}/allStatus`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let dropdownData = res?.data?.data || [];
            setDropDownValue(dropdownData);
            const amountChangedStatus = dropdownData.find(item => item.name === "AMOUNT CHANGED");
            if (amountChangedStatus) {
                setAmountOrderStatusId(amountChangedStatus.id);
            } else {
                console.error("AMOUNT CHANGED status not found");
            }

        }
        //setLoading(false);
    }

    const handleStatusClick = (orderId) => {
        setOrderId(orderId)
        setShowPopup(true);
    };

    const handleHistoryTrack = (orderId) => {
        setOrderId(orderId)
        setHistoryPopup(true);
    };

    const getPendingList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getOnHoldList}/onhold?page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }

    const handleSearchChange = async (event) => {

        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10;

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getOnHoldList}/onhold?search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=${searchTerm}&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }

        setLoading(false);
        setActivePage(1);
    }

    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        const pageSize = 10;

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.getOnHoldList}/onhold?page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            setAll_orders(pending_list);
            setRecordsTotal(totalCount)
        }

        setLoading(false);
        setActivePage(pageNumber);
    };

    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };

    const handleSaveStatus = async (statusData, orderId) => {
        setShowOtherPopup(true);
        setSelectedOrder(orderId); // Set the order ID for confirmation
        setOrderStatusData(statusData); // Set the status ID for confirmation
    };

    // const handleSaveStatus = async (statusData, orderId) => {
    //     try {
    //         // If the action type is ON HOLD or COMPLAINT, prompt for a comment
    //         if (statusData.name === "ON HOLD" || statusData.name === "REGISTER COMPLAINT") {
    //             const comment = prompt("Please provide a comment:");

    //             if (!comment) {
    //                 alert("Comment is required for this action.");
    //                 return; // Exit if no comment is provided
    //             }
    //         }
    //         let updateData = {
    //             statusId: statusData.id,
    //             comment: null,
    //         };

    //         const res = await post_put_patch_request(
    //             "post",
    //             token,
    //             `${app_api.orderStatus(orderId)}`,
    //             updateData,
    //             dispatch,
    //             setCustomerLoading,
    //             "",
    //             setCustomerErrorMessage
    //         );

    //         if (res !== undefined && res.status < 400) {
    //             dispatch(onUpdate({ message: res?.data?.message, type: '' }))

    //         }
    //         getPendingList()
    //     } catch (error) {
    //         console.error("Error in saving status:", error);
    //     }
    // };

    const handlePriceChange = (e, orderId) => {
        // setChangedPrice(e.target.value); // Update the changed price
        const { value } = e.target;
        setChangedPrice((prev) => ({
            ...prev,
            [orderId]: value, // Set the value directly, even if it's empty
        }));
        setOrderId(orderId)
    };

    return (

        <>
            <div className="form-group search-input-top">
                <input autoComplete='off' type="text" className="form-control" id="" placeholder="Search..." onChange={handleInputChange} />
                <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleSearchChange}>Search</button>
            </div>

            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Event Date</th>
                            <th scope="col" className='no-wrap'>Amount Due</th>
                            {/* <th scope="col" className='no-wrap'>Payout Status</th> */}
                            <th scope="col" className='no-wrap'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : all_orders.length > 0 ? (
                            all_orders.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{item?.name}</td>
                                        <td>{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                        <td>{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>

                                        </td>
                                    </tr>

                                    {(expandedRows[index] && item?.detailedPayouts?.data?.length > 0 && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th className='no-wrap'>Seller Name</th>
                                                            <th className='no-wrap'>Seller Email</th>
                                                            <th className='no-wrap'>Seller Phone</th>
                                                            <th className='no-wrap'>Amount Due</th>
                                                            <th className='no-wrap'>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {item?.detailedPayouts.data.map((items, sellerIndex) => (
                                                        <tbody>
                                                            <tr key={index}>
                                                                <td>{items.user.name}</td>
                                                                <td>{items.user.email}</td>
                                                                <td>{items.user.phone}</td>
                                                                <td>{items?.dueAmount ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}` : 'N/A'}</td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <div className="">
                                                                            <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, sellerIndex, event)}>
                                                                                {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                                                ) : (
                                                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                                                )}
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            {nestedExpandedRows[index] && (
                                                                <tr>
                                                                    <td colSpan="7">
                                                                        <table className="table table-bordered tbl-subinner-width">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th className='no-wrap'>Order Id/Sale Date</th>
                                                                                    <th className='no-wrap'>Purchase Details</th>
                                                                                    <th className='no-wrap'>Total Amount</th>
                                                                                    <th className='no-wrap'>PromoCode Discount</th>
                                                                                    <th className='no-wrap'>Final Amount</th>
                                                                                    <th className='no-wrap'>Order Status</th>
                                                                                    <th className='no-wrap'>Action</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {items.orders.map((orderItem, itemIndex) => {
                                                                                    const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;  // Unique key for each dropdown

                                                                                    return (
                                                                                        <tr key={itemIndex}>
                                                                                            <td className={boldRow === dropdownKey ? 'fontWeight' : ""}>
                                                                                                {orderItem?.orderRef}<br />
                                                                                                {orderItem?.orderDate ? (format(new Date(orderItem?.orderDate), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}
                                                                                            </td>
                                                                                            <td>

                                                                                                {orderItem?.orderItems.length + ' Qty x '}
                                                                                                {orderItem?.orderItems[0].price

                                                                                                    ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}`
                                                                                                    : '0'}

                                                                                            </td>
                                                                                            <td>{orderItem?.price ? `${strText.currency_symbol}${formatNumber(orderItem?.price)}` : 'N/A'}</td>
                                                                                            <td>{orderItem?.promoCodeDiscount ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}` : '0.00'}</td>
                                                                                            <td>
                                                                                                <div class="input-group-prepend d-flex align-items-center">
                                                                                                    <span class="">{strText.currency_symbol} </span>
                                                                                                    <input class="form-control input-price" type="text"
                                                                                                        value={changedPrice[orderItem?.orderId] ?? orderItem?.price ?? ""} // Fallback to original value or empty string
                                                                                                        onChange={(e) => handlePriceChange(e, orderItem?.orderId)}
                                                                                                    />
                                                                                                    <a onClick={() => handleStatusClick(orderItem.orderId)} title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery btn-m-right">
                                                                                                        <i class="fa fa-check"></i>

                                                                                                    </a>

                                                                                                </div>
                                                                                                <div>
                                                                                                    <span
                                                                                                        className='delivery-add-link'
                                                                                                        onClick={() => handleStatusClick(orderItem.orderId)} // Pass the order ID to toggle
                                                                                                    >
                                                                                                        Comment
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>{orderItem?.orderStatusName}
                                                                                                <div>
                                                                                                    <span
                                                                                                        className='delivery-add-link'
                                                                                                        onClick={() => handleHistoryTrack(orderItem.orderId)} // Pass the order ID to toggle
                                                                                                    >
                                                                                                        Track History
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                {(() => {
                                                                                                    const preStatusType = item?.statusType;

                                                                                                    return (
                                                                                                        <div>
                                                                                                            <select
                                                                                                                className="btn btn-sm select-setuserType btn-icon change-status-type"
                                                                                                                defaultValue={preStatusType}
                                                                                                                onChange={(e) => {
                                                                                                                    const selectedActionId = e.target.value;

                                                                                                                    // Find the selected action from the dropDownValue array
                                                                                                                    const selectedAction = dropDownValue.find((type) => type.id === selectedActionId);

                                                                                                                    if (selectedAction) {

                                                                                                                        if (selectedAction.name === "ON HOLD" || selectedAction.name === "REGISTER COMPLAINT") {
                                                                                                                            setSelectedOrder(orderItem.orderId)
                                                                                                                            setOrderStatusId(selectedAction.id)
                                                                                                                            setActionType(selectedAction.name); // Set the action type
                                                                                                                            setShowComplaintPopup(true); // Show the popup
                                                                                                                        } else {
                                                                                                                            handleSaveStatus(selectedAction, orderItem.orderId);
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}
                                                                                                            >
                                                                                                                <option value="">Select Status</option>
                                                                                                                {dropDownValue.filter(
                                                                                                                    (type) =>
                                                                                                                        // For SUPER_ADMIN role, exclude 'AMOUNT CHANGED' and 'DELIVERED'
                                                                                                                        (userData.user_role === "SUPER_ADMIN" &&
                                                                                                                            type.name !== "AMOUNT CHANGED" &&
                                                                                                                            type.name !== "REFUND SUCCESSFUL" &&
                                                                                                                            type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                                            type.name !== "PENDING" &&
                                                                                                                            type.name !== "DELIVERED" &&
                                                                                                                            type.name !== "ON HOLD" &&
                                                                                                                            type.name !== "REGISTER COMPLAINT" &&
                                                                                                                            type.name !== "REFUND REQUEST") ||
                                                                                                                        // For CUSTOMER_SUPPORT role, exclude 'AMOUNT CHANGED', 'PENDING', 'DELIVERED', and 'REFUND APPROVED'
                                                                                                                        (userData.user_role === "CUSTOMER_SUPPORT" &&
                                                                                                                            type.name !== "AMOUNT CHANGED" &&
                                                                                                                            type.name !== "REFUND SUCCESSFUL" &&
                                                                                                                            type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                                            type.name !== "PENDING" &&
                                                                                                                            type.name !== "ON HOLD" &&
                                                                                                                            type.name !== "REGISTER COMPLAINT" &&
                                                                                                                            type.name !== "DELIVERED" &&
                                                                                                                            type.name !== "REFUND REQUEST") ||
                                                                                                                        // For all other roles (excluding SUPER_ADMIN and CUSTOMER_SUPPORT), exclude 'AMOUNT CHANGED', 'DELIVERED', and 'REFUND APPROVED'
                                                                                                                        (userData.user_role !== "SUPER_ADMIN" &&
                                                                                                                            userData.user_role !== "CUSTOMER_SUPPORT" &&
                                                                                                                            type.name !== "AMOUNT CHANGED" &&
                                                                                                                            type.name !== "REFUND SUCCESSFUL" &&
                                                                                                                            type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                                            type.name !== "DELIVERED" &&
                                                                                                                            type.name !== "PENDING" &&
                                                                                                                            type.name !== "ON HOLD" &&
                                                                                                                            type.name !== "REGISTER COMPLAINT" &&
                                                                                                                            type.name !== "REFUND REQUEST")
                                                                                                                ).map((type) => (
                                                                                                                    <option key={type.id} value={type.id}>
                                                                                                                        {type.displayName}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    );

                                                                                                })()}
                                                                                            </td>

                                                                                        </tr>
                                                                                    );
                                                                                })}

                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            )}

                                                        </tbody>))}
                                                </table>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : all_orders.length > 0 ? (
                    all_orders.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.name}>{item?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Amount Due</h3>
                                        <div className="mob-tbl-value">{item?.amount?.dueAmount ? `${strText.currency_symbol}${formatNumber(item?.amount?.dueAmount)}` : 'N/A'}</div>
                                    </div>

                                    <div className="mob-table-sec-expand">
                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                    </div>
                                </div>

                                {(expandedRows[index] && item?.detailedPayouts?.data?.length > 0 && (
                                    <>
                                        {item?.detailedPayouts.data.map((items, sellerIndex) => (
                                            <div className="mob-tbl-inner-sec" key={index}>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Seller Name</h3>
                                                    <div className={`mob-tbl-value`}>{items.user.name}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Seller Email</h3>
                                                    <div className="mob-tbl-value">{items.user.email}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Seller Phone</h3>
                                                    <div className="mob-tbl-value">{items.user.phone}</div>
                                                </div>

                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Amount Due</h3>
                                                    <div className="mob-tbl-value ">{items?.dueAmount ? `${strText.currency_symbol}${formatNumber(items?.dueAmount)}` : 'N/A'}</div>
                                                </div>

                                                <div className="mob-table-sec-action">
                                                    <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, sellerIndex, event)}>
                                                        {nestedExpandedRows[index]?.[sellerIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>

                                                </div>

                                                {nestedExpandedRows[index] && (
                                                    <>
                                                        {items.orders.map((orderItem, itemIndex) => {
                                                            const dropdownKey = `${index}-${sellerIndex}-${itemIndex}`;
                                                            return (

                                                                <div className="mob-tb-subinner" key={itemIndex}>

                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Order Id/Sale Date</h3>
                                                                        <div className={`mob-tbl-value ${boldRow === dropdownKey ? 'fontWeight' : ''}`}>{orderItem?.orderRef}<br />
                                                                            {orderItem?.deliveryDate ? (format(new Date(orderItem?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Purchase Details</h3>
                                                                        <div className="mob-tbl-value">
                                                                            {orderItem?.numberOfTickets + ' Qty x '}
                                                                            {orderItem?.ticketPrice
                                                                                ? `${strText.currency_symbol}${formatNumber(orderItem.ticketPrice)}`
                                                                                : '0'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Total Amount</h3>
                                                                        <div className="mob-tbl-value">{orderItem?.orderItems[0].price ? `${strText.currency_symbol}${formatNumber(orderItem?.orderItems[0].price)}` : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">PromoCode</h3>
                                                                        <div className="mob-tbl-value">{orderItem?.promoCodeDiscount ? `${strText.currency_symbol}${formatNumber(orderItem?.promoCodeDiscount)}` : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Final Discount</h3>
                                                                        <div className="mob-tbl-value">{orderItem?.totalAmount ? `${strText.currency_symbol}${formatNumber(orderItem?.totalAmount)}` : 'N/A'}</div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Final Amount</h3>
                                                                        <div className="mob-tbl-value">
                                                                            <div class="input-group-prepend d-flex align-items-center">
                                                                                <span class="">{strText.currency_symbol} </span>
                                                                                <input class="form-control input-price" type="text"
                                                                                    value={changedPrice[orderItem?.orderId] ?? orderItem?.price ?? ""} // Fallback to original value or empty string
                                                                                    onChange={(e) => handlePriceChange(e, orderItem?.orderId)}
                                                                                />
                                                                                <a onClick={() => handleStatusClick(orderItem.orderId)} title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery btn-m-right">
                                                                                    <i class="fa fa-check"></i>

                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <span
                                                                                    className='delivery-add-link'
                                                                                    onClick={() => handleStatusClick(orderItem.orderId)} // Pass the order ID to toggle
                                                                                >
                                                                                    Comment
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Order Status</h3>
                                                                        <div className="mob-tbl-value">{orderItem?.orderStatusName}
                                                                            <div>
                                                                                <span
                                                                                    className='delivery-add-link'
                                                                                    onClick={() => handleHistoryTrack(orderItem.orderId)} // Pass the order ID to toggle
                                                                                >
                                                                                    Track History
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="mob-table-sec">
                                                                        <h3 className="mob-tbl-head">Action</h3>
                                                                        {(() => {
                                                                            const preStatusType = item?.statusType;

                                                                            return (
                                                                                <div>
                                                                                    <select
                                                                                        className="btn btn-sm select-setuserType btn-icon change-status-type"
                                                                                        defaultValue={preStatusType}
                                                                                        onChange={(e) => {
                                                                                            const selectedActionId = e.target.value;

                                                                                            // Find the selected action from the dropDownValue array
                                                                                            const selectedAction = dropDownValue.find((type) => type.id === selectedActionId);

                                                                                            if (selectedAction) {

                                                                                                if (selectedAction.name === "ON HOLD" || selectedAction.name === "REGISTER COMPLAINT") {
                                                                                                    setSelectedOrder(orderItem.orderId)
                                                                                                    setOrderStatusId(selectedAction.id)
                                                                                                    setActionType(selectedAction.name); // Set the action type
                                                                                                    setShowComplaintPopup(true); // Show the popup
                                                                                                } else {
                                                                                                    handleSaveStatus(selectedAction, orderItem.orderId);
                                                                                                }
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <option value="">Select Status</option>
                                                                                        {dropDownValue.filter(
                                                                                            (type) =>
                                                                                                (userData.user_role === "SUPER_ADMIN" &&
                                                                                                    type.name !== "AMOUNT CHANGED" &&
                                                                                                    type.name !== "REFUND SUCCESSFUL" &&
                                                                                                    type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                    type.name !== "PENDING" &&
                                                                                                    type.name !== "DELIVERED" &&
                                                                                                    type.name !== "ON HOLD" &&
                                                                                                    type.name !== "REGISTER COMPLAINT" &&
                                                                                                    type.name !== "REFUND REQUEST") ||
                                                                                                // For CUSTOMER_SUPPORT role, exclude 'AMOUNT CHANGED', 'PENDING', 'DELIVERED', and 'REFUND APPROVED'
                                                                                                (userData.user_role === "CUSTOMER_SUPPORT" &&
                                                                                                    type.name !== "AMOUNT CHANGED" &&
                                                                                                    type.name !== "REFUND SUCCESSFUL" &&
                                                                                                    type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                    type.name !== "PENDING" &&
                                                                                                    type.name !== "ON HOLD" &&
                                                                                                    type.name !== "REGISTER COMPLAINT" &&
                                                                                                    type.name !== "DELIVERED" &&
                                                                                                    type.name !== "REFUND REQUEST") ||
                                                                                                // For all other roles (excluding SUPER_ADMIN and CUSTOMER_SUPPORT), exclude 'AMOUNT CHANGED', 'DELIVERED', and 'REFUND APPROVED'
                                                                                                (userData.user_role !== "SUPER_ADMIN" &&
                                                                                                    userData.user_role !== "CUSTOMER_SUPPORT" &&
                                                                                                    type.name !== "AMOUNT CHANGED" &&
                                                                                                    type.name !== "REFUND SUCCESSFUL" &&
                                                                                                    type.name !== "PAYOUT PAID" &&
                                                                                                                            type.name !== "PAYOUT FAILED" &&
                                                                                                                            type.name !== "PAYOUT PENDING" &&
                                                                                                    type.name !== "DELIVERED" &&
                                                                                                    type.name !== "PENDING" &&
                                                                                                    type.name !== "ON HOLD" &&
                                                                                                    type.name !== "REGISTER COMPLAINT" &&
                                                                                                    type.name !== "REFUND REQUEST")
                                                                                        ).map((type) => (
                                                                                            <option key={type.id} value={type.id}>
                                                                                                {type.displayName}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>

                                                                                </div>
                                                                            );

                                                                        })()}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </>))}
                            </div>
                        </>
                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}
            </div>

            {showOtherPopup && (
                <OtherStatusConfirm
                    setShowOtherPopup={setShowOtherPopup}
                    showOtherPopup={showOtherPopup}
                    statusData={orderStatusData} // Pass selectedAction
                    orderId={selectedOrder} // Pass orderId
                    getPendingList={getPendingList}
                />
            )}

            {historyPopup && (
                <HistoryTrackPopup
                    setHistoryPopup={setHistoryPopup}
                    historyPopup={historyPopup}
                    orderId={orderId}
                />
            )}

            {showPopup && (
                <CurrentstatusDescription
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                    orderId={orderId}
                    changedPrice={changedPrice[orderId]}
                    setOrderStatusId={amountOrderStatusId}
                />
            )}
            {showComplaintPopup && (
                <AddComment
                    setShowComplaintPopup={setShowComplaintPopup}
                    showComplaintPopup={showComplaintPopup}
                    selectedItem={selectedOrder}
                    actionType={actionType} // Pass the selected action type
                    setOrderStatusId={orderStatusId}
                    getPendingList={getPendingList}
                />
            )}
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}