import React from 'react'

export default function AuthAlert({ children, alertStyles, status, onClick }) {
  return (
    <div style={{ ...alertStyles }}>
      {status === 'error' ? (
        <div className="text-capitalize alert alert-danger py-2 margin-sec" role="alert">
          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'space-between',
            }}
            className="inner"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.6rem',
                fontSize: '0.8rem',
              }}
            >
              <i
                style={{ fontSize: '0.9rem' }}
                className="mdi mdi-close-circle-outline"
              ></i>
              {children}
            </div>
            <button
              type="button"
              onClick={onClick}
              style={{
                backgroundColor: 'transparent',
                fontSize: '0.9rem',
                color: '#65666d',
                height: 'fit-content',
                width: 'fit-content',
                outline: 'none',
                border: 'none',
              }}
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="text-capitalize alert alert-success py-2" role="alert">
          <div
            style={{
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'space-between',
            }}
            className="inner"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.6rem',
                fontSize: '0.8rem',
              }}
            >
              <i
                style={{ fontSize: '0.9rem' }}
                className="mdi mdi-checkbox-marked-circle-outline"
              ></i>
              {children}
            </div>
            <button
              type="button"
              onClick={onClick}
              style={{
                backgroundColor: 'transparent',
                fontSize: '0.9rem',
                color: '#65666d',
                height: 'fit-content',
                width: 'fit-content',
                outline: 'none',
                border: 'none',
              }}
            >
              <i className="mdi mdi-close"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
