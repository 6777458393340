import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner'
import {
  clearAll,
  setCustomerErrorMessage,
  setCustomerLoading,
} from '../../../../config/redux/slice/customerSlice'
import {
  get_delete_request,
  post_put_patch_request,
} from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import { showPhoneNumberVerificationModalFunc } from '../../../../config/redux/slice/authSlice'

export default function ProfileView() {
  const dispatch = useDispatch()
  const { userData, token } = useSelector((state) => state.authData)

  const { customerLoading } = useSelector((state) => state.customer)

  const sendVerificationCode = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.send_number_verification}`,
      {},
      dispatch,
      setCustomerLoading,
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      dispatch(
        showPhoneNumberVerificationModalFunc({ modal: true, type: 'verify' })
      )
    }
  }

  return (
    <ul className="list-group rounded-0">
      <li className="list-group-item border-start-0">
        <span className="fw-bold">Name: </span>
        <span>{userData?.fullname}</span>
      </li>
      <li className="list-group-item border-start-0">
        <span className="fw-bold">Email Address: </span>
        <span>{userData?.email}</span>
      </li>
      <li
        style={{ columnGap: '0.8rem' }}
        className="d-flex flex-wrap align-items-center list-group-item border-start-0"
      >
        <span>
          <span className="fw-bold">Phone: </span>
          <span>{userData?.phone ?? 'N/A'}</span>
        </span>
        <>
          {userData?.phoneVerifiedAt != null && (
            <span style={{ backgroundColor: '#ccc' }} className="badge">
              <i className={`mdi mdi-checkbox-marked-circle text-success`}></i>
              <span className="text-success">Verified</span>
            </span>
          )}
          {userData?.phone != null && userData?.phoneVerifiedAt == null && (
            <div>
              {customerLoading ? (
                <Spinner spinner_styles={{ width: '1rem', height: '1rem' }} />
              ) : (
                <button
                  onClick={sendVerificationCode}
                  className="btn btn-sm btn-accent"
                >
                  Verify Phone Number
                </button>
              )}
            </div>
          )}
        </>
      </li>
      <li className="list-group-item border-start-0">
        <span className="fw-bold">Address: </span>
        <span>{userData?.address ?? 'N/A'}</span>
      </li>
    </ul>
  )
}
