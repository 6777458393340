import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, onUpdate, setAdminErrorMessageUser,setCustomerLoading,setCustomerErrorMessage, setDetails, showAlertModalFunc, setAdminPromoCodeErrorMessageUser } from '../../../../config/redux/slice/customerSlice'
import { convertToDateTime, truncateMiddle } from '../../../../helpers/helpers'
import { post_put_patch_request, post_put_request_promoCode } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'
import SelectComponent from '../../../components/Form/SelectComponent'
import Spinner from '../../../components/Spinner'
import ModalLayout from '../../../layout/ModalLayout'
import { setAppLoading } from '../../../../config/redux/slice/appSlice'
import { strText } from '../../../../constants/english'
import { get_delete_request } from '../../../../helpers/request'


export default function EditAlerts({getList,alertObj, eventId, venueId}) {
  const dispatch = useDispatch()
  const { register, handleSubmit, watch, clearErrors, getValues, setError, setValue, formState: { errors }, reset } = useForm({ mode: 'onTouched' })
  const [formData, setFormData] = useState(new FormData());
  const [startDate, setStartDate] = useState("");
  const [selectedSection, setSelectedSection] = useState('')
  const [sectionsData, setSections] = useState([])
  const [subSections, setSubSections] = useState([])
  const {
    adminLoading,
    adminErrorMsg,
    adminSuccessMsg
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  useEffect(() => {
console.log('venueId', venueId)
console.log('eventId', eventId)
console.log('alertObj', alertObj)
  }, [])

  useEffect(() => {
    if (alertObj.sectionId) {
      getAllSUbSections(alertObj.sectionId); // Load subsections for the default sectionId
    }
  }, [alertObj.sectionId]);

  useEffect(() => {
    getAllSections()
  }, [venueId])

  useEffect(() => {
    if (selectedSection !== '') {
      getAllSUbSections()
    }
  }, [selectedSection])

  const getAllSections = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_sections(venueId)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      // dispatch(
      //   setEventValues({ propertyKey: 'sectionData', value: res.data.data })
      // )
      setSections(res.data.data)
    }
  }

  const getAllSUbSections = async (id) => {
    console.log('idddd', id);
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      '',
      `${app_api.get_subsections(id)}`,
      {},
      dispatch,
      '',
      '',
      ''
    )
    if (res !== undefined && res.status < 400) {
      setSubSections(res.data.data)
    }
  }

  const formattedDate = alertObj.expiryDate
    ? moment(alertObj.expiryDate).format("YYYY-MM-DD")
    : "";
    const {
      customerLoading,
      listingManagement: { singleListingDetails, listingDetails }
  } = useSelector((state) => state.customer)

  const onSubmit = async (data) => {
    dispatch(clearAll({}))
    const formatDateToISO = (date) => {
      if (!date) return null;
      const formattedDate = moment(date)
        .set({ hour: 18, minute: 0, second: 0, millisecond: 0 })
        .toISOString();
      return formattedDate;
    };
  
    // Convert expiryDate to the required format
    const formattedExpiryDate = formatDateToISO(data.expiryDate);
    const preprocessField = (value) => (value === "" ? null : value);

    data = {
      ...data,
      expiryDate: formattedExpiryDate,
      eventId: eventId,
      price: Number(data.price),
      numberOfTickets: Number(data.numberOfTickets),
      sectionId: preprocessField(data.sectionId),
    subSectionId: preprocessField(data.subSectionId),
    };

    console.log('Formatted formData:', data);
    const res = await post_put_request_promoCode(
      "patch",
      token,
      `${app_api.update_ticket_alert(alertObj?.id)}`,
      data,
      dispatch,
      setCustomerLoading,
      "",
      setCustomerErrorMessage,
    )
    if (res !== undefined && res.status < 400) {
      getList()
      dispatch(onUpdate({ message: res.data.message, type: 'alert' }))
      closeModal ()
      
      // checkAlert(res);
     
    } 
  }

  const checkAlert = async (data) => {
    console.log('data', data);
    dispatch(clearAll({}))
    const body = {
      eventId: eventId,
      price: data.pricePerTicket,
      numberOfTickets: data.numberOfTickets,
      sectionId: data.sectionId,
      subSectionId: data.subSectionId,
      sellingOption: data.sellingOption,
      ticketType: data.ticketsType

    }
    const res = await post_put_patch_request(
      'post',
      token,
      `${app_api.set_ticket_alert}`,
      body,
      dispatch,
      setCustomerLoading,
      '',
      setCustomerErrorMessage
    );
   
  }


  const closeModal = () => {
    dispatch(showAlertModalFunc({ modal: false, type: null }))
  }

  return (
    <ModalLayout classStyles={'modal-lg'} onClick={closeModal} heading={'Edit Alerts'}>
       <form onSubmit={handleSubmit(onSubmit)} encType='multipart/form-data' noValidate>
          <div className="modal-body">
            <div className="px-4 px-md-7">
              <div style={{ rowGap: '1.2rem' }} className="row mb-2">

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='description'>No of Tickets<span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      defaultValue={alertObj.numberOfTickets}
                      id="numberOfTickets"
                      {...register("numberOfTickets", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />
                    {
                      errors?.numberOfTickets?.message &&
                      <div className="text-danger small mt-1">
                        {errors?.numberOfTickets?.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='description'>Price ({strText.currency_symbol}) <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      className="form-control input-lg"
                      id="price"
                      defaultValue={alertObj.price}
                      {...register("price", {
                        required: {
                          value: true,
                          message: '*this field is required'
                        }
                      })
                      }
                    />

                  </div>
                </div>

                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='ticketType'>Ticket Type</label>
                    <select
                      className="form-control input-lg"
                      id="ticketType"
                      defaultValue={alertObj.ticketType}
                      {...register("ticketType", {
                        required: {
                          value: true,
                          message: '*this field is required',
                        },
                      })}
                    >
                      <option value="ALL">ALL</option>
                      <option value="MOBILE">MOBILE</option>
                      <option value="PHYSICAL">PHYSICAL</option>
                      <option value="ELECTRONIC">ELECTRONIC</option>
                    </select>
                    {errors?.ticketType?.message && (
                      <div className="text-danger small mt-1">
                        {errors.ticketType.message}
                      </div>
                    )}
                  </div>
                </div>


                <div className="col-md-6 col-lg-6">
                  <div className="form-group">
                    <label htmlFor='startdate'>Expiry Date <span className="text-danger">*</span></label>
                    <input
                      type="date"
                      defaultValue={formattedDate}
                      min={moment().format('YYYY-MM-DD')}
                      style={{ cursor: "pointer" }}
                      onFocus={(e) => e.currentTarget.showPicker()}
                      className="form-control input-lg"
                      id="expiryDate"
                      {...register("expiryDate", {
                        onChange: (e) => setStartDate(e.target.value),
                      })
                      }
                    />
                    {
                      errors?.date?.message &&
                      <div className="text-danger small mt-1">
                        {errors?.date?.message}
                      </div>
                    }
                  </div>
                </div>

                {/* <div className="col-md-6 col-lg-6">
                  {sectionsData.length !== 0 && (
                    <div className="form-group mb-3">
                      <label htmlFor="sectionId" className="form-label">
                        Section
                      </label>
                      <select
                        className="form-select"
                        id="sectionId"
                        defaultValue={alertObj.sectionId}

                        {...register('sectionId', {
                          required: {
                            value: true,
                            message: '*this field is required',
                          },
                        })}
                        onChange={(e) => {
                          getAllSUbSections(e.target.value)
                        }}
                      >
                        <option value="">Select Section</option>
                        {sectionsData.map((section) => {
                          return (
                            <option key={section.id} value={section.id}>
                              {section.name}
                            </option>
                          )
                        })}
                      </select>
                      {errors?.sectionId?.message && (
                        <div className="text-danger p mt-1">
                          {errors?.sectionId?.message}
                        </div>
                      )}
                    </div>
                  )}
                </div>


                <div className="col-md-6 col-lg-6">
                  {subSections.length !== 0 && (
                    <div className="form-group mb-4">
                      <label htmlFor="subSectionId" className="form-label">
                        Block
                      </label>
                      <select
                        className="form-select"
                        id="subSectionId"
                        defaultValue={alertObj.subSectionId}

                        {...register('subSectionId')}
                      >
                        <option value="">N/A</option>
                        {subSections.map((subSection) => {
                          return (
                            <option key={subSection.id} value={subSection.id}>
                              {subSection.name}
                            </option>
                          )
                        })}
                      </select>
                      {errors?.subSectionId?.message && (
                        <div className="text-danger p mt-1">
                          {errors?.subSectionId?.message}
                        </div>
                      )}
                    </div>
                  )}
                </div> */}

<div className="col-md-6 col-lg-6">
        {sectionsData.length !== 0 && (
          <div className="form-group mb-3">
            <label htmlFor="sectionId" className="form-label">
              Section
            </label>
            <select
              className="form-select"
              id="sectionId"
              defaultValue={alertObj.sectionId}
              {...register('sectionId', {
                
              })}
              onChange={(e) => {
                getAllSUbSections(e.target.value); // Update subsections on change
              }}
            >
              <option value="ANY">ANY</option>
              {sectionsData.map((section) => (
                <option key={section.id} value={section.id}>
                  {section.name}
                </option>
              ))}
            </select>
            {errors?.sectionId?.message && (
              <div className="text-danger p mt-1">
                {errors?.sectionId?.message}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="col-md-6 col-lg-6">
        {subSections.length !== 0 && (
          <div className="form-group mb-4">
            <label htmlFor="subSectionId" className="form-label">
              Block
            </label>
            <select
              className="form-select"
              id="subSectionId"
              defaultValue={alertObj.subSectionId}
              {...register('subSectionId')}
            >
              <option value="">N/A</option>
              {subSections.map((subSection) => (
                <option key={subSection.id} value={subSection.id}>
                  {subSection.name}
                </option>
              ))}
            </select>
            {errors?.subSectionId?.message && (
              <div className="text-danger p mt-1">
                {errors?.subSectionId?.message}
              </div>
            )}
          </div>
        )}
      </div>

              </div>
            </div>
          </div>
          <div className="modal-footer border-top-0 d-flex align-items-center">
            <div>
              {customerLoading ?
                <Spinner isForm={true} /> :
                <button type="submit" className="btn btn-primary me-3">Submit Form</button>
              }
            </div>
            <button
            disabled={customerLoading}
            onClick={(e) => {
              e.preventDefault(); 
              closeModal();
            }}
            type="button"
            className="btn btn-info"
          >
            Cancel
          </button>          </div>
        </form>
    </ModalLayout>
  )
}
