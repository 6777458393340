import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { clearAll, clearResetBot, onGetProfileListings, onGetProfileSales, setCustomerErrorMessage, setCustomerLoading, setOrderValues, showOrderModalFunc } from '../../../../config/redux/slice/customerSlice'
import { setSalesValues, setDetails } from '../../../../config/redux/slice/adminSlice'
import { showSalesModalFunc } from '../../../../config/redux/slice/adminSlice'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import { Link, useNavigate } from 'react-router-dom'
import { get_delete_request } from '../../../../helpers/request'
import { formatNumber } from '../../../../helpers/helpers'
import { strText } from '../../../../constants/english'
// import ConfirmDelivery from './ConfirmDelivery'
import DeliveryAddress from './deliveryAdress'
import moment from 'moment'
import Pagination from "react-js-pagination";
import SellerDeatils from '../listing_management/SellerDetails';
import AddComplaint from './addComplaint';
import ViewComplaints from './viewComplaints';
// import RefundForm from './RefundForm';
import { format } from 'date-fns';
import ConfirmDelivery from './ConfirmDelivery';
export default function FailedTable() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { token } = useSelector((state) => state.authData)
    const [pending_data, setPending_data] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRows, setExpandedRows] = useState([]);
    const [refun, setRefun] = useState('');
    const [nestedExpandedRows, setNestedExpandedRows] = useState({});
    const [showDbPopup, setShowDbPopup] = useState(false)
    const [showComplaintPopup, setShowComplaintPopup] = useState(false)
    const [showViewComplaintPopup, setShowViewComplaintPopup] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedViewItem, setSelectedViewItem] = useState(null);
    const [showDbPopup1, setShowDbPopup1] = useState(false)
    const [selectedItem1, setSelectedItem1] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [recordsTotal, setRecordsTotal] = useState("");
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        getPendingList()
    }, [])


    const getPendingList = async () => {
        setLoading(true);
        dispatch(clearAll({}))
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_sales}?deliveryStatus=FAILED&page=1&pageSize=10&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        )
        console.log("🚀 ~ getPendingList ~ res:", res)
        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal
            console.log("🚀 ~ getPendingList ~ res:...................", pending_list)
            console.log("🚀 ~ getPendingList ~ res:...................", totalCount)
            setPending_data(pending_list);
            setRecordsTotal(totalCount)
        }
        setLoading(false);
    }

    // const toggleRow = (index) => {
    //     setExpandedRows(prevExpandedRows => ({
    //         ...prevExpandedRows,
    //         [index]: !prevExpandedRows[index]
    //     }));
    //     setNestedExpandedRows({});
    // };



    const confirmClick = (index, data) => {
        console.log("🚀 ~ confirmClick ~ data:", data)
        setItem(data, 'confirm')
    }
    const {
        salesManagement: { salesItemsSubData, showSalesModal: { show, type }, newData }
    } = useSelector((state) => state.admin)


    const toggleRow = (index, event) => {
        event.preventDefault();
        event.stopPropagation();
        setExpandedRows(prevExpandedRows => ({
            ...Object.fromEntries(Object.keys(prevExpandedRows).map(key => [key, false])), // Close all other rows
            [index]: !prevExpandedRows[index]
        }));
        setNestedExpandedRows({});
    };

    const toggleNestedRow = (mainIndex, nestedIndex, event) => {
        event.preventDefault();
        event.stopPropagation();
        setNestedExpandedRows(prevState => ({
            ...prevState,
            [mainIndex]: {
                ...prevState[mainIndex],
                [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
            }
        }));
    };



    // const toggleNestedRow = (mainIndex, nestedIndex) => {
    //     setNestedExpandedRows(prevState => ({
    //         ...prevState,
    //         [mainIndex]: {
    //             ...prevState[mainIndex],
    //             [nestedIndex]: !prevState[mainIndex]?.[nestedIndex]
    //         }
    //     }));
    // };

    const setItem = (data, type) => {
        dispatch(setDetails({ details: data, type: 'sales-items' }))
    dispatch(
            setSalesValues({ propertyKey: 'salesItemsData', value: data ?? [] })
          )
          if (type == 'confirm') {
            dispatch(showSalesModalFunc({ modal: true, type }))
        }
        console.log(setDetails());
    }
    const handleDeliveryaddressClick = (item) => {
        setShowDbPopup(true)
        setSelectedItem(item);
    }


    const handlePageChange = async (pageNumber) => {
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_sales}?deliveryStatus=DELIVERED&page=${pageNumber}&pageSize=${pageSize}&draw=1&q=&_=1719545988610`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handlePageChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handlePageChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handlePageChange ~ totalCount:...................", totalCount);

            setPending_data(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(pageNumber);
    };
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSellerDetailsClick = (item) => {
        setShowDbPopup1(true)
        setSelectedItem1(item);
    }
    const handleAddComplaintClick = (item) => {
        setShowComplaintPopup(true);
        setShowDbPopup(false);

        setSelectedItem(item);

    }
    const handleViewComplaintClick = async (item) => {
        const orderId = item.id;
        const baseURL = `${app_api.complaints}`;
        const endpoint = "/complaints";
        const url = `${baseURL}/${orderId}${endpoint}`;

        const res = await get_delete_request(
            'get',
            token,
            url,
            {},
            dispatch,
            "",
            "",
            "",
            true
        )
        console.log(res);
        if (res !== undefined && res.status < 400) {
            console.log('1111', res);

            setShowViewComplaintPopup(true);
            setShowDbPopup(false);

            setSelectedViewItem(res.data);
        
                setItem(res.data, 'show')
           
            
        };

    }
    

    const handleSearchChange = async (event) => {
        // const value = event.target.value;
        // setSearchTerm(value);
        setLoading(true);
        dispatch(clearAll({}));

        // Calculate the page offset
        const pageSize = 10; // Adjust this value as per your requirements

        const res = await get_delete_request(
            'get',
            token,
            `${app_api.get_all_sales}?deliveryStatus=DELIVERED&search=${searchTerm}&page=1&pageSize=${pageSize}&draw=1&q=&_=${Date.now()}`,
            {},
            dispatch,
            setCustomerLoading,
            onGetProfileSales,
            setCustomerErrorMessage,
            true
        );

        console.log("🚀 ~ handleSearchChange ~ res:", res);

        if (res !== undefined && res.status < 400) {
            let pending_list = res?.data?.data || [];
            let totalCount = res?.data?.recordsTotal;

            console.log("🚀 ~ handleSearchChange ~ pending_list:...................", pending_list);
            console.log("🚀 ~ handleSearchChange ~ totalCount:...................", totalCount);

            setPending_data(pending_list);
            setRecordsTotal(totalCount);
        }

        setLoading(false);
        setActivePage(1);
    }
    return (

        <>
            <div className="form-group search-input-top">
                <input autoComplete='off' type="text" className="form-control" id="" placeholder="Search..." onChange={handleInputChange} />
                <button className="btn btn-primary me-3 ml-2" style={{ marginLeft: "10px" }} onClick={handleSearchChange}>Search</button>
            </div>
            <div className="table-responsive web-show-tbl">
                <table className="table table-striped mt-3 tbl-pending">
                    <thead>
                        <tr>
                            <th scope="col" className='no-wrap'>No of Sales</th>
                            <th scope="col" className='no-wrap'>Event</th>
                            <th scope="col" className='no-wrap'>Event Date</th>
                            <th scope="col" className='no-wrap'>Total Ticket No.</th>
                            <th scope="col" className='no-wrap'>Total Amount</th>
                            <th scope="col" className='no-wrap'>Expand</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="8" className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></td>
                            </tr>
                        ) : pending_data.length > 0 ? (
                            pending_data.map((item, index) => (
                                <>
                                    <tr key={index} className={expandedRows[index] ? 'fontWeight' : ""}>
                                        <td>{item?.orders?.length || 0}</td>
                                        <td>{item?.eventDetails?.name}</td>
                                        <td>{moment(item?.eventDetails?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</td>
                                        <td>{item?.totalNumberOfTickets}</td>
                                        <td>{item?.totalAmount ? `${strText.currency_symbol}${formatNumber(item?.totalAmount)}` : 'N/A'}</td>
                                        <td> <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                            {/* <a title='Request refund' className="btn btn-sm bg-danger text-white btn-icon request-refund" onClick={() => refundClick(index, item)}>
                                        <i class="fa fa-undo"></i>
                                    </a> */}
                                        </td>
                                    </tr>
                                    {console.log("expanded", expandedRows)}
                                    {(expandedRows[index] && item.orders.length > 0) && (
                                        <tr>
                                            <td colSpan="8">
                                                <table className="table table-striped tbl-inner-width">
                                                    <thead>
                                                        <tr>
                                                            <th className='no-wrap'>Order Id/Sale Date</th>
                                                            <th className='no-wrap'>No. Of Ticket</th>
                                                            <th className='no-wrap'>Ticket Price</th>
                                                            <th className='no-wrap'>Total Amount</th>
                                                            <th className='no-wrap'>PromoCode Discount </th>
                                                            <th className='no-wrap'>Final Amount </th>
                                                            <th className='no-wrap'>Delivery Status</th>
                                                            <th className='no-wrap'>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    {item.orders.map((item, orderIndex) => (
                                                        <tbody>
                                                            <tr key={orderIndex}>
                                                                <td className={nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ""}>{item?.orderRef}</td>
                                                                <td>{item?.OrderItems?.length || 0}</td>
                                                                <td>{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</td>
                                                                <td>{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission + item?.commissionAmount)}` : 'N/A'}</td>
                                                                <td>{item?.OrderItems[0]?.order?.discount ? `${strText.currency_symbol}${formatNumber(item?.OrderItems[0]?.order?.discount)}` : 'N/A' }</td>
                                                                <td>{item?.OrderItems[0]?.order?.discount ? `${strText.currency_symbol}${formatNumber(item?.OrderItems[0]?.order?.price)}` : `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission + item?.commissionAmount)}` }</td>                                                               
                                                                <td>{item?.deliveryStatus}</td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <div className="">
                                                                            <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                                                {nestedExpandedRows[index]?.[orderIndex] ? (
                                                                                    <i className="fa fa-angle-up fa-sizeset"></i>
                                                                                ) : (
                                                                                    <i className="fa fa-angle-down fa-sizeset"></i>
                                                                                )}
                                                                            </a>
                                                                            <a title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery btn-m-right" onClick={() => confirmClick(index, item)}>
                                                                                <i class="fa fa-check"></i>
                                                                            </a>
                                                                            <a
                                                                                title="View Comments"
                                                                                onClick={(event) => handleViewComplaintClick(item)}
                                                                                class="btn btn-sm bg-success btn-icon text-white view-complaints btn-m-right">
                                                                                <i class="fa fa-eye"></i>
                                                                            </a>
                                                                        </div>
                                                                        <div>
                                                                            <div className='delivery-add-link mb-1' onClick={() => handleDeliveryaddressClick(item)}>Delivery Address</div>
                                                                            <div className='delivery-add-link mb-1' onClick={() => handleSellerDetailsClick(item)}>Seller Details</div>
                                                                            <div className='delivery-add-link mb-1' onClick={() => handleAddComplaintClick(item)}>Add Comments</div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>

                                                            {nestedExpandedRows[index]?.[orderIndex] && (
                                                                <tr>
                                                                    <td colSpan="7">
                                                                        <table className="table table-bordered tbl-subinner-width">
                                                                            <thead>
                                                                                <tr>
                                                                                    {/* <th className='no-wrap'>Ticket ID</th> */}
                                                                                    <th className='no-wrap'>Ticket Type</th>
                                                                                    <th className='no-wrap'>Section</th>
                                                                                    <th className='no-wrap'>Block</th>
                                                                                    <th className='no-wrap'>Row</th>
                                                                                    {/* <th className='no-wrap'>Seat Number</th> */}
                                                                                    {/* <th className='no-wrap'>Delivery Status</th> */}
                                                                                    <th className='no-wrap'>Delivery Date</th>
                                                                                    <th className='no-wrap'>Track Id</th>
                                                                                    {/* <th className='no-wrap'>Action</th> */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {/* {item.orders.map((order, orderIndex) => ( */}
                                                                               {item.OrderItems.map((items, itemIndex) => (
                                                                                    <tr key={itemIndex}>
                                                                                        {/* <td>{item?.ticket.ref}</td> */}
                                                                                        <td>{item?.ticketsType}</td>
                                                                                        <td>
                                                                                            {item?.sectionName}
                                                                                        </td>
                                                                                        <td>
                                                                                            {item?.subSectionName !== null ? item?.subSectionName : ""}

                                                                                        </td>
                                                                                        <td>{item?.row}</td>
                                                                                        {/* <td>{item?.ticket.seat}</td> */}
                                                                                        {/* <td>{item?.deliveryStatus}</td> */}
                                                                                        <td>{items?.deliveryDate ? format(new Date(items?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase() : 'N/A'}</td>
                                                                                        {/* <td><a title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery" onClick={() => confirmClick(index, item)}>
                                                                                        <i class="fa fa-check"></i>
                                                                                    </a></td> */}
                                                                                        <td>{items?.trackId}</td>

                                                                                    </tr>
                                                                                // ))
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>))}
                                                    {(show && type == 'confirm') &&
                                                        <ConfirmDelivery />
                                                    }

                                                </table>
                                            </td>
                                        </tr>
                                    )}


                                </>

                            ))

                        ) : (
                            <tr>
                                <td colSpan="8" className="text-center py-4">No Data Found</td>
                            </tr>
                        )}
                    </tbody>
                    {/* {(show && type == 'confirm') &&
                    <ConfirmDelivery />
                } */}

                </table>
            </div>

            <div className="mob-table-section">
                {loading ? (
                    <div className="text-center py-4"><i className="fa fa-refresh fa-spin"></i></div>

                ) : pending_data.length > 0 ? (
                    pending_data.map((item, index) => (
                        <>

                            <div className={`mob-table-sec-outer ${expandedRows[index] ? 'fontWeight' : ''}`} key={index}>
                                <div className="position-relative">
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">No of Sales</h3>
                                        <div className="mob-tbl-value">{item?.orders?.length || 0}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event</h3>
                                        <div className="mob-tbl-value" title={item?.eventDetails?.name}>{item?.eventDetails?.name}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Event Date</h3>
                                        <div className="mob-tbl-value">{moment(item?.eventDetails?.date).format('ddd DD MMM YYYY').toUpperCase() ?? 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Total Ticket No.</h3>
                                        <div className="mob-tbl-value">{item?.totalNumberOfTickets}</div>
                                    </div>
                                    <div className="mob-table-sec">
                                        <h3 className="mob-tbl-head">Total Amount</h3>
                                        <div className="mob-tbl-value">{item?.totalAmount ? `${strText.currency_symbol}${formatNumber(item?.totalAmount)}` : 'N/A'}</div>
                                    </div>
                                    <div className="mob-table-sec-expand">
                                        <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleRow(index, event)}>
                                            {expandedRows[index] ? (
                                                <i className="fa fa-angle-up fa-sizeset"></i>
                                            ) : (
                                                <i className="fa fa-angle-down fa-sizeset"></i>
                                            )} </a>
                                    </div>

                                </div>


                                {console.log("expanded", expandedRows)}
                                {(expandedRows[index] && item.orders.length > 0) && (
                                    <>

                                        {item.orders.map((item, orderIndex) => (
                                            <div className="mob-tbl-inner-sec" key={orderIndex}>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Order Id</h3>
                                                    <div className={`mob-tbl-value ${nestedExpandedRows[index]?.[orderIndex] ? 'fontWeight' : ''}`}>{item?.orderRef}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">No. of Ticket</h3>
                                                    <div className="mob-tbl-value">{item?.OrderItems?.length || 0}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Ticket Price</h3>
                                                    <div className="mob-tbl-value">{item?.pricePerTicket ? `${strText.currency_symbol}${formatNumber(item?.pricePerTicket)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Total Amount</h3>
                                                    <div className="mob-tbl-value ">{item?.totalAmountWithoutCommission ? `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission + item?.commissionAmount)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">PromoCode Discount</h3>
                                                    <div className="mob-tbl-value ">{item?.OrderItems[0]?.order?.discount ? `${strText.currency_symbol}${formatNumber(item?.OrderItems[0]?.order?.discount)}` : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Final Amount</h3>
                                                    <div className="mob-tbl-value ">{item?.OrderItems[0]?.order?.discount ? `${strText.currency_symbol}${formatNumber(item?.OrderItems[0]?.order?.price)}` : `${strText.currency_symbol}${formatNumber(item?.totalAmountWithoutCommission + item?.commissionAmount)}`}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Sale Date</h3>
                                                    <div className="mob-tbl-value ">{item?.createdAt ? (format(new Date(item?.createdAt), 'EEE dd MMM yyyy').toUpperCase()) : 'N/A'}</div>
                                                </div>
                                                <div className="mob-table-sec">
                                                    <h3 className="mob-tbl-head">Delivery Status</h3>
                                                    <div className="mob-tbl-value ">{item?.deliveryStatus}</div>
                                                </div>
                                                <div className="mob-table-sec-action">


                                                    <a title='View Items' className="btn btn-sm bg-primary text-white btn-icon view-items btn-m-right btn-i-padd" onClick={(event) => toggleNestedRow(index, orderIndex, event)}>
                                                        {nestedExpandedRows[index]?.[orderIndex] ? (
                                                            <i className="fa fa-angle-up fa-sizeset"></i>
                                                        ) : (
                                                            <i className="fa fa-angle-down fa-sizeset"></i>
                                                        )}
                                                    </a>
                                                    <a title='Confirm Delivery' class="btn btn-sm bg-success text-white btn-icon confirm-delivery btn-m-right btn-i-padd" onClick={() => confirmClick(index, item)}>
                                                        <i class="fa fa-check"></i>
                                                    </a>
                                                    <a
                                                        title="View Comments"
                                                        onClick={(event) => handleViewComplaintClick(item)}
                                                        class="btn btn-sm bg-success btn-icon text-white view-complaints btn-m-right btn-i-padd">
                                                        <i class="fa fa-eye"></i>
                                                    </a>

                                                    <span className='delivery-add-link btn-m-right delivery-fontsize' onClick={() => handleDeliveryaddressClick(item)}>Delivery Address</span>
                                                    <span className='delivery-add-link btn-m-right delivery-fontsize' onClick={() => handleSellerDetailsClick(item)}>Seller Details</span>
                                                    <span className='delivery-add-link btn-m-right delivery-fontsize' onClick={() => handleAddComplaintClick(item)}>Add Comments</span>


                                                </div>

                                                {nestedExpandedRows[index]?.[orderIndex] && (
                                                    <>
                                                        {item.OrderItems.map((item, itemIndex) => (
                                                            <div className="mob-tb-subinner" key={itemIndex}>
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Ticket Type</h3>
                                                                    <div className="mob-tbl-value">{item?.ticket.listing.ticketsType}</div>
                                                                </div>
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Section</h3>
                                                                    <div className="mob-tbl-value" title={item?.ticket.listing.section.name}>{item?.ticket.listing.section.name}</div>
                                                                </div>
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Block</h3>
                                                                    <div className="mob-tbl-value" >{item?.ticket.listing?.subSection !== null ? item?.ticket.listing.subSection.name : ""}
                                                                    </div>
                                                                </div>
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Row</h3>
                                                                    <div className="mob-tbl-value">{item?.ticket.row}</div>
                                                                </div>
                                                                {/* <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Seat Number</h3>
                                                                    <div className="mob-tbl-value">{item?.ticket.seat}</div>
                                                                </div> */}
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Delivery Date</h3>
                                                                    <div className="mob-tbl-value">{item?.deliveryDate ? format(new Date(item?.deliveryDate), 'EEE dd MMM yyyy').toUpperCase() : 'N/A'}</div>
                                                                </div>
                                                                <div className="mob-table-sec">
                                                                    <h3 className="mob-tbl-head">Track Id</h3>
                                                                    <div className="mob-tbl-value">{item?.trackId}</div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </>)}
                            </div>


                        </>

                    ))

                ) : (
                    <div className="text-center py-4">No Data Found</div>

                )}
                {(show && type == 'confirm') &&
                    <ConfirmDelivery />
                }

            </div>


            <DeliveryAddress setShowDbPopup={setShowDbPopup} showDbPopup={showDbPopup} pending_data={pending_data} selectedItem={selectedItem} />
            <AddComplaint setShowComplaintPopup={setShowComplaintPopup} showComplaintPopup={showComplaintPopup} pending_data={pending_data} selectedItem={selectedItem} />
            <ViewComplaints setShowViewComplaintPopup={setShowViewComplaintPopup} showViewComplaintPopup={showViewComplaintPopup} pending_data={pending_data} selectedViewItem={selectedViewItem} />
            <SellerDeatils setShowDbPopup={setShowDbPopup1} showDbPopup={showDbPopup1} pending_data={pending_data} selectedItem={selectedItem1} fromPage="AdminOrderManagement" />
            {recordsTotal > 0 ?
                <div className='pagination-wrapper'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={recordsTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                    />
                </div>
                : ""}
        </>
    );
}
