import React, { useEffect, useState } from 'react'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import SalesTable from './SalesTabl'
import PayoutTable from './PayoutTable'
import ListingTable from './ListingTabl'
import CardWrapper from '../../../layout/CardWrapper'
import { Link } from 'react-router-dom'
import DashboardCards from './DashboardCards'
import OrdersTable from './OrdersTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  clearAll,
  setCustomerErrorMessage,
} from '../../../../config/redux/slice/customerSlice'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

export default function Dashboard() {
  const dispatch = useDispatch()

  const [dashboard_card_count_list, set_dashboard_card_count_list] = useState([
    {
      id: 3,
      heading: 'Tickets Sold',
      color: 'bg-info',
      icon: 'mdi mdi-currency-gbp',
      icon_color: 'text-info',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
    {
      id: 4,
      heading: 'Tickets Listed',
      color: 'bg-purple',
      icon: 'fa fa-ticket',
      icon_color: 'text-purple',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
    {
      id: 2,
      heading: 'Active Listings',
      color: 'bg-dark',
      icon: 'mdi mdi-checkbox-marked-circle',
      icon_color: 'text-success',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_amount: 0,
      total_count: 0,
    },
    {
      id: 5,
      heading: 'Matching Alerts',
      color: 'bg-dark',
      icon: 'mdi mdi-alert-circle',
      icon_color: 'text-success',
      loading: true,
      sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
      total_count: 0,
    },
  ])

  const [dashboard_card_revenue_list, set_dashboard_card_revenue_list] =
    useState([
      {
        id: 3,
        heading: 'Tickets Sold',
        color: 'bg-info',
        icon: 'mdi mdi-currency-gbp',
        icon_color: 'text-white',
        loading: true,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: 0,
        total_count: 0,
      },
      {
        id: 4,
        heading: 'Tickets Listed',
        color: 'bg-purple',
        icon: 'fa fa-ticket',
        icon_color: 'text-white',
        loading: true,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: 0,
        total_count: 0,
      },
      {
        id: 2,
        heading: 'Active Listings',
        color: 'bg-dark',
        icon: 'mdi mdi-checkbox-marked-circle',
        icon_color: 'text-white',
        loading: true,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: 0,
        total_count: 0,
      },
    ])

  const { token } = useSelector((state) => state.authData)

  useEffect(() => {
    dispatch(clearAll())
    let cardValueCountList = []
    let cardValueRevenueList = []

    Promise.allSettled([ticketsSold(), ticketsListed(), activeListings(), getTotalAlertCount()]).then(
      function (values) {
        let index = 0
        for (let singleItem of values) {
          //Check to see if the single item value is an array because i return an array
          //of obkects if the endpoint returns data that will be used for two different cards
          //for example buyersSellersCount return data for buyers count and sellers count in a single response
          //but i would display buyer count in one card and sellers count in another card
          if (Array.isArray(singleItem.value)) {
            for (
              let position = 0;
              position < singleItem.value.length;
              position++
            ) {
              if (singleItem?.value[position]?.total_count != undefined) {
                cardValueCountList.push(singleItem?.value[position])
                // cardValueCountList.splice(index, 0, singleItem.value[position])
              }
              if (singleItem?.value[position]?.total_amount != undefined) {
                cardValueRevenueList.push(singleItem.value[position])
              }
              index++
            }
          } else {
            if (singleItem?.value?.total_count != undefined) {
              cardValueCountList.push(singleItem?.value)
              // cardValueCountList.splice(index, 0, singleItem.value)
            }
            if (singleItem?.value?.total_amount != undefined) {
              cardValueRevenueList.push(singleItem.value)
            }
            index++
          }
        }
        set_dashboard_card_count_list(cardValueCountList)
        set_dashboard_card_revenue_list(cardValueRevenueList)
      }
    )
  }, [])

  const ticketsSold = async () => {
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.total_tickets_sold}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      let obj = {
        id: 3,
        heading: 'Tickets Sold',
        color: 'bg-info',
        icon: 'mdi mdi-currency-gbp',
        icon_color: 'text-info',
        loading: false,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: res?.data?.data?.totalRevenue,
        total_count: res?.data?.data?.count,
      }
      return obj
    }
  }

  const ticketsListed = async () => {
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.total_tickets_listed}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      let obj = {
        id: 4,
        heading: 'Tickets Listed',
        color: 'bg-purple',
        icon: 'fa fa-ticket',
        icon_color: 'text-purple',
        loading: false,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: res?.data?.data?.totalRevenue,
        total_count: res?.data?.data?.count,
      }
      return obj
    }
  }

  const activeListings = async () => {
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.total_active_listings}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      let obj = {
        id: 2,
        heading: 'Active Listings',
        color: 'bg-dark',
        icon: 'mdi mdi-checkbox-marked-circle',
        icon_color: 'text-success',
        loading: false,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_amount: res?.data?.data?.totalRevenue,
        total_count: res?.data?.data?.count,
      }
      return obj
    }
  }

  const getTotalAlertCount = async () => {
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.total_ticket_alert}`,
      {},
      dispatch,
      '',
      '',
      setCustomerErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      console.log('res212', res);
      let obj = {
        id: 2,
        heading: 'Matching Alerts',
        color: 'bg-dark',
        icon: 'mdi mdi-alert-circle',
        icon_color: 'text-success',
        loading: false,
        sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
        total_count: res?.data?.data,
      }
      return obj
    }
  }

  return (
    <CustomerWrapper heading={'Dashboard'}>
      <div style={{ rowGap: '1rem' }} className="d-flex flex-column">
        <DashboardCards dashboard_card_count_list={dashboard_card_count_list} />
        {/* <CardWrapper cardTitle={"WHAT'S NEXT"} cardButtons={<Link className='text-decoration-none'>View all current and past orders</Link>}>
          <div>
            You don't have any upcoming events scheduled right now
          </div>
        </CardWrapper> */}
        <OrdersTable />
        <ListingTable />
        <SalesTable />
        <PayoutTable />
      </div>
    </CustomerWrapper>
  )
}
