import React, { useEffect, useState } from 'react'
import DashboardCards from './DashboardCards'
import AdminWrapper from '../../../layout/AdminWrapper'
import ProfileStatistics from './ProfileStatistics'
import BarChart from './BarChart'
import BarChartForRevenue from './BarChartForRevenue'
import PieChart from './PieChart'
import AreaChart from './AreaChart'
import { get_delete_request } from '../../../../helpers/request'
import { useDispatch, useSelector } from 'react-redux'
import { app_api } from '../../../../utils/endpoints/app_api'
import { clearAll, setAdminErrorMessageUser } from '../../../../config/redux/slice/adminSlice'
import moment from 'moment'
import CommisionDetails from './CommisionDetails'
import SalesDetails from './salesDetails'
import EventDetails from './eventDetails'
import SellerDetails from './sellerDetails'
import { formatNumber } from '../../../../helpers/helpers'

export default function Dashboard() {
    const dispatch = useDispatch()
    const [sales_trend_data, set_sales_trend_data] = useState([])
    const [revenueTicketSold, setRevenueTicketSold] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const [show, setShow] = useState(false);
    const [showSalesDetails, setSalesDetails] = useState(false);
    const [showEventsDetails, setEventsDetails] = useState(false);
    const [showSellerDetails, setSellerDetails] = useState(false);
    const [eventId, setEventId] = useState("");

    const handleClose = () => setShow(false);
    const handleCloseSalesDetails = () => setSalesDetails(false);
    const handleCloseEventDetails = () => setEventsDetails(false);
    const handleCloseSellerDetails = () => setSellerDetails(false);
    const handleShow = (event) => {
        setSelectedEvent(event);
        setShow(true);
        top_commision_event_details(event.eventId);
        setEventId(event.eventId)
        console.log("🚀 ~ handleShow ~ event:", event)
    };
    const handleShowSalesDetails = (event) => {
        setSelectedEvent(event);
        setSalesDetails(true);
        top_sales_event_details(event.eventId);
        console.log("🚀 ~ handleShow ~ event:", event)
    };
    const handleShowEventDetails = (event) => {
        setSelectedEvent(event);
        setEventsDetails(true);
        top_event_details(event.eventId);
        console.log("🚀 ~ handleShow ~ event:", event)
    };
    const handleShowSellerDetails = (event) => {
        setSelectedEvent(event);
        setSellerDetails(true);
        top_seller_details(event.sellerId);
        console.log("🚀 ~ handleShowSellerDetails ~ event:", event)
    };
    const [dashboard_card_count_list, set_dashboard_card_count_list] = useState(
        [
            {
                id: 1,
                heading: 'Total Events',
                icon: 'icon ion-calendar',
                icon_color: 'text-white',
                color: 'bg-dark',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: 0
            },
            {
                id: 3,
                heading: 'Tickets Sold',
                color: 'bg-info',
                icon: 'mdi mdi-ticket',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 4,
                heading: 'Tickets Listed',
                color: 'bg-purple',
                icon: 'fa fa-ticket',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 2,
                heading: 'Active Listings',
                color: 'bg-dark',
                icon: 'mdi mdi-checkbox-marked-circle',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 7,
                heading: 'Users',
                color: 'bg-info',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: 0
            },
            {
                id: 5,
                heading: 'Buyers',
                color: 'bg-primary',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: 0
            },
            {
                id: 6,
                heading: 'sellers',
                color: 'bg-dark',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: 0
            }
        ]
    )
    const [dashboard_card_revenue_list, set_dashboard_card_revenue_list] = useState(
        [
            {
                id: 5,
                heading: 'Active Listings',
                color: 'bg-dark',
                icon: 'mdi mdi-checkbox-marked-circle',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 3,
                heading: 'Tickets Sold',
                color: 'bg-info',
                icon: 'mdi mdi-ticket',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 4,
                heading: 'Tickets Listed',
                color: 'bg-purple',
                icon: 'fa fa-ticket',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            },
            {
                id: 2,
                heading: 'Active Listings',
                color: 'bg-dark',
                icon: 'mdi mdi-checkbox-marked-circle',
                icon_color: 'text-white',
                loading: true,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: 0,
                total_count: 0
            }
        ]
    )
    const [pie_chart_values, set_pie_chart_values] = useState({})
    const [trending_events, set_trending_events] = useState([])

    const [commision_events, set_commision_events] = useState([])
    const [commision_details, set_commision_details] = useState([])
    const [sales_details, set_sales_details] = useState([])
    const [events_details, set_events_details] = useState([])
    const [seller_details, set_seller_details] = useState([])
    const [sales_events, set_sales_events] = useState([])
    const [listing_events, set_listing_events] = useState([])
    const [sales_seller, set_sales_seller] = useState([])

    const {
        token
    } = useSelector((state) => state.authData)

    useEffect(() => {
        salesRevenueTrend()
        top_commision_events()
        // revenueTicketDetails()
    }, [])
    const getCurrentDate = () => {
        const now = new Date();
        return { month: now.getMonth() + 1, year: now.getFullYear() };
    };

    const revenueTicketDetails = async (period = 'week', event_id = null) => {
        const { month, year } = getCurrentDate();
        const url = app_api.revenue_tickets_sold(period, month, year, event_id);

        try {
            const res = await get_delete_request(
                "get",
                token,
                url,
                {},
                dispatch,
                "",
                "",
                setAdminErrorMessageUser
            );

            if (res && res.status < 400) {
                const revenueTicketList = res?.data?.data?.data || [];
                console.log("revenueTicketttttttttttttttt", res);
                setRevenueTicketSold(revenueTicketList);
            }
        } catch (error) {
            console.error("Error fetching revenue ticket details:", error);
        }
    };
    const salesRevenueTrend = async () => {

        const res = await get_delete_request(
            "get",
            token,
            `${app_api.sales_revenue_monthly}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let sales_trend_list = res?.data?.data?.data?.map((item) => {
                if (item.total == null) {
                    return 0
                } else {
                    return item.total
                }
            })
            set_sales_trend_data(sales_trend_list)
        }
    }

    useEffect(() => {
        dispatch(clearAll())
        top_selling_events()
        top_commision_events()
        top_sales_events()
        top_listing_events()
        top_sales_seller()
        let cardValueCountList = []
        let cardValueRevenueList = []

        Promise.allSettled([
            total_commission_revenue(),
            eventCount(),
            ticketsSold(),
            ticketsListed(),
            activeListings(),
            registeredUsers(),
            buyersSellersCount(),
        ]).then(function (values) {
            let index = 0
            for (let singleItem of values) {
                //Check to see if the single item value is an array because i return an array
                //of obkects if the endpoint returns data that will be used for two different cards
                //for example buyersSellersCount return data for buyers count and sellers count in a single response
                //but i would display buyer count in one card and sellers count in another card
                if (Array.isArray(singleItem.value)) {
                    for (let position = 0; position < singleItem.value.length; position++) {
                        if (singleItem?.value[position]?.total_count != undefined) {
                            cardValueCountList.push(singleItem?.value[position])
                            // cardValueCountList.splice(index, 0, singleItem.value[position])
                        }
                        if (singleItem?.value[position]?.total_amount != undefined) {
                            cardValueRevenueList.push(singleItem.value[position])
                        }
                        index++
                    }
                } else {
                    if (singleItem?.value?.total_count != undefined) {
                        cardValueCountList.push(singleItem?.value)
                        // cardValueCountList.splice(index, 0, singleItem.value)
                    }
                    if (singleItem?.value?.total_amount != undefined) {
                        cardValueRevenueList.push(singleItem.value)
                    }
                    index++
                }
            }
            set_dashboard_card_count_list(cardValueCountList)
            set_dashboard_card_revenue_list(cardValueRevenueList)
        });
    }, [])

    const top_selling_events = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.top_selling_events}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            set_trending_events(res?.data?.data)
        }
    }

    const top_commision_events = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.top_commision_events}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            set_commision_events(res?.data?.data)
        }
    }
    const top_commision_event_details = async (eventId) => {
        dispatch(clearAll({}))
        // setIsbadRequest(false)
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.top_commision_event_details(eventId)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res !== undefined && res.status < 400) {
            console.log("🚀 ~ getSingleEvent ~ res:", res)

            dispatch(set_commision_details({ details: res?.data?.data, type: 'event' }))
        }
    }
    const top_sales_event_details = async (eventId) => {
        dispatch(clearAll({}))
        // setIsbadRequest(false)
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.top_sales_event_details(eventId)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res !== undefined && res.status < 400) {
            console.log("🚀 ~ top_sales_event_details ~ res:", res)

            dispatch(set_sales_details({ details: res?.data?.data, type: 'event' }))
        }
    }
    const top_event_details = async (eventId) => {
        dispatch(clearAll({}))
        // setIsbadRequest(false)
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.top_event_details(eventId)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res !== undefined && res.status < 400) {
            console.log("🚀 ~ top_event_details ~ res:", res)

            dispatch(set_events_details({ details: res?.data?.data, type: 'event' }))
        }
    }
    const top_seller_details = async (userId) => {
        dispatch(clearAll({}))
        // setIsbadRequest(false)
        const res = await get_delete_request(
            'get',
            token,
            `${app_api.top_seller_details(userId)}`,
            {},
            dispatch,
            "",
            "",
            ""
        )
        if (res !== undefined && res.status < 400) {
            console.log("🚀 ~ top_seller_details ~ res:", res)

            dispatch(set_seller_details({ details: res?.data?.data, type: 'event' }))
        }
    }

    const top_sales_events = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.top_sales_events}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            set_sales_events(res?.data?.data)
        }
    }

    const top_listing_events = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.top_listing_events}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            set_listing_events(res?.data?.data)
        }
    }

    const top_sales_seller = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.top_sales_seller}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            set_sales_seller(res?.data?.data)
        }
    }



    const total_commission_revenue = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.total_commission_revenue}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 5,
                heading: 'Total Commission',
                icon: 'fa fa-bar-chart',
                icon_color: 'text-white',
                color: 'bg-dark',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: res?.data?.data?.total
            }
            return obj
        }
    }

    const eventCount = async () => {

        const res = await get_delete_request(
            "get",
            token,
            `${app_api.events_count}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 1,
                heading: 'Total Events',
                icon: 'icon ion-calendar',
                icon_color: 'text-white',
                color: 'bg-dark',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: res?.data?.data?.count
            }
            return obj
        }
    }

    const ticketsSold = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.total_tickets_sold}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 3,
                heading: 'Tickets Sold',
                color: 'bg-info',
                icon: 'mdi mdi-ticket',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: res?.data?.data?.totalRevenue,
                total_count: res?.data?.data?.soldCount
            }
            set_pie_chart_values((prev) => {
                return { ...prev, total_sold: res?.data?.data?.soldCount }
            })
            return obj
        }
    }

    const ticketsListed = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.total_tickets_listed}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 4,
                heading: 'Tickets Listed',
                color: 'bg-purple',
                icon: 'fa fa-ticket',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: res?.data?.data?.totalRevenue,
                total_count: res?.data?.data?.count
            }
            set_pie_chart_values((prev) => {
                return { ...prev, total_listed: res?.data?.data?.count }
            })
            return obj
        }
    }

    const activeListings = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.total_active_listings}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 2,
                heading: 'Active Listings',
                color: 'bg-dark',
                icon: 'mdi mdi-checkbox-marked-circle',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_amount: res?.data?.data?.totalRevenue,
                total_count: res?.data?.data?.count
            }
            return obj
        }
    }

    const buyersSellersCount = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.buyers_sellers_count}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj1 = {
                id: 5,
                heading: 'Buyers',
                color: 'bg-primary',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: res?.data?.data?.buyers?.count
            }
            let obj2 = {
                id: 6,
                heading: 'sellers',
                color: 'bg-dark',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: res?.data?.data?.sellers?.count
            }
            return [obj1, obj2]
        }
    }

    const registeredUsers = async () => {
        const res = await get_delete_request(
            "get",
            token,
            `${app_api.registered_users_count}`,
            {},
            dispatch,
            "",
            "",
            setAdminErrorMessageUser
        )
        if (res !== undefined && res.status < 400) {
            let obj = {
                id: 7,
                heading: 'Users',
                color: 'bg-info',
                icon: 'icon ion-person',
                icon_color: 'text-white',
                loading: false,
                sparkline_list: [5, 3, 9, 6, 5, 9, 7, 3, 5, 2],
                total_count: res?.data?.data?.count
            }
            return obj
        }
    }

    return (
        <AdminWrapper>
            <div>
                <DashboardCards dashboard_card_count_list={dashboard_card_count_list} dashboard_card_revenue_list={dashboard_card_revenue_list} />
                <div className="row row-sm mt-4">
                    <div className="col-xl-8">
                        <AreaChart sales_data={sales_trend_data} />
                        {/* <BarChart sales_data={sales_trend_data} /> */}
                    </div>
                    <div className="col-xl-4 mt-xl-0">
                        <PieChart pie_chart_values={pie_chart_values} />
                    </div>


                    <div className="col-xl-12">
                        <BarChartForRevenue />
                    </div>




                    <div className="col-xl-6 mt-xl-0">

                        <div className="card widget-messages mt-4">
                            <div className="card-header">
                                <span><b>Commision By Events</b></span>
                            </div>

                            {/* <div className="card-body-scroll">
                                <div className="list-group list-group-flush">
                                    

                                    {commision_events.map((singleEvent) => (

                                        <div key={singleEvent.id} onClick={() => handleShow(singleEvent)} className="list-group-item list-group-item-action d-flex gap-3 align-items-center">
                                            <div className="h-10 w-10 rounded-circle"
                                            >
                                                <img className="h-10 w-10" src={singleEvent.poster} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span className='text-ellipse' title={singleEvent.eventName}>{singleEvent.eventName}</span>
                                                        <div className="fs-12">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                    </div>
                                                    <div>
                                                        <span className='no-wrap'>{singleEvent.currency} {singleEvent.totalCommission}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            </div> */}

                            <div className="card-body-scroll">

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='no-wrap'>Event Details</th>
                                            <th scope="col" className='no-wrap'>Total Amount</th>
                                            <th scope="col" className='no-wrap'>Commission</th>
                                            <th scope="col" className='no-wrap'>Tickets Listed</th>
                                            <th scope="col" className='no-wrap'>Tickets Sold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {commision_events.map((singleEvent) => (
                                            <tr key={singleEvent.id} onClick={() => handleShow(singleEvent)} className='cursor-pointer'>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className='img-right'><img className="h-10 w-10" src={singleEvent.poster} /></div>
                                                        <div>
                                                            <div className="text-ellipse" title={singleEvent.eventName}><b>{singleEvent.eventName}</b></div>
                                                            <div className="text-ellipse">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{singleEvent.currency} {formatNumber(singleEvent.totalAmount)}</td>
                                                <td>{singleEvent.currency} {formatNumber(singleEvent.totalCommission)}</td>
                                                <td>{singleEvent.totalTickets}</td>
                                                <td>{singleEvent.totalTicketsSold}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <CommisionDetails show={show} handleClose={handleClose} event={selectedEvent} commision_details={commision_details} />
                    </div>

                    <div className="col-xl-6 mt-xl-0">
                        <div className="card widget-messages mt-4">
                            <div className="card-header">
                                <span><b>Sales By Events</b></span>
                            </div>
                            <div className="card-body-scroll">
                                {/* <div className="list-group list-group-flush">

                                    {sales_events.map((singleEvent) => {

                                        return <div key={singleEvent.id} onClick={() => handleShowSalesDetails(singleEvent)} className="list-group-item list-group-item-action d-flex gap-3 align-items-center">
                                            <div className="h-10 w-10 rounded-circle"
                                            >
                                                <img className="h-10 w-10" src={singleEvent.poster} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="">
                                                        <span className='text-ellipse' title={singleEvent.eventName}>{singleEvent.eventName}</span>
                                                        <div className="fs-12">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                    </div>
                                                    <div className="">
                                                        <span className='no-wrap'>{singleEvent.currency} {singleEvent.totalPrice}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div> */}

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='no-wrap'>Event Details</th>
                                            {/* <th scope="col" className='no-wrap'>Tickets</th> */}

                                            <th scope="col" className='no-wrap'>Total Amount</th>
                                            <th scope="col" className='no-wrap'>Tickets Listed</th>
                                            <th scope="col" className='no-wrap'>Tickets Sold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sales_events.map((singleEvent) => (
                                            <tr key={singleEvent.id} onClick={() => handleShowSalesDetails(singleEvent)} className='cursor-pointer'>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className='img-right'><img className="h-10 w-10" src={singleEvent.poster} /></div>
                                                        <div>
                                                            <div className="text-ellipse" title={singleEvent.eventName}><b>{singleEvent.eventName}</b></div>
                                                            <div className="text-ellipse">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                        </div>
                                                    </div>
                                                </td>

                                                {/* <td>{singleEvent.totalSoldTickets}</td> */}
                                                <td>{singleEvent.currency} {formatNumber(singleEvent.totalPrice)}</td>
                                                <td>{singleEvent.totalTickets}</td>
                                                <td>{singleEvent.totalTicketsSold}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <SalesDetails show={showSalesDetails} handleClose={handleCloseSalesDetails} event={selectedEvent} sales_details={sales_details} />
                    </div>

                    <div className="col-xl-6 mt-xl-0">
                        <div className="card widget-messages mt-4">
                            <div className="card-header">
                                <span><b>Listing By Events</b></span>
                            </div>
                            <div className="card-body-scroll">
                                {/* <div className="list-group list-group-flush">

                                    {listing_events.map((singleEvent) => {

                                        return <div key={singleEvent.id} onClick={() => handleShowEventDetails(singleEvent)} className="list-group-item list-group-item-action d-flex gap-3 align-items-center">
                                            <div className="h-10 w-10 rounded-circle"
                                            >
                                                <img className="h-10 w-10" src={singleEvent.poster} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span className='text-ellipse' title={singleEvent.eventName}>{singleEvent.eventName}</span>
                                                        <div className="fs-12">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                    </div>
                                                    <div>
                                                        <span className='no-wrap'>{singleEvent.currency} {singleEvent.totalPrice}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div> */}

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='no-wrap'>Event Details</th>
                                            <th scope="col" className='no-wrap'>Tickets Listed</th>
                                            <th scope="col" className='no-wrap'>Tickets Sold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listing_events.map((singleEvent) => (
                                            <tr key={singleEvent.id} onClick={() => handleShowEventDetails(singleEvent)} className='cursor-pointer'>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <div className='img-right'><img className="h-10 w-10" src={singleEvent.poster} /></div>
                                                        <div>
                                                            <div className="text-ellipse" title={singleEvent.eventName}><b>{singleEvent.eventName}</b></div>
                                                            <div className="text-ellipse">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{singleEvent.totalTickets}</td>
                                                <td>{singleEvent.totalSoldTickets}</td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <EventDetails show={showEventsDetails} handleClose={handleCloseEventDetails} event={selectedEvent} events_details={events_details} />
                    </div>

                    <div className="col-xl-6 mt-xl-0">
                        <div className="card widget-messages mt-4">
                            <div className="card-header">
                                <span><b>Sales By Sellers</b></span>
                            </div>
                            <div className="card-body-scroll">
                                {/* <div className="list-group list-group-flush">

                                    {sales_seller.map((singleSeller) => {
                                        console.log("🚀 ~ {sales_seller.map ~ sales_seller:", sales_seller)

                                        return <div key={singleSeller.id} onClick={() => handleShowSellerDetails(singleSeller)} className="list-group-item list-group-item-action d-flex gap-3 align-items-center">
                                            <div className="h-10 w-10 rounded-circle"
                                            >
                                                <img className="h-10 w-10" src={singleSeller.avatar} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span className='text-ellipse' title={singleSeller.sellerName}>{singleSeller.sellerName}</span>
                                                        <div className='text-ellipse' title={singleSeller.email}>{singleSeller.email}</div>
                                                    </div>
                                                    <div>
                                                        <span className='no-wrap'>{singleSeller.currency} {singleSeller.totalSales}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div> */}

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" className='no-wrap'>Seller Details</th>
                                            <th scope="col" className='no-wrap'>Total Amount</th>
                                            <th scope="col" className='no-wrap'>Tickets Listed</th>
                                            <th scope="col" className='no-wrap'>Tickets Sold</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sales_seller.map((singleSeller) => (
                                            <tr key={singleSeller.id} onClick={() => handleShowSellerDetails(singleSeller)} className='cursor-pointer'>
                                                <td>
                                                    <div className="d-flex align-items-center ">
                                                        <div className='img-right'> <img className="h-10 w-10" src={singleSeller.avatar} /></div>
                                                        <div>
                                                            <div className="text-ellipse" title={singleSeller.sellerName}><b>{singleSeller.sellerName}</b></div>
                                                            <div className="text-ellipse" title={singleSeller.email}>{singleSeller.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{singleSeller.currency} {formatNumber(singleSeller.totalSales)}</td>
                                                <td>{singleSeller.totalTickets}</td>
                                                <td>{singleSeller.soldTickets}</td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <SellerDetails show={showSellerDetails} handleClose={handleCloseSellerDetails} event={selectedEvent} seller_details={seller_details} />

                    </div>
                    <div className="col-xl-6 mt-xl-0">

                        <div className="card widget-messages mt-4">
                            <div className="card-header">
                                <span><b>Trending Events</b></span>
                            </div>

                            <div className="card-body-scroll">

                                <div className="list-group list-group-flush">

                                    {trending_events.map((singleEvent) => (
                                        singleEvent.addTrending === true &&
                                        <div key={singleEvent.id} className="list-group-item list-group-item-action d-flex gap-3 align-items-center" href="#">
                                            <div className="w-10 rounded-circle">
                                                <img className="h-10 w-10" src={singleEvent.poster} alt={singleEvent.name} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>
                                                        <span className='text-ellipse-trending' title={singleEvent.name}>{singleEvent.name}</span>
                                                        <div className="fs-12">{moment(singleEvent.date).format('DD-MM-YYYY')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </div>
        </AdminWrapper >
    )
}