import React, { useEffect, useState } from 'react'
import AdminWrapper from '../../../layout/AdminWrapper'
import AddEvent from './AddEvent'
import EventTable from './EventTable'
import { useDispatch, useSelector } from 'react-redux'
import FilterEvent from './FilterEvent'
import { get_delete_request } from '../../../../helpers/request'
import { app_api } from '../../../../utils/endpoints/app_api'

import { onGetProfileSales, setCustomerErrorMessage, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import { clearAll } from '../../../../config/redux/slice/adminSlice'

export default function EventManagement() {

  const dispatch = useDispatch()

  const {
    eventManagement: { showAddEventForm, eventDetails, showEventModal: { show, type } }
  } = useSelector((state) => state.admin)

  const {
    token
  } = useSelector((state) => state.authData)

  const [types, setTypes] = useState([]);
  const [usertypes, setUserTypes] = useState([]) ///////xdsd

  const [active_data, getActive_data] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordsTotal, setRecordsTotal] = useState("");
  const [searchTerm, setSearchTerm] = useState('');



  useEffect(() => {
    dispatch(clearAll({}))
    getAllEventTypes()
    getAllUserTypes() //////xdsd
    getActiveList()

  }, [])

  const getActiveList = async () => {
    setLoading(true);
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_events}?eventStatus=ActiveEvents&page=1&pageSize=10&draw=1&q=&_=1719545988610`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      true
    )
    console.log("🚀 ~ getActiveList ~ res:", res)
    if (res !== undefined && res.status < 400) {
      let active_list = res?.data?.data || [];
      let totalCount = res?.data?.recordsTotal
      getActive_data(active_list);
      setRecordsTotal(totalCount)
      setSearchTerm('')
    }
    setLoading(false);
  }


  const getAllEventTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_event_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {
      setTypes(res?.data?.data)
    }
  }
  //////////XDSD////////
  const getAllUserTypes = async () => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_user_type}`,
      {},
      dispatch,
      "",
      "",
      ""
    )
    if (res != undefined && res.status < 400) {

      setUserTypes(res?.data?.data)
    }
  }
  return (//xskr
    <AdminWrapper>
      <div>
        {showAddEventForm &&
          <AddEvent types={types} usertypes={usertypes} getActiveList={getActiveList} />
        }
        {/* <AddEvent/> */}
        {/* <FilterEvent types={types} usertypes={usertypes}/> */}
        <EventTable types={types} usertypes={usertypes} getActiveList={getActiveList} getActiveListData={active_data} recordsTotalData={recordsTotal} />
      </div>
    </AdminWrapper>
  )
}
