const base = '/users'

export const user_urls = {
  dashboard: `${base}/dashboard`,
  profile: `${base}/profile`,
  orders: `${base}/orders`,
  sales: `${base}/sales`,
  user_payout: `${base}/payout`,
  sell_ticket: `/sell/event/:eventId`,
  sell_ticket_dyn: (eventId) => `/sell/event/${eventId}`,
  upload_ticket: `/sell/upload/event/:eventId`,
  upload_ticket_dyn: (eventId) => `/sell/upload/event/${eventId}`,
  user_listings: `${base}/listings`,
  user_listings_details: `${base}/listings/details`,
  buy_ticket: `/review-order`,
  buy_ticket_tab: `/review-order/:tabNo`,
  user_tickets: `${base}/listings/:listingId`,
  user_tickets_dyn: (listingId) => `${base}/listings/${listingId}`,
  payment_successful: `${base}/payment/success`,
  // listing_event_dyn: `${base}/listings/expand`,
  order_items: `${base}/orders/items`,
  event_order_items: `${base}/sales/event-items`,
  sale_items: `${base}/sales/items`,
  user_sales_details: `${base}/sales/details`,
  connectedAcc: `/sell/event/create-connected-account`,
  connected_account_successful: `/create-connected-account/success`,
  alerts: `${base}/alerts`,
  alerts_request: `${base}/alert-request`
  // 'buy_ticket_dyn': (eventId) => `/sell/upload/event/${eventId}`
}
