import React from 'react'
import { useSelector } from 'react-redux'
import default_img from '../../../assets/images/default-img.jpeg'
import { navigationStructure } from '../../../utils/nav_structure'
import { Link } from 'react-router-dom'

export default function SideBar() {
  const {
        isSidebarOpen
  } = useSelector((state) => state.customer)

  const {isActive} = useSelector((state) => state.app)

  const {
        userData
    } = useSelector((state) => state.authData)

    const filteredNavigation = navigationStructure.customer_navigation.filter((option) => {
      console.log('option123', option);
      console.log('userData', userData);
      if (option.name === 'Alert Request') {
        // Show "Premium Features" only if userType is PREMIUM and hasSeller is true
        return userData?.userType === 'PREMIUM' && userData?.isSeller === true;
      }
      return true; // Show all other options by default
    });

  return (
  <div style={{zIndex: 1057}} className={`bg-white customer-sidebar position-fixed ${isSidebarOpen ? 'sidebar-out' : ''}`}>
        <div className='text-center border-bottom px-lg-2 pt-3 px-xl-3'>
            <img crossOrigin='anonymous' className='rounded-circle' width='60' height='60' src={(!userData?.avatar || userData.avatar == '') ? default_img : userData?.avatar}/>
            <h5 className='fw-bold'>{userData.fullname}</h5>
        </div>
        <ul className='pt-3 px-1 px-lg-2 px-xl-3'>
          {(filteredNavigation).map((option)=>{
            return <Link to={option.url}><li key={option.id} className={`text-center ${isActive == option.url ? 'active' : ''} py-2 text-uppercase fw-bold`}>
              {option.name}
            </li></Link>
          })}
        </ul>
      
    </div>
  )
}
