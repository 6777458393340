import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { clearAll, clearResetBot, setCustomerErrorMessage, setDetails, showListingModalFunc, onGetProfileSales, setCustomerLoading } from '../../../../config/redux/slice/customerSlice'
import { listingsCustomerDatatable } from '../../../../helpers/customer_dataTables'
import { app_api } from '../../../../utils/endpoints/app_api'
import { app_urls } from '../../../../utils/urls/app_urls'
import CustomerWrapper from '../../../layout/CustomerWrapper'
import { get_delete_request, post_put_patch_request } from '../../../../helpers/request'
import AlertsTableList from './AlertsTableList'
import DeleteAlerts from './DeleteAlerts'
import EditAlerts from './EditAlerts'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PayoutPaymentSummaryCards from '../payouts_management/payoutPaymentSummaryCards'
import TicketAlertSummaryCards from './ticketAlertSummaryCards'
import MatchingAlertTableList from './matchingAlertTable'
import ExpiredAlertTableList from './ExpiredAlertTable'
import ClosedAlertTable from './ClosedAlertTable'


export default function AlertsTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { listingId } = useNavigate()
  const [listingData, setListingData] = useState()
  const [listingCount, setListingCount] = useState()
  const [expiredListingData, setExpiredListingData] = useState([])
  const [closedListingData, setClosedListingData] = useState([])
  const [matchingListingData, setMatchingListingData] = useState([])
  const [matchingListingCount, setMatchingListingCount] = useState([])
  const [closedListingCount, setClosedListingCount] = useState()
  const [expiredListingCount, setExpiredListingCount] = useState()
  const {token} = useSelector((state) => state.authData)
  const {listingManagement: { newData, listingDetails, showListingModal: { show, type } }} = useSelector((state) => state.customer)
  
  useEffect(() => {
    localStorage.setItem("activePage","")
    localStorage.setItem("searchTerm","")
      getList()
      }, [])

  const handleUpdateListing = () => {
    var activePage = localStorage.getItem("activePage");
    var searchTerm = localStorage.getItem("searchTerm");
    handlePageChange(activePage,searchTerm); 
    dispatch(showListingModalFunc({ modal: false, type: null })); 
  };
  const handlePageChange = async (event,searchTerm) => {
    dispatch(clearAll({}));
    const pageSize = 10; 
    const res = await get_delete_request(
        'get',
        token,
        `${app_api.get_ticket_alerts}?page=${event}&pageSize=${pageSize}&draw=2&q=${searchTerm}&_=${Date.now()}`,
        {},
        dispatch,
        setCustomerLoading,
        onGetProfileSales,
        setCustomerErrorMessage,
        true
    );
    console.log("🚀 ~ handleSearchChange ~ res:", res);
    if (res !== undefined && res.status < 400) {
      setListingData(res?.data?.data || [])
      setListingCount(res?.data?.recordsTotal)
    }
}



  const getList = async (page) => {
    dispatch(clearAll({}))
    const res = await get_delete_request(
      'get',
      token,
      `${app_api.get_ticket_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      false
    )
    if (res !== undefined && res.status < 400) {
      console.log(res?.data);
      setListingData(res?.data?.data || [])
      setListingCount(res?.data?.recordsTotal)
    }
    const res1 = await get_delete_request(
      'get',
      token,
      `${app_api.get_matching_ticket_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      false
    )
    if (res1 !== undefined && res1.status < 400) {
      console.log(res1?.data);
      setMatchingListingData(res1?.data?.data || [])
      setMatchingListingCount(res1?.data?.recordsTotal)
    }
    const res2 = await get_delete_request(
      'get',
      token,
      `${app_api.get_expired_ticket_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      false
    )
    if (res2 !== undefined && res2.status < 400) {
      console.log(res2?.data);
      setExpiredListingData(res2?.data?.data || [])
      setExpiredListingCount(res2?.data?.recordsTotal)
    }
    const res3 = await get_delete_request(
      'get',
      token,
      `${app_api.get_closed_ticket_alerts}?page=1&pageSize=10&draw=2&q=&_=1720424965852`,
      {},
      dispatch,
      setCustomerLoading,
      onGetProfileSales,
      setCustomerErrorMessage,
      false
    )
    if (res3 !== undefined && res3.status < 400) {
      console.log(res3?.data);
      setClosedListingData(res3?.data?.data || [])
      setClosedListingCount(res3?.data?.recordsTotal)
    }

  }

  const setItem = (data, type) => {
    dispatch(setDetails({ details: data, type: 'listing' }))
    if (type == 'details') {
      navigate(app_urls.user_listings_details)
    } else if (type == 'view-tickets') {
      navigate(app_urls.user_tickets_dyn(data.id))
    } else if (type == 'expand') {

    }
    else if (type == 'delete' || type == 'edit' || type == 'duplicate') {
      dispatch(showListingModalFunc({ modal: true, type }))
    }
  }

  const errorFunc = (msg) => {
    dispatch(setCustomerErrorMessage({ message: msg }))
  }
  return (
    <CustomerWrapper heading={'Alerts'}>
       <TicketAlertSummaryCards/>
        <div className="payable-tblouter tbl-bg-white">
            <Tabs>
              <TabList>
                <Tab>Open Alerts</Tab>
                <Tab>Matching Alerts</Tab>
                <Tab>Closed Alerts</Tab>
                {/* <Tab>Failed</Tab> */}
                <Tab>Expired Alerts</Tab>
              </TabList>

              <TabPanel>
                {/* <PendingTable /> */}
                <AlertsTableList key={listingData} getList1= {getList} listingDetails={listingData} listingCount={listingCount} getList={handleUpdateListing} />

              </TabPanel>
              <TabPanel>
                {/* <InprogressTable /> */}
                <MatchingAlertTableList key={matchingListingData} getList1= {getList} getList={handleUpdateListing} listingDetails={matchingListingData} listingCount={matchingListingCount}/>
              </TabPanel>
              {/* <TabPanel>
                <FailedTable />
              </TabPanel> */}
              <TabPanel>
              <ClosedAlertTable key={closedListingData} getList1= {getList} getList={handleUpdateListing} listingDetails={closedListingData} listingCount={closedListingCount}/>

             

                </TabPanel>
                <TabPanel>
              <ExpiredAlertTableList key={closedListingData} getList1= {getList} getList={handleUpdateListing} listingDetails={expiredListingData} listingCount={expiredListingCount}/>

                {/* <PaidPayoutTable /> */}
              </TabPanel>
            </Tabs>
        </div>

      <div className="payable-tblouter tbl-bg-white">
        {/* <DataTableWrapper> */}
        {(show && type == 'delete') &&
          <DeleteAlerts  getList={handleUpdateListing} />
        }
        
        {(show && type == 'edit') &&
          <EditAlerts alertObj={listingData.alerts} getList={handleUpdateListing}/>
        }
        {/* <AlertsTableList key={listingData} getList1= {getList} listingDetails={listingData} listingCount={listingCount} getList={handleUpdateListing} /> */}
        {/* </DataTableWrapper> */}
      </div>
    </CustomerWrapper>
  )
}
