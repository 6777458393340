import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Select from "react-select";

import {
  clearAll,
  onOnboardUser,
  onUserLogin,
  setErrorMessage,
  setLoading,
  showPhoneNumberVerificationModalFunc
} from '../../../config/redux/slice/authSlice'
import { post_put_patch_request } from '../../../helpers/request'
import { app_api } from '../../../utils/endpoints/app_api'
import { app_urls } from '../../../utils/urls/app_urls'
import AuthAlert from '../../components/AuthAlert'
import PasswordComponent from '../../components/Form/PasswordComponent'
import Spinner from '../../components/Spinner'
import AuthWrapper from '../../layout/AuthWrapper'
import SocialAuthComponent from '../../components/Form/SocialAuthComponent'
import PhoneNumberComponent from '../../components/Form/PhoneNumberComponent'
import registration_background from '../../../assets/images/background_images/registration_background.jpeg'
import CountryComponent from '../../components/Form/CountryComponent'
import { get_delete_request } from '../../../helpers/request'

export default function Registration() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onTouched' })
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [selectedClub, setSelectedClub] = React.useState(null);
  const [selectedSports, setSelectedSports] = React.useState(null);
  const { loading, errorMsg, token } = useSelector((state) => state.authData)
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showExistValue, setExistMsg] = useState('');
  const [setSendVerification, setShowSendVerification] = useState(false);
  const [showPhoneNo, setShowPhoneNo] = useState('');
  const [setPhoneNo, showPhoneNumber] = useState('');
  const [showSuccessMsg, setSuccessMsg] = useState('');
  const [showErrorMsg, setErrorMsg] = useState('');
  const passwordValue = watch('password')

  const favouriteClubOptions = [
    { label: 'Arsenal', value: 'Arsenal' },
    { label: 'Aston Villa', value: 'Aston Villa' },
    { label: 'Bounemouth', value: 'Bounemouth' },
    { label: 'Brentford', value: 'Brentford' },
    { label: 'Brighton', value: 'Brighton' },
    { label: 'Chelsea', value: 'Chelsea' },
    { label: 'Crystal Palace', value: 'Crystal Palace' },
    { label: 'Everton', value: 'Everton' },
    { label: 'Fulham', value: 'Fulham' },
    { label: 'Ipswich Town', value: 'Ipswich Town' },
    { label: 'Leicester City', value: 'Leicester City' },
    { label: 'Liverpool', value: 'Liverpool' },
    { label: 'Manchester United', value: 'Manchester United' },
    { label: 'Newcastle', value: 'Newcastle' },
    { label: 'Nottingham Forest', value: 'Nottingham Forest' },
    { label: 'Southampton', value: 'Southampton' },
    { label: 'Tottenham Hotspurs', value: 'Tottenham Hotspurs' },
    { label: 'West Ham', value: 'West Ham' },
    { label: 'Wolves', value: 'Wolves' },
  ];

  const sportsInterestOptions = [
    { label: 'Rugby', value: 'Rugby' },
    { label: 'Cricket', value: 'Cricket' },
    { label: 'Football', value: 'Football' },
    { label: 'Tennis', value: 'Tennis' },
    { label: 'F1', value: 'F1' },
    { label: 'NFL', value: 'NFL' },
    { label: 'Basketball', value: 'Basketball' },
    { label: 'Concerts', value: 'Concerts' },
    { label: 'Theatre', value: 'Theatre' },
  ];

  const handleClubSelection = (option) => {
    setSelectedClub(option);
  };

  const sendVerificationCode = async (data) => {
    console.log('showPhoneNo', data);
    setShowPhoneNo(data);

    dispatch(clearAll({}))
    let phoneNo = data;
    const res = await post_put_patch_request(
      'post',
      '',
      `${app_api.send_phone_number_verification}`,
      { phoneNo },
      dispatch,
      setLoading,
      '',
      setErrorMessage
    )
    if (res !== undefined && res.status < 400) {
      dispatch(
        showPhoneNumberVerificationModalFunc({ modal: true, type: 'verify' }));
      setShowOtpInput(true);
      setShowSendVerification(true);
      const phoneStr = data.toString();
      const firstSpaceIndex = phoneStr.indexOf(' ');
      const numberAfterSpace = phoneStr.substring(firstSpaceIndex + 1).trim();

      const digitsOnly = numberAfterSpace.replace(/\D/g, '');

      const lastFourDigits = digitsOnly.slice(-4);

      // Create the masked version
      const maskedNumber = '*'.repeat(digitsOnly.length - 4) + lastFourDigits;
      showPhoneNumber(maskedNumber);

    } else {
      setErrorMsg(res?.data?.message);
    }
  }

  const handleSportsSelection = (options) => {
    setSelectedSports(options);
  };

  useEffect(() => {
    return () => {
      dispatch(clearAll({}));
    };
  }, [dispatch]);

  useEffect(() => {
    // Reset bank details fields when showBankDetails changes to false
    if (!showBankDetails) {
      setValue('bankName', null);
      setValue('accountName', null);
      setValue('accountNo', null);
      setValue('sortCode', null);
      setValue('IBAN', null);
      clearErrors(['bankName', 'accountName', 'accountNo', 'sortCode', 'IBAN']);
    }
  }, [showBankDetails, setValue, clearErrors]);

  const onSubmit = async (data) => {
    setErrorMsg('');
    setSuccessMsg('');
    dispatch(clearAll({}))
    if (data?.code == undefined) {
      setError('code', {
        type: 'manual',
        message: 'Please select country code',
      })
    } else {
      let trimmedMobileNo = data.mobile_no;
      const escapedCode = data.code.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'); // Escapes special characters
      const countryCodeRegex = new RegExp(`^${escapedCode}\\s?`);
      trimmedMobileNo = data.mobile_no.replace(countryCodeRegex, '').trim();
      data.phone = data.code + ' ' + trimmedMobileNo;
      if (!showBankDetails) {
        data.bankName = null;
        data.accountName = null;
        data.accountNo = null;
        data.sortCode = null;
        data.IBAN = null;
      }
      if (data.bankName != null) {
        data.isSeller = true;
      }
      else {
        data.isSeller = false;
      }
      if (selectedClub) {
        data.favouriteClub = selectedClub ? selectedClub.map(val => val.value).join(', ') : null;
      }

      if (selectedSports) {
        data.favouriteSports = selectedSports ? selectedSports.map(option => option.value).join(', ') : null;
      }
      if (Array.isArray(showExistValue) &&
        (

          (showExistValue.length === 1 && showExistValue.includes('email already exists'))
        )) {
        const response = await post_put_patch_request(
          'post',
          "",
          `${app_api.register}`,
          data,
          dispatch,
          setLoading,
          "",
          setErrorMessage
        )
        if (response !== undefined && response.status < 400) {

          dispatch(onOnboardUser({ email: data.email }))
          navigate(app_urls.verification_link_sent)
          setSelectedClub(null);
          setSelectedSports(null);
        }
      }
      console.log('showExistValue', showExistValue);
      const finalPhoneNo = data.phone;
      if (finalPhoneNo && !setSendVerification ||
        (Array.isArray(showExistValue) &&
          (
            (showExistValue.length === 2 && showExistValue.includes('email already exists') && showExistValue.includes('phone already exists')) ||
            (showExistValue.length === 1 && showExistValue.includes('phone already exists'))
          )
        )
      ) {
        sendVerificationCode(finalPhoneNo);
      }
      let otp = data.otp;
      if (otp) {

        let verifyData = {
          code: otp,
          phoneNo: finalPhoneNo
        }
        const res = await post_put_patch_request(
          'post',
          token,
          `${app_api.verify_phone_number_otp}`,
          verifyData,
          dispatch,
          setLoading,
          '',
          setErrorMessage
        )
        if (res !== undefined && res.status < 400) {

          setSuccessMsg(res?.data?.message);
          setTimeout(() => {
            setSuccessMsg('');
          }, 3000);
          const response = await post_put_patch_request(
            'post',
            "",
            `${app_api.register}`,
            data,
            dispatch,
            setLoading,
            "",
            setErrorMessage
          )
          if (response !== undefined && response.status < 400) {

            dispatch(onOnboardUser({ email: data.email }))
            navigate(app_urls.verification_link_sent)
            setSelectedClub(null);
            setSelectedSports(null);
            setExistMsg('');
          }
          else {
            setExistMsg(response?.data?.message);
            setValue('otp', '');
            setShowOtpInput(false);
          }
        }
        else {
          setErrorMsg(res?.data?.message);
          setTimeout(() => {
            setErrorMsg('');
          }, 3000);
        }
      }

    }
  }



  const [localFirstNameError, setLocalFirstNameError] = useState('');
  const [localLastNameError, setLocalLastNameError] = useState('');
  const [localEmailError, setLocalEmailError] = useState('');
  const [localAddr1Error, setLocalAddr1Error] = useState('');
  const [localAddr2Error, setLocalAddr2Error] = useState('');
  const [localHouseNameError, setLocalHouseNameError] = useState('');
  const [localTownError, setLocalTownError] = useState('');
  const [localPostalCodeError, setLocalPostalCodeError] = useState('');

  const handleTextChange = (fieldName, value) => {
    if (fieldName === 'firstName') {
      if (value.length > 30) {
        setLocalFirstNameError('*Maximum length is 30 characters');
      } else {
        setLocalFirstNameError('');
        clearErrors('firstName');
      }
    } else if (fieldName === 'lastName') {
      if (value.length > 30) {
        setLocalLastNameError('*Maximum length is 30 characters');
      } else {
        setLocalLastNameError('');
        clearErrors('lastName');
      }
    } else if (fieldName === 'email') {
      if (value.length > 30) {
        setLocalEmailError('*Maximum length is 30');
      } else {
        setLocalEmailError('');
        clearErrors('email');
      }
    } else if (fieldName === 'address1') {
      if (value.length > 50) {
        setLocalAddr1Error('*Maximum length is 50');
      } else {
        setLocalAddr1Error('');
        clearErrors('firstLineAddress');
      }
    } else if (fieldName === 'address2') {
      if (value.length > 50) {
        setLocalAddr2Error('*Maximum length is 50');
      } else {
        setLocalAddr2Error('');
        clearErrors('secondLineAddress');
      }
    } else if (fieldName === 'houseName') {
      if (value.length > 10) {
        setLocalHouseNameError('*Maximum length is 10');
      } else {
        setLocalHouseNameError('');
        clearErrors('houseName');
      }
    } else if (fieldName === 'town') {
      if (value.length > 20) {
        setLocalTownError('*Maximum length is 20');
      } else {
        setLocalTownError('');
        clearErrors('town');
      }
    } else if (fieldName === 'postCode') {
      if (value.length > 10) {
        setLocalPostalCodeError('*Maximum length is 10');
      } else {
        setLocalPostalCodeError('');
        clearErrors('postCode');
      }
    }
  };

  const closeAlert = () => {
    dispatch(setErrorMessage({ message: null }))
  }

  return (
    <AuthWrapper
      formClasses={'mainFormRegistration'}
      autoComplete='off'
      background_image={registration_background}
      onHandleSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-group col-md-12 mb-2">
        <label htmlFor="name">
          First Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="name"
          autoComplete="off"
          {...register('firstName', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('firstName', e.target.value)}
        />
        {errors?.firstName?.message && (
          <div className="text-danger small mt-1">
            {errors?.firstName?.message}
          </div>
        )}
        {localFirstNameError && <div className="text-danger small mt-1">{localFirstNameError}</div>}
      </div>
      <div className="form-group col-md-12 mb-2">
        <label htmlFor="lastName">
          Last Name <span className="text-danger">*</span>
        </label>
        <input
          type="text"
          className="form-control input-lg"
          id="lastName"
          autoComplete="off"
          {...register('lastName', {
            required: {
              value: true,
              message: '*this field is required',
            },
          })}
          onChange={(e) => handleTextChange('lastName', e.target.value)}
        />
        {errors?.lastName?.message && (
          <div className="text-danger small mt-1">
            {errors?.lastName?.message}
          </div>
        )}
        {localLastNameError && <div className="text-danger small mt-1">{localLastNameError}</div>}
      </div>
      {/* <div className="form-group col-md-6 mb-4">
        <label htmlFor='last_name'>Last Name</label>
        <input 
          type="text" 
          className="form-control input-lg" 
          id="last_name"
          {...register("last_name", {
            required: {
              value: true,
              message: '*this field is required'
            }
          })
          } 
        />
        {
          errors?.last_name?.message &&
          <div className="text-danger small mt-1">
            {errors?.last_name?.message}
          </div>
        }
      </div>                 */}
      <div className="form-group col-md-12 mb-2">
        <label htmlFor="email">
          Email <span className="text-danger">*</span>
        </label>
        <input
          type="email"
          className="form-control input-lg"
          id="email"
          autoComplete="off"
          aria-describedby="emailHelp"
          {...register('email', {
            required: {
              value: true,
              message: '*this field is required',
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: '*invalid email pattern',
            },
          })}
          onChange={(e) => handleTextChange('email', e.target.value)}
        />
        {errors?.email?.message && (
          <div className="text-danger small mt-1">{errors?.email?.message}</div>
        )}
        {localEmailError && <div className="text-danger small mt-1">{localEmailError}</div>}
      </div>

      <div className="form-group col-md-12 mb-2">
        <PhoneNumberComponent
          fieldName={'mobile_no'}
          fieldName2={'code'}
          error_msg={errors.mobile_new?.message ?? errors.code?.message}
          label={'Phone Number'}
          setValueFunc={setValue}
          setErrorFunc={setError}
          clearErrors={clearErrors}
          isReq={true}
          register={register}
        />
      </div>
      <PasswordComponent
        fieldName={'password'}
        classStyles={'col-md-12'}
        error_msg={errors.password?.message}
        label={
          <>
            Password <span className="text-danger">*</span>
          </>
        }
        isReq={true}
        register={register}
        patternRules={'password'}
        hasHelperText={true}
        clearErrors={clearErrors}
      />
      <PasswordComponent
        fieldName={'confirm_password'}
        classStyles={'col-md-12'}
        error_msg={errors.confirm_password?.message}
        label={
          <>
            Confirm Password <span className="text-danger">*</span>
          </>
        }
        isReq={true}
        register={register}
        patternRules={'password'}
        isSameAs={passwordValue}
        clearErrors={clearErrors}
      />

      {/* XD_J */}

      <div className="form-group col-md-12 mb-2">
        <input
          type="checkbox"
          className="form-check-input cursor-pointer"
          id="showBankDetails"
          autoComplete="off"
          checked={showBankDetails}
          onChange={(e) => setShowBankDetails(e.target.checked)}
        />
        <label className="form-check-label cursor-pointer" htmlFor="showBankDetails" style={{ marginLeft: "10px" }}>
          Would you be interested in selling tickets?
        </label>
      </div>

      {!showBankDetails && (
        <>

          <div className="form-group col-md-12 mb-2">
            <label htmlFor="bankName">
              Choose Favourite Teams
            </label>
            <Select
              isMulti
              id="FavouriteClub"
              options={favouriteClubOptions}
              value={selectedClub}
              onChange={handleClubSelection}
              className="custom-select"
            />
          </div>
          <div className="form-group col-md-12 mb-2">
            <label htmlFor="accountName">
              Choose Sports/Interest
            </label>
            <Select
              isMulti
              id="SportsInterest"
              options={sportsInterestOptions}
              value={selectedSports}
              onChange={handleSportsSelection}
              className="custom-select"
            />

          </div>
        </>
      )}
      {showBankDetails && (
        <>

          <div className="col-md-12 mt-3 mb-3 d-flex flex-column align-items-center justify-content-center">
            <p>Permanent Address Details</p>

            <div className="form-group col-md-12 mb-2 mt-3">
              <label htmlFor="firstLineAddress">
                First Line Address <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control input-lg"
                id="firstLineAddress"
                autoComplete="off"
                {...register('firstLineAddress', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
                onChange={(e) => handleTextChange('address1', e.target.value)}
              />
              {localAddr1Error && <div className="text-danger small mt-1">{localAddr1Error}</div>}

              {errors?.firstLineAddress?.message && (
                <div className="text-danger small mt-1">
                  {errors?.firstLineAddress?.message}
                </div>
              )}
            </div>

            <div className="form-group col-md-12 mb-2">
              <label htmlFor="town">
                City <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control input-lg"
                id="town"
                autoComplete="off"
                {...register('town', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },
                })}
                onChange={(e) => handleTextChange('town', e.target.value)}
              />
              {localTownError && <div className="text-danger small mt-1">{localTownError}</div>}
              {errors?.town?.message && (
                <div className="text-danger small mt-1">{errors?.town?.message}</div>
              )}
            </div>

            <div className="form-group col-md-12 mb-2">
              <label htmlFor="city">
                Country <span className="text-danger">*</span>
              </label>

              <CountryComponent
                // searchValue={search_cities}
                register={register}
                isReq={true}
                fieldName={'countryId'}
                fieldName2={'country'}
                text="Select Country"
                setValue={setValue}
                setErrorFunc={setError}
                getValueFunc={getValues}
                errors={errors?.city?.message}
                clearErrors={clearErrors}
                getEndpoint={app_api.get_countries}
                searchEndpoint={app_api.search_countries}
              />
              {errors?.country?.message && (
                <div className="text-danger small mt-1">
                  {errors?.country?.message}
                </div>
              )}
            </div>

            <div className="form-group col-md-12 mb-2">
              <label htmlFor="postCode">
                Postal Code <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control input-lg"
                id="postCode"
                autoComplete="off"
                {...register('postCode', {
                  required: {
                    value: true,
                    message: '*this field is required',
                  },

                })}
                onChange={(e) => handleTextChange('postCode', e.target.value)}
              />
              {localPostalCodeError && <div className="text-danger small mt-1">{localPostalCodeError}</div>}

              {errors?.postCode?.message && (
                <div className="text-danger small mt-1">
                  {errors?.postCode?.message}
                </div>
              )}
            </div>
            <p>Bank Account Details</p>
          </div>
          <div className="form-group col-md-12 mb-2">
            <label htmlFor="bankName">
              Bank Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="bankName"
              autoComplete="off"
              {...register('bankName', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.bankName?.message && (
              <div className="text-danger small mt-1">{errors?.bankName?.message}</div>
            )}
          </div>
          <div className="form-group col-md-12 mb-2">
            <label htmlFor="accountName">
              Account Holder Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="accountName"
              autoComplete="off"
              {...register('accountName', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.accountName?.message && (
              <div className="text-danger small mt-1">{errors?.accountName?.message}</div>
            )}
          </div>
          <div className="form-group col-md-12 mb-2">
            <label htmlFor="IBAN">
              IBAN <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="IBAN"
              autoComplete="off"
              {...register('IBAN', {
                required: showBankDetails && {
                  value: true,
                  message: '*this field is required',
                },
              })}
            />
            {errors?.IBAN?.message && (
              <div className="text-danger small mt-1">{errors?.IBAN?.message}</div>
            )}
          </div>
          <div className="form-group col-md-12 mb-2">
            <label htmlFor="accountNo">
              Swift Code
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="accountNo"
              autoComplete="off"
              {...register('accountNo')}
            />
            {errors?.accountNo?.message && (
              <div className="text-danger small mt-1">{errors?.accountNo?.message}</div>
            )}
          </div>

          <div className="form-group col-md-12 mb-2">
            <label htmlFor="sortCode">
              BIC
            </label>
            <input
              type="text"
              className="form-control input-lg"
              id="sortCode"
              autoComplete="off"
              {...register('sortCode')}
            />
            {errors?.sortCode?.message && (
              <div className="text-danger small mt-1">{errors?.sortCode?.message}</div>
            )}
          </div>

        </>
      )}

      {
        showOtpInput && (
          <div>
            <h5
              className="text-dark"
              style={{ fontSize: '0.86rem', lineHeight: '1.5rem' }}
            >
              Enter the 6 digit OTP that was sent to your phone number ({setPhoneNo}
              )
            </h5>
            <div className="mt-2">
              <div style={{ rowGap: '1.2rem' }} className="row mb-2">
                <div className="form-group">
                  <label htmlFor="token">One Time Password (OTP)</label>
                  <input
                    type="text"
                    className="form-control input-lg"
                    id="token"
                    {...register('otp', {
                      required: {
                        value: true,
                        message: '*this field is required',
                      },
                    })}
                  />
                  {errors?.otp?.message && (
                    <div className="text-danger small mt-1">
                      {errors?.otp?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
      {/* <div>
        {showSuccessMsg && (
          <div className="text-success small mt-1">
            {showSuccessMsg}
          </div>
        )}
        {showErrorMsg && (
          <div className="text-danger small mt-1">
            {showErrorMsg}
          </div>
        )}
      </div> */}
      <div className="reg-alert-sec">
        {errorMsg && (
          <AuthAlert
            onClick={closeAlert}
            alertStyles={{ width: '95%', margin: '0 auto' }}
            status="error"
          >
            {errorMsg}
          </AuthAlert>
        )}
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column align-items-center justify-content-center">
        <button
          type="submit"
          disabled={loading}
          className={`btn btn-primary btn-lg form-control input-lg py-2 mb-3 text-uppercase ${loading && 'd-flex justify-content-center'
            }`}
        >
          Submit
          {loading && (
            <Spinner
              class_styles={'ms-2'}
              spinner_styles={{ width: '1rem', height: '1rem' }}
              color={'text-white'}
            />
          )}
        </button>

        <p>
          Have an account already?
          <Link className="text-blue ms-1" to={app_urls.login}>
            Login Here
          </Link>
          {/* <a className="text-blue" href="sign-up.html">Login Here</a> */}
        </p>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center">
        <SocialAuthComponent />
      </div>
    </AuthWrapper>
  )
}
